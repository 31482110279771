import { Injectable } from "@angular/core";
import BX24 from "./bx24/bx24";
import IAuth from "./bx24/entities/auth";
import { Observable, from, shareReplay } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class Bitrix24Service {
  private _bx24: BX24;
  constructor() {
    this._bx24 = new BX24();
  }

  private authInfo: Observable<IAuth> | null = null;
  public getAuth(): Observable<IAuth> {
    if (this.authInfo == null) {
      this.authInfo = from(this._bx24.getAuth()).pipe(shareReplay());
    }
    return this.authInfo;
  }

  public sendMessage(cmd: string, params?: object): Promise<any> {
    return this._bx24.sendMessage(cmd, params);
  }

  public openWnd(view: string,title:string, width:number, params: any = null) {
    this._bx24.sendMessage("openApplication", {
      data: {
        view: view,
        data: params,
      },
      bx24_width: width, // int
      bx24_title: title,
      bx24_label: {
        text: title,
      },
    });
  }

  public openView(view: string, params: any = null) {
    this._bx24.sendMessage("openApplication", {
      data: {
        view: view,
        data: params,
      },
    });
  }

  public editKpi(
    id: number | null,
    positionId: number | null,
    isGroup: boolean | null,
    parentId: number | null,
    periodId: number | null,
    role: "controller" | "viewer" | null
  ) {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "edit-kpi",
        id: id,
        positionId: positionId,
        data: {
          role: role,
          isGroup: isGroup,
          parentId: parentId,
          periodId: periodId,
        },
      },
      bx24_width: 750, // int
      bx24_title: isGroup ? "Цель" : "Показатель",
      bx24_label: {
        text: isGroup ? "Цель" : "Показатель",
      },
    });
  }
  public editKpiTemplate(
    id: number | null,
    isGroup: boolean | null,
    parentId: number | null
  ) {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "edit-kpi-template",
        id: id,
        data: {
          isGroup: isGroup,
          parentId: parentId,
        },
      },
      bx24_width: 750, // int
      bx24_title: isGroup ? "Цель" : "Показатель",
      bx24_label: {
        text: isGroup ? "Цель" : "Показатель",
      },
    });
  }

  public editMember(id: number | null) {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "edit-user",
        id: id,
      },
      bx24_width: 750, // int
      bx24_title: "Сотрудник",
      bx24_label: {
        text: "Сотрудник",
      },
    });
  }

  public editPosition(
    positionId: number | null,
    departmentId: number | null = null
  ): Promise<any> {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "edit-position",
        data: {
          positionId: positionId,
          departmentId: departmentId,
        },
      },
      bx24_width: 750, // int
      bx24_title: "Должность",
      bx24_label: {
        text: "Должность",
      },
    });
  }
  public editDepartment(
    departmentId: number | null,
    parentId: number | null = null
  ): Promise<any> {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "edit-department",
        data: {
          departmentId: departmentId,
          parentId: parentId,
        },
      },
      bx24_width: 750, // int
      bx24_title: "Департамент",
      bx24_label: {
        text: "Департамент",
      },
    });
  }

  public OpenPayment(period: string, userLimit: number) {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "payment-page",
        data: {
          period,
          userLimit,
        },
      },
      bx24_title: "Счёт",
      bx24_label: {
        text: "Счёт",
      },
    });
  }
  public viewUserKpi(
    positionId: string | null,
    periodId:number | null,
    title: string = "Показатели",
    role: "controller" | "viewer" | null
  ) {
    return this._bx24.sendMessage("openApplication", {
      data: {
        view: "user-kpis-page",
        positionId: positionId,
        data: {
          role:role,
          periodId:periodId,
        },
      },
      bx24_title: title,
      bx24_label: {
        text: title,
      },
    });
  }

  public closeCurrentWnd(params: any = null) {
    this._bx24.sendMessage("closeApplication", params).then((res) => {
     // console.log(res);
    });
  }
}
