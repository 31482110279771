<mat-grid-list cols="2" gutterSize="5">
  <mat-grid-tile>
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n>Согласующие</mat-card-title>
        <button mat-icon-button color="primary">
          <mat-icon>person_add</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="coordinating">
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item i18n>Удалить</button>
              </mat-menu>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef i18n>Имя и фамилия</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="subdivision">
            <th mat-header-cell *matHeaderCellDef i18n>Подразделение</th>
            <td mat-cell *matCellDef="let element">{{element.subdivision}}</td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="['actions','name','subdivision']"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['actions','name','subdivision'];"
          ></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n>Контролёры</mat-card-title>
        <button mat-icon-button color="primary">
          <mat-icon>person_add</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content
        ><table mat-table [dataSource]="controllers">
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item i18n>Удалить</button>
              </mat-menu>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef i18n>Имя и фамилия</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="subdivision">
            <th mat-header-cell *matHeaderCellDef i18n>Подразделение</th>
            <td mat-cell *matCellDef="let element">{{element.subdivision}}</td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="['actions','name','subdivision']"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['actions','name','subdivision'];"
          ></tr></table
      ></mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
