import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { debounceTime, map } from "rxjs";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import {
  ExportService,
  IFilterInfo,
  IColumnInfo,
} from "src/app/services/export.service";
import { MessagesService } from "src/app/services/messages.service";
import { OrgStructureService } from "src/app/services/org-structure.service";

@Component({
  selector: "controlled-list-page",
  templateUrl: "./controlled-list-page.component.html",
  styleUrl: "./controlled-list-page.component.scss",
})
export class ControlledListPageComponent implements OnInit {
  public isLoading: boolean = true;
  public searchForm: FormGroup;
  public data: any[] = [];
  constructor(
    private orgStructureService: OrgStructureService,
    public bx: Bitrix24Service,
    public dictionaries: DictionaryService,
    private _exportService: ExportService,
    private _messagesService: MessagesService
  ) {
    this.searchForm = new FormGroup({
      period: new FormControl(null as number | null),
      status: new FormControl(null as number | null),
      searchString: new FormControl(null),
      departments: new FormControl([]),
    });
  }

  private loadData() {
    this.isLoading = true;
    this.orgStructureService
      .GetMembersToControl(this.searchForm.getRawValue())
      .subscribe((res) => {
        // console.log(res);
        this.data = res;
        this.isLoading = false;
      });
  }

  public onEditRow(element: any) {
    this.bx.viewUserKpi(
      element.id,
      this.searchForm.controls["period"].value,
      $localize`Показатели`,
      "controller"
    );
  }

  ngOnInit(): void {
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(res =>{
      this.loadData();
    });
    this._messagesService.onUpdateKpi.subscribe((res) => {
      this.loadData();
    });
    this.dictionaries.GetDefaultPeriodId().subscribe((res) => {
      this.searchForm.controls["period"].setValue(res);
      // this.loadData();
    });
  }

  onSearchFormReset() {
    this.searchForm.reset({
      period: null,
      status: null,
    });
    this.loadData();
  }
  onSearchFormSubmit() {
    setTimeout(() => {
      this.loadData();
    });
  }

  async onDownloadClick() {
    var filters: IFilterInfo[] = [];

    var form: any = this.searchForm.getRawValue();
    if (form.period) {
      let fValue: number = form.period;
      var dicValue = await this.dictionaries.PeriodsWithOutHidden.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: $localize`Период`,
        value: dicValue || "",
      });
    }
    if (!!form.status) {
      let fValue: number = form.status;
      var dicValue = await this.dictionaries.Statuses.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: $localize`Статус`,
        value: dicValue || "",
      });
    }

    const exportColumns: IColumnInfo[] = [
      {
        title: $localize`Сотрудник`,
        field: (row) => row.user?.name,
      },
      { title: $localize`Департамент`, field: (row) => row.departmentName },
      { title: $localize`Должность`, field: (row) => row.name },
      {
        title: $localize`Исполнение`,
        field: (row) => {
          return row.execution.execution / 100;
        },
        color: (row) => row.execution.color,
        fieldMask: "0%",
      },
    ];

    this._exportService.ExportKpis(
      $localize`Оцениваемые`,
      $localize`Цели и KPI`,
      exportColumns,
      this.data,
      filters
    );
  }
}
