<mat-card class="mat-edit-card">
  <mat-card-header>
    <mat-card-title i18n>Должность</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <form [formGroup]="mainForm">
      <mat-form-field>
        <mat-label i18n>Наименование должности</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Код</mat-label>
        <input matInput formControlName="code" />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label i18n>Департамент</mat-label>
        <mat-select formControlName="departmentId">
          <mat-option
            *ngFor="let item of departments | async"
            [value]="item.id"
          >
            <div [style.padding-left.px]="24*item.level">
              {{ item.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label i18n>Сотрудник</mat-label>
        <select-users formControlName="user" [single]="true"></select-users>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Проверяющие</mat-label>
        <select-position formControlName="approvers"> </select-position>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Контролёры</mat-label>
        <select-position formControlName="inspectors"> </select-position>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Наблюдатели</mat-label>
        <select-position formControlName="viewers"> </select-position>
      </mat-form-field>
      <div>
        <mat-slide-toggle formControlName="archived">В архиве</mat-slide-toggle>
      </div>
      <div style="margin-top: 1rem;">
        <mat-slide-toggle formControlName="disableAccess" i18n
          >Отключен от системы</mat-slide-toggle
        >
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="action-buttons">
      <button mat-raised-button (click)="onBtnSave()" color="primary" i18n>Сохранить</button>
    </div>
  </mat-card-actions>
</mat-card>
