import { Component, Inject } from '@angular/core';
import { PLACE_DATA } from 'src/app/consts';
import IPlaceParamsData from 'src/app/services/bx24/entities/placeParamsData';
const tabs: any = {
  period: 0, 
  units:1
};
@Component({
  selector: 'dictionaries-page',
  templateUrl: './dictionaries-page.component.html',
  styleUrl: './dictionaries-page.component.scss'
})
export class DictionariesPageComponent {
  public selectedTab: number = 0;
  /**
   *
   */
  constructor(
    @Inject(PLACE_DATA) public viewData: IPlaceParamsData
  ) {
    if (viewData?.data?.tab) {
      if (tabs[viewData?.data?.tab] !== undefined) {
        this.selectedTab = tabs[viewData.data.tab];
      }
    }
  }
}
