
<h2 mat-dialog-title i18n>Департаменты</h2>
<div style="padding: 0 1rem;">
    <mat-form-field>
      <mat-label>Фильтр</mat-label>
      <input matInput [(ngModel)]="filterText" (input)="onChangeSearchText()" />
    </mat-form-field>
  </div>
<mat-dialog-content>
  @if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>

  } @else {
 
  <div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>

        <mat-checkbox [checked]="isChecked(node)" (change)="toogleItem(node)"
          ><div class="name-wrapper">
            <mat-icon>home</mat-icon>

            <div>
              <div>{{node.name}}</div>
            </div>
          </div>
        </mat-checkbox>
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node;when: hasChild"
        matTreeNodePadding
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        <mat-checkbox [checked]="isChecked(node)" (change)="toogleItem(node)"
          ><div class="name-wrapper">
            <mat-icon>home</mat-icon>
            <div>
              <div>{{node.name}}</div>
            </div>
          </div>
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button i18n (click)="selectedItems = []">Очистить</button>
  <button mat-button mat-dialog-close i18n>Отмена</button>
  <button mat-button [mat-dialog-close]="selectedItems" cdkFocusInitial>
    Ок
  </button>
</mat-dialog-actions>
