import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PLACE_DATA } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import IPlaceParamsData from "src/app/services/bx24/entities/placeParamsData";
import { MessagesService } from "src/app/services/messages.service";
import {
  IUserModel,
  OrgStructureService,
} from "src/app/services/org-structure.service";

@Component({
  selector: "edit-user-page",
  templateUrl: "./edit-user-page.component.html",
  styleUrl: "./edit-user-page.component.scss",
})
export class EditUserPageComponent {
  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    lastName: new FormControl(""),
    secondName: new FormControl(""),
    fired: new FormControl(false),
    disabledAccess: new FormControl(false),
  });
  public model: IUserModel = {
    email: null,
    fired: false,
    id: 0,
    isBitrixUser: false,
    lastName: null,
    name: null,
    secondName: null,
    disabledAccess: false,
  };

  public isLoading: boolean = false;
  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private bx: Bitrix24Service,
    private orgStructureService: OrgStructureService,
    private _messagesService: MessagesService
  ) {}

  private LoadData(id: number) {
    this.isLoading = true;
    this.orgStructureService.GetUser(id).subscribe((res) => {
      this.model = res;
      for (const field in this.mainForm.controls) {
        const control = this.mainForm.get(field); // 'control' is a FormControl
        control?.setValue((<any>res)[field] || null);
      }
      if (res.isBitrixUser) this.mainForm.disable();
      this.mainForm.controls["disabledAccess"].enable();
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    // console.log(this.data);
    if (!!this.data.id) {
      this.LoadData(this.data.id);
    }
  }

  public onBtnSave() {
    this.mainForm.markAllAsTouched();

    if (this.mainForm.valid) {
      const value = this.mainForm.getRawValue();
      let model: IUserModel = {
        ...value,
        id: this.model.id,
        isBitrixUser: this.model.isBitrixUser,
        fired: value.fired == true,
        disabledAccess: value.disabledAccess == true,
      };
      this.isLoading = true;
      this.orgStructureService.SaveUser(model).subscribe((res) => {
        this.isLoading = false;
        this.LoadData(res);

        this._messagesService.UpdateEmployee(model);
      });
    }
    // console.log(this.mainForm.getRawValue());
  }
}
