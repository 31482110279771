<mat-card>
  <mat-card-header>
    <!-- <mat-card-subtitle>
      <div>
        <button mat-button (click)="bx.openView('controlled-list')">Контроль</button>
      </div>
    </mat-card-subtitle> -->
    <mat-card-title>
      <div class="content-wrapper">
        <div>
          <span i18n>Оцениваемые мной</span>
          <help-btn
            page="rukovodstvo_soglasuyushchego_rukovoditelya"
          ></help-btn>
        </div>
        <div>
          <form
            [formGroup]="searchForm"
            class="search-form"
            (ngSubmit)="onSearchFormSubmit()"
          >
            <mat-form-field>
              <mat-label i18n>Фильтр</mat-label>
              <input matInput formControlName="searchString" />
            </mat-form-field>
            <div>
              <mat-form-field>
                <mat-label i18n>Период</mat-label>
                <mat-select matInput formControlName="period">
                  <mat-option
                    *ngFor="let item of dictionaries.PeriodsWithOutHidden| async"
                    [value]="item.id"
                  >
                    <div [style.padding-left.px]="24*item.level">
                      {{ item.name }}
                    </div></mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-label i18n>Статус</mat-label>
                <mat-select matInput formControlName="status">
                  <mat-option
                    *ngFor="let item of dictionaries.Statuses | async"
                    [value]="item.id"
                    >{{ item.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="">
                <mat-label i18n>Департамент</mat-label>
                <select-department
                  formControlName="departments"
                ></select-department>
              </mat-form-field>
            </div>
            <button mat-mini-fab type="submit" color="primary">
              <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="onSearchFormReset()">
              <mat-icon>search_off</mat-icon>
            </button>
          </form>
        </div>
      </div>
    </mat-card-title>
    <mat-card-title>
      <div class="header">
        <div></div>
        <div>
          <button
            [disabled]="!hasSelected"
            color="primary"
            mat-icon-button
            i18n-matTooltip
            matTooltip="Добавить из библиотеки"
            (click)="onAddFromLib()"
          >
            <mat-icon>library_books</mat-icon>
          </button>
          <button
            color="primary"
            mat-icon-button
            (click)="onDownloadClick()"
            i18n-matTooltip
            matTooltip="Экспорт"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <table mat-table [dataSource]="tableRows" matSort>
      <ng-container matColumnDef="checker">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [indeterminate]="isCheckerIndeterminate"
            [checked]="allComplete"
            (change)="onChangeAll($event)"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            [checked]="selectedRows[element.id]"
            (change)="onCheck(element.id)"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEditRow(element)" i18n
              >Цели и KPI</button
            >
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="userName" i18n
          >Сотрудник</th
        >
        <td mat-cell *matCellDef="let element">
          <div class="user" (click)="onEditRow(element)">
            <avatar [url]="element.user?.photo"></avatar>
            {{element.user?.name}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" i18n
          >Должность</th
        >
        <td mat-cell *matCellDef="let element">
          {{element.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="department">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="departmentName"
          i18n
          >Департамент</th
        >
        <td mat-cell *matCellDef="let element">
          {{element.departmentName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="execution">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="executionValue"
          i18n
          >Исполнение</th
        >
        <td mat-cell *matCellDef="let element" class="custom-color">
          <div>
            {{element.execution.execution | number : '1.0-0'}}%
          </div>
          <mat-progress-bar
            mode="determinate"
            [value]="element.execution.execution"
            [style.color]="element.execution.color"
          ></mat-progress-bar>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="statusId" i18n
          >Статус</th
        >
        <td mat-cell *matCellDef="let element">
          @if(element.minStatus != null){
          <mat-chip [style.background-color]="element.minStatus?.color"
            >{{element.minStatus?.name}}</mat-chip
          >
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="needApproval"
          ><mat-icon>priority_high</mat-icon></th
        >
        <td mat-cell *matCellDef="let element">
          @if (element.needApproval) {
          <mat-icon style="color: red;" i18n-matTooltip matTooltip="Необходимо согласовать"
            >lightbulb_outline</mat-icon
          >
          }
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="['checker','user','department','position','execution','status','info','actions']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['checker','user','department','position','execution','status','info','actions'];"
      ></tr>
    </table>
  </mat-card-content>
</mat-card>
