import { Component, Inject, OnInit } from "@angular/core";
import { OrgStructureService } from "../../services/org-structure.service";
import { Bitrix24Service } from "../../services/bitrix24.service";
import { PLACE_DATA } from "src/app/consts";
import IPlaceParamsData from "src/app/services/bx24/entities/placeParamsData";

export enum RowTypeEnum {
  department,
  position,
}
export interface ITableRow {
  id: number;
  name: string;
  level: number;
  rowType: RowTypeEnum;
  user?: any;
  approvers?: any[];
  inspectors?: any[];
}
const tabs: any = {
  dep: 1,
  pos: 2,
  emp: 3,
  lib: 4,
  lic: 5,
};
@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrl: "./settings-page.component.scss",
})
export class SettingsPageComponent implements OnInit {
  public data: any;
  RowTypeEnum: typeof RowTypeEnum = RowTypeEnum;
  public isLoading: boolean = true;
  public selectedTab: number = 0;

  public columns = ["name", "user", "approvers"];
  /**
   *
   */
  constructor(
    private orgStructureService: OrgStructureService,
    private bitrix24Service: Bitrix24Service,
    @Inject(PLACE_DATA) public viewData: IPlaceParamsData
  ) {
    
    if (viewData?.data?.tab) {
      if (tabs[viewData?.data?.tab] !== undefined) {
        this.selectedTab = tabs[viewData.data.tab];
      }
    }
    let title: any = "Орг структура";
    bitrix24Service.sendMessage("setTitle", title).then((res) => {
     // console.log(res);
    });
  }

  private BuildTable(rows: any[], level: number = 0): ITableRow[] {
    let result: ITableRow[] = [];
    for (let row of rows) {
      result.push({
        id: row.id,
        name: row.name,
        level: level,
        rowType: RowTypeEnum.department,
      });
      result.push(...this.BuildTable(row.children, level + 1));

      for (let pos of row.positions || []) {
        result.push({
          id: pos.id,
          name: pos.name,
          level: level + 1,
          rowType: RowTypeEnum.position,
          user: pos.user,
          approvers: pos.approvers || [],
          inspectors: pos.inspectors || [],
        });
      }
    }

    return result;
  }

  ngOnInit(): void {
    // this.orgStructureService.GetList().subscribe((res) => {
    //   let result: ITableRow[] = this.BuildTable(res);
    //   this.data = result;
    //   this.isLoading = false;
    // });
  }

  onApproversChanged(row: ITableRow, rows: any[]) {
    // console.log(row);
    this.orgStructureService
      .SaveApprovers(
        row.id,
        rows.map((m) => m.id)
      )
      .subscribe((res) => {});
  }

  onInspectorsChanged(row: ITableRow, rows: any[]) {
    // console.log(row);
    this.orgStructureService
      .SaveInspectors(
        row.id,
        rows.map((m) => m.id)
      )
      .subscribe((res) => {});
  }
}
