<mat-card class="mat-edit-card">
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <div>
      <table mat-table [dataSource]="rows">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              (click)="remove(element.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Дата</th>
          <td mat-cell *matCellDef="let element">
            {{element.creationDate | date: 'medium'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="creator">
          <th mat-header-cell *matHeaderCellDef i18n>Автор</th>
          <td mat-cell *matCellDef="let element">
            <user-widget [user]="element.creator"></user-widget>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="action-buttons">
      <button mat-raised-button (click)="addKey()" color="primary" i18n
        >Создать ключ</button
      >
    </div>
  </mat-card-actions>
</mat-card>
