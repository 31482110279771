import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, shareReplay } from 'rxjs';

export interface ILicenseInfo{
  userLimit:number | null;
  expirationDate:string;
  isBlocked:boolean;
}
export interface ILicenseError{
  error:string;
  message:string;
}
@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  public onLicenseError:Subject<ILicenseError> = new Subject();

  constructor(private http: HttpClient) { }

  
  public Info:Observable<any> = this.http.get('/api/license/info')
  .pipe(shareReplay());
  
  public GetInfo(): Observable<any> {
    return this.http.get<any>(`/api/info`);
  }
}
