import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
export interface IMessageInfo {
  action: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  private sharedWorker!: SharedWorker;
  public onMessage: Subject<any> = new Subject();
  public onUpdatePosition: Subject<any> = new Subject();
  public onUpdateEmployee: Subject<any> = new Subject();
  public onUpdateDepartment: Subject<any> = new Subject();
  public onUpdateKpi: Subject<any> = new Subject();
  public onUpdateInvoices: Subject<any> = new Subject();
  public onUpdateKpiTemplate: Subject<any> = new Subject();
  constructor(private _ngZone: NgZone) {
    this.sharedWorker = new SharedWorker(
      new URL("./../workers/message.shared.worker", import.meta.url),
      {
        type: "module",
      }
    );
    this.sharedWorker.port.onmessage = ({ data }) => {
      this._ngZone.run(() => {
        this.onMessage.next(data);
        switch (data.action) {
          case "UpdatePosition":
            this.onUpdatePosition.next(data.data);
            break;
          case "UpdateDepartment":
            this.onUpdateDepartment.next(data.data);
            break;
          case "UpdateEmployee":
            this.onUpdateEmployee.next(data.data);
            break;
          case "UpdateKpiTemplate":
            this.onUpdateKpiTemplate.next(data.data);
            break;
          case "UpdateKpi":
            this.onUpdateKpi.next(data.data);
            break;
          case "UpdateInvoices":
            this.onUpdateInvoices.next(null);
            break;
          default:
            console.log(data);
        }
      });
    };
  }
  public UpdateInvoices(kpi: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdateInvoices",
      data: {},
    });
  }
  public UpdateKpi(kpi: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdateKpi",
      data: kpi,
    });
  }
  public UpdateKpiTemplate(kpi: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdateKpiTemplate",
      data: kpi,
    });
  }
  public UpdatePosition(position: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdatePosition",
      data: position,
    });
  }

  public UpdateDepartment(position: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdateDepartment",
      data: position,
    });
  }
  public UpdateEmployee(emp: any) {
    this.sharedWorker.port.postMessage({
      action: "UpdateEmployee",
      data: emp,
    });
  }

  // public SendMessage(action: string, data: any) {
  //   this.sharedWorker.port.postMessage({
  //     action: action,
  //     data: data,
  //   });
  // }
}
