<loader [isLoading]="isLoading"></loader>
<mat-tab-group *ngIf="!isLoading">
  @for (item of tariffs; track $index) {
  <mat-tab>
    <ng-template mat-tab-label>{{item.name}}</ng-template>
    <div class="price-wrapper">
      @for (price of item.tariffPrices; track $index) {
      <div class="price-wrapper-item">
        <mat-card>
          <mat-card-header>
            {{price.name}}
          </mat-card-header>
          <mat-card-content>
            {{price.price | number : '1.2-2' }}
          </mat-card-content>
          <mat-card-actions [align]="'end'">
            <button mat-raised-button color="primary" (click)="onBtnBuy(item.code,price.userLimit)" i18n>Купить</button>
          </mat-card-actions>
        </mat-card>
      </div>
      }
    </div>
  </mat-tab>
  }
</mat-tab-group>
