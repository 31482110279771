import { Component, OnInit } from "@angular/core";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { PaymentService } from "src/app/services/payment.service";

@Component({
  selector: "choose-tariff-page",
  templateUrl: "./choose-tariff-page.component.html",
  styleUrl: "./choose-tariff-page.component.scss",
})
export class ChooseTariffPageComponent implements OnInit {
  public isLoading: boolean = true;
  public tariffs: any[] = [];

  constructor(private paymentService: PaymentService,public bitrix24Service:Bitrix24Service) {}

  async loadData() {
    this.isLoading = true;
    this.tariffs = (await this.paymentService.GetTariffs().toPromise()) || [];
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.loadData();
  }

  onBtnBuy(period: string, userLimit: number) {
    //console.log(period, userLimit);
    this.bitrix24Service.OpenPayment(period, userLimit);
  }
}
