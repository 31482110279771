import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, switchMap, tap } from "rxjs";
import { Bitrix24Service } from "../services/bitrix24.service";
import { Injectable } from "@angular/core";
import { LicenseService } from "../services/license.service";

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(private bx24: Bitrix24Service, private licenseService:LicenseService) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // console.log(req);

    return this.bx24.getAuth().pipe(
      switchMap((auth) => {

        const authReq = req.clone({
          headers: req.headers
            .set("X-Auth-Expires", `${auth.AUTH_EXPIRES}`)
            .set("X-Auth-Id", `${auth.ACCESS_TOKEN}`)
            .set("X-Refresh-Id", `${auth.REFRESH_TOKEN}`)
            .set("X-Member-Id", `${auth.MEMBER_ID}`)
            .set("X-App-Sid", `${auth.APP_SID}`)
            .set("X-Domain", `${auth.DOMAIN}`)
            .set("X-Protocol", `${auth.PROTOCOL}`)
                });
        // console.log(authReq);
        return next.handle(authReq).pipe(tap(res =>{}, err=>{
          
          switch (err.error.error) {
            case "LicenseException" :
              this.licenseService.onLicenseError.next(err.error); 
            break;
            case "AccessDeniedException" :
              this.licenseService.onLicenseError.next({
                ...err.error,
                message:"Нет доступа к системе оценки"
              }); 
            break
          }       
        }));
      })
    );
  }
}
