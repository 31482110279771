<loader [isLoading]="isLoading"></loader>
<div class="layout-wrapper">
  <div class="layout-left">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <!-- <span>Функции</span> -->
          <div class="list-item">
            <div class="list-item__content">Функции</div>
            <div class="list-item__actions"
              ><button mat-icon-button (click)="onItemClick(0)">
                <mat-icon>add</mat-icon>
              </button></div
            >
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="list-wrapper">
          @for (item of listItems; track $index) {

          <div
            class="list-item hovered"
            [class.selected]="item.id == selectedId"
            (click)="onItemClick(item.id)"
          >
            <div class="list-item__content">{{item.name}}</div>
            <div class="list-item__actions">
              <button
                mat-icon-button
                (click)="onDelete(item)"
                [disabled]="!item.canDelete"
              >
                <mat-icon *ngIf="item.canDelete">delete</mat-icon>
              </button></div
            >
          </div>

          }
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="layout-content">
    <mat-card>
      <!-- <mat-card-header>
        <mat-card-title>
          <span>Код</span>
        </mat-card-title>
      </mat-card-header> -->

      @if (selectedId !== null) {
      <mat-card-content>
        <div class="content-wrapper">
          <div class="content-wrapper__params" [class.disabled]="isReadOnly">
            <div>
              <div class="list-item">
                <div class="list-item__content mat-mdc-card-title"
                  >Параметры</div
                >
                <div class="list-item__actions"
                  ><button
                    mat-icon-button
                    (click)="onEditParams(null)"
                    [disabled]="isReadOnly"
                  >
                    <mat-icon>add</mat-icon>
                  </button></div
                >
              </div>
            </div>
            <div>
              <div class="list-wrapper">
                @for (item of functionParametrs; track $index) {

                <div class="list-item hovered">
                  <div class="list-item__content"
                    >{{item.displayName}}
                    <span class="fp-name">({{item.name}})</span></div
                  >
                  <div class="list-item__actions">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      [disabled]="isReadOnly"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button></div
                  >
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditParams(item)">
                      <mat-icon>edit</mat-icon>
                      <span>Изменить</span>
                    </button>
                    <button mat-menu-item (click)="onRemoveParams(item)">
                      <mat-icon>delete</mat-icon>
                      <span>Удалить</span>
                    </button>
                  </mat-menu>
                </div>
                }
              </div>
            </div>
          </div>
          <div class="content-wrapper__main">
            <form [formGroup]="mainForm" class="main-form">
              <mat-form-field>
                <mat-label i18n>Наименование</mat-label>
                <input
                  matInput
                  formControlName="name"
                  [disabled]="isReadOnly"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label i18n>Описание</mat-label>
                <input
                  matInput
                  formControlName="description"
                  [disabled]="isReadOnly"
                />
              </mat-form-field>
              <mat-tab-group class="functions-tab-group"  mat-stretch-tabs="true" >
                <mat-tab i18n-label label="Исполнение">
                  <div class="ace-wrapper" [class.disabled]="isReadOnly">
                    <ace
                      [disabled]="isReadOnly"
                      mode="lua"
                      theme="xcode"
                      [(value)]="execCode"
                    ></ace>
                  </div>
                </mat-tab>
                <mat-tab i18n-label label="План">
                  <div class="ace-wrapper" [class.disabled]="isReadOnly">
                    <ace
                      [disabled]="isReadOnly"
                      mode="lua"
                      theme="xcode"
                      [(value)]="planCode"
                    ></ace>
                  </div>
                </mat-tab>
                <mat-tab i18n-label label="Факт">
                  <div class="ace-wrapper" [class.disabled]="isReadOnly">
                    <ace
                      [disabled]="isReadOnly"
                      mode="lua"
                      theme="xcode"
                      [(value)]="factCode"
                    ></ace>
                  </div>
                </mat-tab>
              </mat-tab-group>

              <div class="error-message">
                {{errorMessage}}
              </div>
            </form>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions [align]="'end'">
        @if (currentModel?.id || 0 > 0) {
        <button mat-button color="primary" (click)="toogleEdit()">
          @if(isReadOnly){
          <span i18n>Изменить</span>
          } @else {
          <span i18n>Отмена</span>
          }
        </button>
        }
        <!-- <button mat-button color="primary">Отменить</button> -->
        <button
          mat-raised-button
          color="primary"
          [disabled]="isReadOnly"
          (click)="onSave()"
          >Сохранить</button
        >
      </mat-card-actions>
      }
    </mat-card>
  </div>
</div>
