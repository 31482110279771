<h2 mat-dialog-title i18n>Сотрудники</h2>
<mat-dialog-content>
  @if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>

  } @else {
  <div>
    <mat-form-field>
      <mat-label i18n>Фильтр</mat-label>
      <input matInput [(ngModel)]="filterText" (input)="onChangeSearchText()" />
    </mat-form-field>
  </div>
  <div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>

      <mat-checkbox
        [checked]="isChecked(node.data)"
        (change)="toogleItem(node.data)"
        ><div class="name-wrapper">
          <avatar [url]="node.data.photo"> </avatar>
          <div>
            {{node.data.name}}
            <!-- <div>{{node | json}}</div> -->
          </div>
        </div>
      </mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      {{node.name}}
    </mat-tree-node>
  </mat-tree>
</div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Отмена</button>
  <button mat-button [mat-dialog-close]="selectedItems" cdkFocusInitial>
    Ок
  </button>
</mat-dialog-actions>
