import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from "@angular/material/tree";
import { DictionaryService } from "src/app/services/dictionary.service";
import {
  IKpiTemplateModel,
  KpiTemplateService,
} from "src/app/services/kpi-template.service";
import { KpiService } from "src/app/services/kpi.service";
import { MessagesService } from "src/app/services/messages.service";

interface ITreeNode {
  id: number;
  name: string;
  user?: any;
  children?: [];
}

interface IFlatNode {
  id: number;
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: "app-add-kpi-from-lib-wnd",
  templateUrl: "./add-kpi-from-lib-wnd.component.html",
  styleUrl: "./add-kpi-from-lib-wnd.component.scss",
})
export class AddKpiFromLibWndComponent implements OnInit {
  private _allRows: IKpiTemplateModel[] = [];
  public selectedItems: number[] = [];
  public isLoading: boolean = false;

  public mainForm = new FormGroup({
    periodId: new FormControl(null as number[] | null, Validators.required),
  });

  constructor(
    public dictionaries: DictionaryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private kpiTemplateService: KpiTemplateService,
    private kpiService: KpiService,
    public dialogRef: MatDialogRef<AddKpiFromLibWndComponent>,
    private _messagesService: MessagesService,
  ) {
    if (!!data.periodId){
      this.mainForm.controls["periodId"].setValue([data.periodId]);
    }
  }
  public isChecked(node: any) {
    return this.selectedItems.findIndex((f) => f == node.id) >= 0;
  }

  private processChildren(parentId: number, add: boolean) {
    let rows = this._allRows.filter((f) => f.parentId == parentId);
    for (let row of rows) {
      let index = this.selectedItems.findIndex((f) => f == row.id);
      if (index == -1) {
        if (add) {
          this.selectedItems.push(row.id);
        }
      } else {
        if (!add) {
          this.selectedItems.splice(index, 1);
        }
      }
      this.processChildren(row.id, add);
    }
  }

  public toogleItem(node: any) {
    let index = this.selectedItems.findIndex((f) => f == node.id);
    if (index == -1) {
      this.selectedItems.push(node.id);
    } else {
      this.selectedItems.splice(index, 1);
    }
    this.processChildren(node.id, index == -1);
  }

  private _transformer = (node: ITreeNode, level: number) => {
    return {
      id: node.id,
      expandable: this._allRows.find((f) => f.parentId == node.id) != null,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => {
      
      let result: any[] = this._allRows.filter((f) => f.parentId === node.id);
      return result;
    }
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IFlatNode) => node.expandable;

  ngOnInit(): void {
    this.isLoading = true;
    this.kpiTemplateService.GetList().subscribe((res) => {
      this._allRows = res;
      var root = res.filter((f) => f.parentId == null);
      this.dataSource.data = root;
      setTimeout(() => {
        this.treeControl.dataNodes.forEach((n) => {
          if (n.level == 0) this.treeControl.expand(n);
        });
      });
      this.isLoading = false;
    });
  }

  onSelect() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      this.isLoading = true;
      this.kpiService.AddFromTemplates(this.data?.positionIds, this.mainForm.controls["periodId"].value || [],this.selectedItems).subscribe(res =>{
        this._messagesService.UpdateKpi(null); 
        this.isLoading = false;
        setTimeout(() => {          
          this.dialogRef.close();
        });
      });
    }
  }
}
