<mat-card>
  <mat-card-actions align="end">
    <mat-slide-toggle
      [(ngModel)]="showHidden"
      labelPosition="before"
      color="primary"
      (change)="buildTree()"
      i18n
      >Архивные</mat-slide-toggle
    >
    <button mat-icon-button (click)="onEdit(null)">
      <mat-icon color="primary">add</mat-icon>
    </button>
  </mat-card-actions>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <div class="list">
      @for (row of rows;track row.id) {
      <div
        class="box list-row"
        [class.hidden]="row.data.isHidden"
        [attr.data-level]="row.level"
        [attr.data-id]="row.id"
      >
        <div class="drag-icon">
          <div>
            <mat-icon
              color="primary"
              cdkDrag
              cdkDragHandle
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragMoved)="onDragMoved($event)"
              (cdkDragEnded)="cdkDragEnded($event)"
              [cdkDragData]="row"
              (cdkDragConstrainPosition)="dragConstrainPosition"
              cdkDragRootElement=".box"
            >
              drag_indicator
            </mat-icon>
          </div>
        </div>
        <div class="expander" [style.padding-left.px]="row.level*20">
          @if (row.hasChildren) {
          <button
            mat-icon-button
            (click)="toogleRow(row); $event.stopPropagation() "
          >
            <mat-icon color="primary">
              {{expandedRows[row.id] ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          } @else {
          <button mat-icon-button disabled></button>
          }
        </div>
        <div class="content">
          <div>
            {{row.data.name}}
          </div>
          <div class="code">
            {{row.data.code}}
          </div>
        </div>

        <div class="edit-btn">
          <button
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEdit(row.data)">
              <mat-icon color="primary">edit</mat-icon>
              <span i18n>Редактровать</span>
            </button>
            <button mat-menu-item (click)="onAddSub(row.data)">
              <mat-icon color="primary">add</mat-icon>
              <span i18n>Добавить</span>
            </button>
          </mat-menu>
        </div>
      </div>
      }
    </div>
  </mat-card-content>
</mat-card>
