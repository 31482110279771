import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { IListInfo, KpiService } from "../../services/kpi.service";
import { map, Observable, Subject, Subscription, takeUntil } from "rxjs";
import { IKpiListItem } from "../../models";
import { Bitrix24Service } from "../../services/bitrix24.service";
import {
  ConnectedPosition,
  ConnectionPositionPair,
} from "@angular/cdk/overlay";
import { MatTableDataSource } from "@angular/material/table";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import {
  KpiTypeFiveVariantsValues,
  KpiTypeThreeVariantsValues,
  KpiTypeYesNoValues,
  PLACE_DATA,
} from "../../consts";
import IPlaceParamsData from "../../services/bx24/entities/placeParamsData";
import { DictionaryService } from "../../services/dictionary.service";

import { saveAs } from "file-saver";
import { MessagesService } from "src/app/services/messages.service";
import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragMove,
  CdkDragStart,
  DragRef,
  Point,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import {
  ExportService,
  IColumnInfo,
  IFilterInfo,
} from "src/app/services/export.service";
import { MatDialog } from "@angular/material/dialog";
import { AddKpiFromLibWndComponent } from "src/app/components/add-kpi-from-lib-wnd/add-kpi-from-lib-wnd.component";

const moment = _rollupMoment || _moment;

export const MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: "MMM YYYY",
  },
  display: {
    dateInput: "MMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
interface IDropInfo {
  targetId: number;
  action?: "before" | "after" | "inside";
}
@Component({
  selector: "kpi-list",
  templateUrl: "./kpi-list-page.component.html",
  styleUrl: "./kpi-list-page.component.scss",
  providers: [provideMomentDateAdapter(MONTH_YEAR_FORMATS)],
})
export class KpiListPageComponent implements OnInit, OnDestroy {
  public KpiTypeYesNoValues = KpiTypeYesNoValues;
  public KpiTypeFiveVariantsValues = KpiTypeFiveVariantsValues;
  public KpiTypeThreeVariantsValues = KpiTypeThreeVariantsValues;

  destroy$: Subject<boolean> = new Subject<boolean>();
  public dropAction: IDropInfo | null = null;
  // public tableRows: MatTableDataSource<IKpiListItem> = new MatTableDataSource<
  //   IKpiListItem
  // >();
  private _allRows: IKpiListItem[] = [];
  public tableRows: IKpiListItem[] = [];
  public selectedRow: IKpiListItem | null = null;
  public displayedColumns = [
    "drag",
    "actions",
    "name",
    "code",
    "period",
    "plan",
    "fact",
    "weight",
    "execution",
    "unit",
    "status",
    "trend",
    "creator",
  ];

  public shortDisplayedColumns = [
    "drag",
    "actions",
    "name",
    "period",
    "plan",
    "fact",
    "weight",
    "execution",
    "unit",
  ];

  public selectedPositionId: number | null = null;
  public isShortView: boolean = false;
  public isLoading: boolean = true;
  public isLoadingData: boolean = true;
  public model: IListInfo | null = null;
  public total: any;
  public totalComplite: any;
  public isController: boolean = false;
  public isViewer: boolean = false;

  public searchForm: FormGroup;

  public expandedRows: Record<number, boolean> = {};
  public hasChildrenRows: Record<number, boolean> = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private kpiService: KpiService,
    public dialog: MatDialog,
    public bx: Bitrix24Service,
    public dictionaries: DictionaryService,
    @Inject(PLACE_DATA) public data: IPlaceParamsData,
    private _messagesService: MessagesService,
    private _exportService: ExportService
  ) {
    // console.log(data);
    this.isController = data.data?.role == "controller";
    this.isViewer = data.data?.role == "viewer";
    this.searchForm = new FormGroup({
      // from: new FormControl(this.PrepareDate(moment()), Validators.required),
      // to: new FormControl(this.PrepareDate(moment()), Validators.required),
      period: new FormControl(null as number | null),
      status: new FormControl(null as number | null),
    });
  }

  public getValueByProp(data: any[], propName: string, value: number) {
    for (let row of data) {
      if (row.value == value) return row[propName];
    }
    return null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this._messagesService.onUpdateKpi
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loadData();
      });
    this.dictionaries.GetDefaultPeriodId().subscribe((res) => {
      let selectedPeriod = res;

      if (this.data.data?.periodId != null) {
        selectedPeriod = Number.parseInt(this.data.data?.periodId);
        if (Number.isNaN(selectedPeriod)) {
          selectedPeriod = res;
        }
      }
      this.searchForm.controls["period"].setValue(selectedPeriod);
      this.loadData();
    });
  }

  private loadObs: Subject<any> = new Subject();
  private loadData() {
    if (this.searchForm.invalid) return;
    this.hasChildrenRows = {};
    this.isLoadingData = true;
    this.loadObs.next(null);
    if (this.data?.positionId) {
      this.kpiService
        .GetUserKpiList(
          this.data.positionId || 0,
          this.searchForm.getRawValue()
        )
        .pipe(takeUntil(this.loadObs))
        .subscribe(this.ProcessData.bind(this));
    } else {
      this.kpiService
        .GetKpiList(this.searchForm.getRawValue())
        .pipe(takeUntil(this.loadObs))
        .subscribe(this.ProcessData.bind(this));
    }
  }
  private _dragElement: IKpiListItem | null = null;
  private fillRows(
    sourceDs: IKpiListItem[],
    targetDs: IKpiListItem[],
    parentId: number | null,
    level: number,
    fullTree: boolean = false
  ) {
    let rows = sourceDs.filter((f) => f.parentId == parentId);

    for (let row of rows) {
      // if (row == this._dragElement) continue;
      row.level = level;
      targetDs.push(row);
      if (!!this.expandedRows[row.id] || fullTree) {
        this.fillRows(sourceDs, targetDs, row.id, level + 1, fullTree);
      }
    }

    // row.level = level;
    // var rows = sourceDs.filter((f) => f.parentId == row.id);
    // rows.forEach((e) => {
    //   targetDs.push(e);
    //   this.fillRows(sourceDs, targetDs, e, level + 1);
    // });
  }

  private BuildTableRows() {
    // this.hasChildrenRows = {};
    let result: IKpiListItem[] = [];
    // this._allRows.sort()

    this.fillRows(this._allRows, result, null, 0);

    this.tableRows = result;
  }

  private ProcessPosition() {
    let hasSelected = false;
    let selectedPos: any = this.model?.positions.find(
      (f) => f.id == this.selectedPositionId
    );
    let rows = selectedPos?.rows || [];
    this._allRows = rows;
    rows.forEach((e: any) => {
      if (this.selectedRow?.id == e.id) {
        hasSelected = true;
      }
      if (e.parentId == null) {
      } else {
        this.hasChildrenRows[e.parentId] = true;
      }
    });

    if (!hasSelected) {
      this.selectedRow = null;
    }
    this.BuildTableRows();
    this.total = selectedPos.total;
    this.totalComplite = selectedPos.totalComplite;
  }

  private ProcessData(res: IListInfo) {
    // let resultRows: IKpiListItem[] = [];

    this.isLoading = false;
    this.isLoadingData = false;
    // this._allRows = res.rows;
    this.model = res;
    this.hasChildrenRows = {};
    let hasSelected = false;
    // res.rows.forEach((e) => {
    //   if (this.selectedRow?.id == e.id) {
    //     hasSelected = true;
    //   }
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    if (this.selectedPositionId == null)
      this.selectedPositionId = this.model.positions[0]?.id;
    this.ProcessPosition();
  }
  onEditRow(row: IKpiListItem) {
    this.bx
      .editKpi(
        row.id,
        this.data?.positionId || 0,
        row.isGroup,
        null,
        null,
        this.data?.data?.role
      )
      .then((res) => {});
  }

  public onAddFromLib() {
    const dialogRef = this.dialog.open(AddKpiFromLibWndComponent, {
      width:"800px",
      minHeight: "640px",
      data: {
        positionIds: [this.selectedPositionId],
        periodId: this.searchForm.controls["period"].value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }
  public CopyKpi(kpi: IKpiListItem) {
    this.isLoading = true;
    this.kpiService.CopyKpi(kpi.id).subscribe((res) => {
      this.isLoading = false;
      this.loadData();
      this.bx
        .editKpi(
          res,
          this.data?.positionId || 0,
          kpi.isGroup,
          null,
          null,
          this.data?.data?.role
        )
        .then((res) => {});
    });
  }

  public onClickNew(isGroup: boolean, row: IKpiListItem | null = null) {
    let selectedRow = row;
    if (selectedRow == null) {
      selectedRow = this.selectedRow;
    }

    let parentId = null;

    if (selectedRow != null) {
      if (selectedRow.isGroup) {
        parentId = selectedRow.id;
      } else {
        parentId = selectedRow.parentId;
      }
    }
    if (selectedRow?.status?.id !== 1) {
      parentId = null;
    }
    let periodId =
      selectedRow?.isGroup || parentId != null
        ? selectedRow?.period?.id || null
        : null;
    if (parentId == null) {
      periodId = this.searchForm.controls["period"].value;
    }
    let positionId: number = this.selectedPositionId || 0;

    // let positionId: number = this.data?.positionId || 0;
    // if (positionId == 0){

    // }

    this.bx
      .editKpi(
        null,
        positionId,
        isGroup,
        parentId,
        periodId,
        this.data?.data?.role
      )
      .then((res) => {});
  }

  onRowClick(row: IKpiListItem) {
    if (this.selectedRow == row) {
      this.selectedRow = null;
    } else {
      this.selectedRow = row;
    }
  }

  private PrepareDate(date: Moment): Moment {
    const ctrlValue = date ?? moment();
    ctrlValue.month(date.month());
    ctrlValue.year(date.year());
    return ctrlValue;
  }
  onPositionChange(positionId: number) {
    this.selectedPositionId = positionId;
    this.ProcessPosition();
  }
  onSearchFormReset() {
    this.searchForm.reset({
      // from: this.PrepareDate(moment()),
      // to: this.PrepareDate(moment()),
      period: null,
      status: null,
    });
    this.loadData();
  }
  onSearchFormSubmit() {
    setTimeout(() => {
      this.loadData();
    });
  }
  setMonthAndYear(
    field: string,
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    var control = this.searchForm.get(field);
    control?.setValue(this.PrepareDate(normalizedMonthAndYear));
    datepicker.close();
  }

  toogleRow(element: IKpiListItem) {
    this.expandedRows[element.id] = !this.expandedRows[element.id];
    this.BuildTableRows();
  }

  async onDownloadClick() {
    var filters: IFilterInfo[] = [
      {
        title: "Сотрудник",
        value: this.model?.user?.name,
      },
      {
        title: "",
        value: "",
      },
    ];

    var form: any = this.searchForm.getRawValue();
    if (form.period) {
      let fValue: number = form.period;
      var dicValue = await this.dictionaries.Periods.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: "Период",
        value: dicValue || "",
      });
    }
    if (!!form.status) {
      let fValue: number = form.status;
      var dicValue = await this.dictionaries.Statuses.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: "Статус",
        value: dicValue || "",
      });
    }

    const exportColumns: IColumnInfo[] = [
      {
        title: "Цель/Показатель",
        field: (row) => `${" ".repeat(row.level * 4)} ${row.name || ""}`,
      },
      { title: "Код", field: (row) => row.code },
      { title: "План", field: (row) => row.plan, fieldMask: "#,##0.00" },
      { title: "Факт", field: (row) => row.fact, fieldMask: "#,##0.00" },
      { title: "Вес", field: (row) => row.weight, fieldMask: "#,##0.00" },
      {
        title: "Исполнение",
        field: (row) => {
          return row.execution / 100;
        },
        color: (row) => row.color,
        fieldMask: "0%",
      },
      { title: "Ед. Изм.", field: (row) => row.unit },
      { title: "Период", field: (row) => row.period?.name },
      { title: "Статус", field: (row) => row.status?.name },
      { title: "Тренд", field: (row) => row.trend?.name },
      { title: "Автор", field: (row) => row.creator?.name },
    ];
    let tableRows: any[] = [];
    this.fillRows(this._allRows, tableRows, null, 0, true);

    tableRows.push({
      // name:"Эффективность сотрудника",
      execution: this.total.execution,
      color: this.total.color,
    });

    this._exportService.ExportKpis(
      `Показатели ${this.model?.user?.name}`,
      "Цели и KPI",
      exportColumns,
      tableRows,
      filters
    );

    return;
  }

  private movedRows: Element[] = [];
  // drag&drop
  onDragStarted(event: CdkDragStart) {
    this._dragElement = event.source.data;
    const rootElement = event.source.getRootElement();
    rootElement.classList.add("list-row-drag");
    const elementLevel = parseInt(
      rootElement.getAttribute("data-level") || "-1"
    );
    let parent = rootElement.parentElement;
    this.movedRows = [];

    if (parent) {
      let isFind = false;
      for (let i = 0; i < parent?.children?.length; i++) {
        let elem = parent.children.item(i);
        if (elem == null) continue;

        if (elem == rootElement) {
          isFind = true;
          continue;
        }
        if (isFind) {
          let level = parseInt(elem?.getAttribute("data-level") || "-1");
          if (level > elementLevel) {
            elem.classList.add("list-row-drag");
            this.movedRows.push(elem);
          } else {
            break;
          }
        }
      }
    }

    // this.BuildTableRows();

    // console.log(event);
  }
  onDragMoved(event: CdkDragMove) {
    this.movedRows.forEach((elem) => {
      elem.setAttribute(
        "style",
        event.source.getRootElement().getAttribute("style") || ""
      );
    });

    let rowElement = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );
    while (rowElement != null && !rowElement.classList.contains("list-row")) {
      rowElement = rowElement.parentElement;
    }
    if (rowElement == null) {
      this.clearDragInfo();
      return;
    }
    if (rowElement.classList.contains("list-row-drag")) {
      this.clearDragInfo();
      return;
    }
    const targetRect = rowElement.getBoundingClientRect();
    const canDrop = rowElement.getAttribute("data-can-drop") == "true";

    let targetId = parseInt(rowElement.getAttribute("data-id") || "-1");

    this.dropAction = {
      targetId: targetId,
    };

    if (canDrop) {
      const oneThird = targetRect.height / 3;
      if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropAction["action"] = "before";
      } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropAction["action"] = "after";
      } else {
        // inside

        this.dropAction["action"] = "inside";
      }
    } else {
      const oneSecond = targetRect.height / 2;
      if (event.pointerPosition.y - targetRect.top < oneSecond) {
        // before
        this.dropAction["action"] = "before";
      } else {
        // after
        this.dropAction["action"] = "after";
      }
    }
    this.showDragInfo();
  }

  showDragInfo() {
    this.clearDragInfo();
    if (this.dropAction) {
      this.document
        .querySelector(`.list-row[data-id="${this.dropAction?.targetId}"]`)
        // .getElementById(`node-${this.dropActionTodo?.targetId}`)
        ?.classList.add("drop-" + this.dropAction?.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropAction = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  cdkDragEnded(event: CdkDragEnd) {
    // console.log(event);
    const dropAction = this.dropAction;
    const rootElement = event.source.getRootElement();
    const elements = [rootElement, ...this.movedRows];
    this.movedRows = [];
    setTimeout(() => {
      event.source.reset();
      elements.forEach((elem) => {
        elem.classList.add("cdk-drag-animating");
        elem.classList.remove("list-row-drag");
        elem.setAttribute("style", "");
      });
      setTimeout(() => {
        elements.forEach((elem) => {
          elem.classList.remove("cdk-drag-animating");
        });
      }, 300);
    });

    this.clearDragInfo(true);
    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);

    // debugger
    var targetRow = this.tableRows.find((f) => f.id == dropAction?.targetId);
    if (targetRow == null) {
      return;
    }
    let parentId: number | null = null;

    if (dropAction?.action == "inside") {
      event.source.data.parentId = targetRow.id;
      parentId = targetRow.id;
      this.expandedRows[targetRow.id] = true;
    } else {
      event.source.data.parentId = targetRow.parentId;
    }
    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    this.isLoading = true;
    this.kpiService
      .ChangeParent(event.source.data.id, parentId)
      .subscribe((res) => {
        this.loadData();
      });

    // this.hasChildrenRows = {};
    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    // this.BuildTableRows();

    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);
  }

  dragConstrainPosition(
    userPointerPosition: Point,
    dragRef: DragRef,
    dimensions: DOMRect,
    pickupPositionInElement: Point
  ): Point {
    return userPointerPosition;
  }
}
