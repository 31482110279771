<h2 mat-dialog-title i18n>Период</h2>
<mat-dialog-content>
  <form [formGroup]="mainForm">
    <mat-form-field>
      <mat-label i18n>Имя</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Код</mat-label>
      <input matInput formControlName="code" />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Период</mat-label>
      <mat-date-range-input [formGroup]="mainForm" [rangePicker]="picker">
        <input matStartDate formControlName="from" placeholder="Начало периода">
        <input matEndDate formControlName="to" placeholder="Конец периода">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    
    <div style="padding-bottom: 1rem;">
      <mat-slide-toggle formControlName="isDefault"  i18n
        >По умолчанию</mat-slide-toggle
      >
    </div>
    <div>
      <mat-slide-toggle formControlName="isHidden" i18n>Скрыть</mat-slide-toggle>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Отмена</button>
  <button mat-button (click)="onSave()" i18n>Ок</button>
</mat-dialog-actions>
