<loader [isLoading]="isLoading"></loader>
<div mat-dialog-title>
  <form [formGroup]="mainForm">
    <mat-form-field>
      <mat-label i18n>Период</mat-label>
      <mat-select matInput formControlName="periodId" [multiple]="false">
        <mat-option
          *ngFor="let item of dictionaries.Periods | async"
          [value]="item.id"
        >
          <div [style.padding-left.px]="24*item.level">
            {{ item.name }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Отмена</button>
  <button mat-button (click)="onSelect()" cdkFocusInitial>
    Ок
  </button>
</mat-dialog-actions>
