import { Component, Inject } from "@angular/core";
import { PLACE_DATA } from "src/app/consts";
import { ILicenseError } from "src/app/services/license.service";

const messages:any = {
  "period_expire":"Срок вашей подписки истёк",
  "user_limit":"Превышен лимит сотрудников"
}

@Component({
  selector: "app-license-error-page",
  templateUrl: "./license-error-page.component.html",
  styleUrl: "./license-error-page.component.scss",
})
export class LicenseErrorPageComponent {
  public message:string = "";
  constructor(@Inject(PLACE_DATA) private data: ILicenseError) {
    if (messages[data.message] == undefined){
      this.message = data.message
    } else {
      this.message =messages[data.message];
    }
  }
}
