import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { TableColumnDirective } from "./table-column.directive";

@Component({
  selector: "app-table-grid",
  templateUrl: "./table-grid.component.html",
  styleUrl: "./table-grid.component.scss",
})
export class TableGridComponent {
  @ContentChildren(TableColumnDirective) columns!: QueryList<
    TableColumnDirective
  >;
  @Input() public rows: any[] | null = [];
}
