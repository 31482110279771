<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div class="header">
        <span i18n>Цели и KPI</span>
        <div>
          <button
            mat-mini-fab
            color="primary"
            (click)="onClickNew(true)"
            i18n-matTooltip
            matTooltip="Добавить цель"
          >
            <mat-icon>ads_click</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="onClickNew(false)"
            i18n-matTooltip
            matTooltip="Добавить показатель"
          >
            <mat-icon>straighten</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="padding-bottom: 0;">
    <loader [isLoading]="isLoading"></loader>

    <div>
      <table mat-table [dataSource]="tableRows">
        <ng-container matColumnDef="drag">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon
              color="primary"
              cdkDrag
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragMoved)="onDragMoved($event)"
              (cdkDragEnded)="cdkDragEnded($event)"
              [cdkDragData]="element"
              (cdkDragConstrainPosition)="dragConstrainPosition"
              cdkDragRootElement="tr"
              >drag_indicator</mat-icon
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onEditRow(element)" i18n
                >Просмотр</button
              >
              <button mat-menu-item (click)="onClickNew(true, element)" i18n
                >Добавить цель</button
              >
              <button mat-menu-item (click)="onClickNew(false, element)" i18n
                >Добавить показатель</button
              >
              <button mat-menu-item (click)="onClickCopy(element)" i18n
                >Копировать</button
              >
              <button mat-menu-item (click)="onClickRemove(element)" i18n
                >Удалить</button
              >
            </mat-menu>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Цель/Показатель</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.padding-left.px]="element.level*40"
          >
            <div class="flex-center clickable">
              @if(!!hasChildrenRows[element.id]) {
              <button
                mat-icon-button
                (click)="toogleRow(element); $event.stopPropagation() "
              >
                <mat-icon class="mat-icon-rtl-mirror" color="primary">
                  {{expandedRows[element.id] ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              } @else {
              <button mat-icon-button disabled></button>
              }
              <mat-icon color="primary" style="flex: 0 0 auto;">
                @if (element.isGroup) { ads_click } @else { checklist }
            
              </mat-icon>
              <a (click)="onEditRow(element)">{{element.name}}</a>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef i18n
            >Эффективность сотрудника</td
          >
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef i18n>Код</th>
          <td mat-cell *matCellDef="let element">
            {{element.code}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef i18n>План</th>
          <td mat-cell *matCellDef="let element">
            <view-typed-value [typeId]="element.typeId" [value]="element.plan"></view-typed-value>     
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef i18n>Вес</th>
          <td mat-cell *matCellDef="let element">
            {{element.weight | number : '1.0-0'}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef i18n>Ед. Изм.</th>
          <td mat-cell *matCellDef="let element">{{element.unit.name}}</td>
        </ng-container>

        <ng-container matColumnDef="trend">
          <th mat-header-cell *matHeaderCellDef i18n>Тренд</th>
          <td mat-cell *matCellDef="let element">{{element.trend.name}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr
          class="list-row"
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
          [attr.data-level]="row.level"
          [attr.data-id]="row.id"
          [attr.data-can-drop]="row.isGroup"
          (click)="onRowClick(row)"
          [class.selected]="row.id == selectedRow?.id"
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
