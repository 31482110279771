<mat-card>
  <mat-card-actions align="end">
    <button mat-icon-button (click)="onAdd()">
      <mat-icon color="primary">add</mat-icon>
    </button>
  </mat-card-actions>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
      @for (row of rows; track row.id) {
      <div class="box" cdkDrag>
        <div class="drag-icon">
          <mat-icon cdkDragHandle color="primary">
            drag_indicator
          </mat-icon>
        </div>
        <div class="content">
          @if (row == editRow ) {
          <div class="edit-wrapper">
            <div class="edit-wrapper-input">

              <input
              class="inline-input"
              [(ngModel)]="row.name"
              cdkTrapFocus
              cdkTrapFocusAutoCapture
              (keypress)="onKeyPress($event)"
              (focus)="onFocusInput($event)"
              /> 
            </div>
            <div class="edit-wrapper-checkbox">
              <mat-checkbox  matTooltip="Скрыть" [(ngModel)]="row.isHidden"></mat-checkbox>
            </div>
          </div>
          } @else {
          <span>
            {{row.name}}
          </span>
          }
        </div>
        <div class="edit-btn">
          @if (row == editRow ) {
         
          } @else { @if(row.isHidden){
          <mat-icon color="primary">visibility_off</mat-icon>
          }}
          <button
            mat-icon-button
            (click)="onEditClick(row)"
            [disabled]="!row.canEdit"
          >
            <mat-icon color="primary">
              @if(row.canEdit){ {{row==editRow ? "save" : "edit" }} }
            </mat-icon>
          </button>
        </div>
      </div>
      }
    </div>
  </mat-card-content>
</mat-card>
