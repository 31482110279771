<div class="widgets-wrapper">
  <app-widget
    icon="auto_graph"
    iconColor="rgb(102,145,231)"
    iconBackgroundColor="rgb(232,239,251)"
    i18n-titleText
    titleText="Мои KPI"
    (onTitleClick)="bx.openView('user-kpis-page')"
  >
  &nbsp;
  </app-widget>
  <app-widget
    *ngIf="info?.quantityForApproval > 0"
    icon="groups"
    iconColor="rgb(232,189,83)"
    iconBackgroundColor="rgb(252,245,229)"
    i18n-titleText
    titleText="Моя команда"
    (onTitleClick)="bx.openView('evaluation-users')"
  >
    {{info?.quantityForApproval}}
   
  </app-widget>
 
  <app-widget
    *ngIf="info?.quantityForControl > 0"
    icon="supervised_user_circle"
    iconColor="rgb(80,195,230)"
    iconBackgroundColor="rgb(229,246,251)"
    i18n-titleText
    titleText="Контролируемые"
    videoHref="https://www.youtube.com/watch?v=0YRS9csH-d0"
    (onTitleClick)="bx.openView('controlled-list')"
  >
    {{info?.quantityForControl}}
  </app-widget>
  <app-widget
    *ngIf="info?.quantityForView > 0"
    icon="visibility"
    iconColor="rgb(108,214,201)"
    iconBackgroundColor="rgb(233,248,238)"
    i18n-titleText
    titleText="Наблюдаемые"
    (onTitleClick)="bx.openView('viewing-list')"
  >
    {{info?.quantityForView}}
   
  </app-widget>  
</div>

<div class="widgets-wrapper" *ngIf="isAdmin">
  <app-widget
    icon="admin_panel_settings"
    iconColor="rgb(108,214,201)"
    iconBackgroundColor="rgb(233,248,238)"
    i18n-titleText
    titleText="Администрирование"
    videoHref="https://www.youtube.com/watch?v=zdWMw0QX9wk"
    (onTitleClick)="bx.openView('orgstructure')"
  >
    <ul class="menu" type="square">
      <li class="menu-item" (click)="bx.openView('orgstructure')" i18n
        >Структура и штатное расписание</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('orgstructure', {tab:'dep'})"
        i18n
        >Департаменты</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('orgstructure', {tab:'pos'})"
        i18n
        >Должности</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('orgstructure', {tab:'emp'})"
        i18n
        >Сотрудники</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('orgstructure', {tab:'lib'})"
        i18n
        >Библиотека KPI</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('orgstructure', {tab:'lic'})"
        i18n
        >Лицензия</li
      >
      <li
        class="menu-item"
        (click)="bx.openWnd('api-keys-page','Ключи API',720)"
        i18n
        >Ключи API</li
      >
      <li
        class="menu-item"
        (click)="bx.openView('functions-page')"
        i18n
        >Формулы</li
      >

      <li
      class="menu-item"
      (click)="bx.openWnd('update-access-keys','Ключи доступа',720)"
      i18n
      >Обновить ключи</li
    >
    </ul>
  </app-widget>

  <app-widget
    icon="local_library"
    iconColor="rgb(108,214,201)"
    iconBackgroundColor="rgb(233,248,238)"
    i18n-titleText
    titleText="Справочники"
  >
    <ul class="menu" type="square">
      <li
        class="menu-item"
        (click)="bx.openWnd('dictionaries-page','Справочники',720, {tab:'period'})"
        i18n
        >Периоды</li
      >
      <li
        class="menu-item"
        (click)="bx.openWnd('dictionaries-page','Справочники',720, {tab:'units'})"
        i18n
        >Единицы измерения</li
      >
    </ul>
  </app-widget>
  <app-widget
    icon="table_view"
    iconColor="rgb(232,189,83)"
    iconBackgroundColor="rgb(252,245,229)"
    i18n-titleText
    titleText="Отчет"
  >
    <ul class="menu" type="square">
      <li
        class="menu-item"
        (click)="onKpiReportClick()"
        i18n
        >Исполнение за период</li
      >
      
    </ul>
  </app-widget>
</div>

<div class="widgets-wrapper">
  <app-widget
    icon="library_books"
    iconColor="rgb(102,145,231)"
    iconBackgroundColor="rgb(232,239,251)"
    i18n-titleText
    titleText="Помощь"
  >
    <ul class="menu-help">
      <li class="menu-item">
        <a
          target="_blank"
          href="https://help.targetplanning.ru/o_target_planning.htm"
          ><mat-icon>bookmark_border</mat-icon>
          <span i18n>О TARGET PLANNING</span></a
        >
      </li>
      <li class="menu-item">
        <a
          target="_blank"
          href="https://help.targetplanning.ru/rukovodstvo_polzovatelya.htm"
          ><mat-icon>bookmark_border</mat-icon>
          <span i18n>Руководство пользователя</span></a
        >
      </li>
      <li class="menu-item">
        <a
          target="_blank"
          href="https://help.targetplanning.ru/rukovodstvo_soglasuyushchego_rukovoditelya.htm"
          ><mat-icon>bookmark_border</mat-icon>
          <span i18n>Руководство согласующего руководителя</span></a
        >
      </li>
      <li class="menu-item">
        <a
          target="_blank"
          href="https://help.targetplanning.ru/rukovodstvo_administratora.htm"
          ><mat-icon>bookmark_border</mat-icon>
          <span i18n>Руководство администратора</span></a
        >
      </li>
      <li class="menu-item">
        <a target="_blank" href="https://www.youtube.com/watch?v=zdWMw0QX9wk"
          ><mat-icon>play_circle_outline</mat-icon>
          <span i18n>Установка и администрирование. (ч. 1).</span></a
        >
      </li>
      <li class="menu-item">
        <a target="_blank" href="https://www.youtube.com/watch?v=a8yBkJz6H5M"
          ><mat-icon>play_circle_outline</mat-icon>
          <span i18n>Планирование и оценка. (ч. 2)</span></a
        >
      </li>
    </ul>
  </app-widget>
</div>
