<!-- <h2 mat-dialog-title>Ключ API</h2> -->
<mat-dialog-content>
  <p i18n>Сохраните ключ</p>
  <div>
    <textarea [value]="data" rows="5" readonly> </textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
