<!-- <loader [isLoading]="isLoading"></loader> -->

<mat-card class="user-info-card">
  <mat-card-content>
    <div class="content-wrapper">
      <div class="user-info-wrapper">
        <div class="awatar">
          <avatar [url]="model?.user?.photo"></avatar>
        </div>
        <div>
          {{model?.user?.name}}
          <div class="position-info">
            {{model?.user?.position}}
          </div>
        </div>
      </div>
      <!-- <div class="total-execution" *ngIf="!!total">
        Эффективность:
        <span [style.color]="total?.color">{{total?.execution | number : '1.0-0'}}%</span
        >
      </div> -->
      <div class="space"></div>
      <div class="filters">
        <form
          [formGroup]="searchForm"
          class="search-form"
          (ngSubmit)="onSearchFormSubmit()"
        >
          <div class="period-form">
            <mat-form-field class="period-field">
              <mat-label i18n>Период</mat-label>
              <mat-select
                matInput
                formControlName="period"
                (valueChange)="onSearchFormSubmit()"
              >
                <mat-option
                  *ngFor="let item of dictionaries.PeriodsWithOutHidden | async"
                  [value]="item.id"
                >
                  <div [style.padding-left.px]="24*item.level">
                    {{ item.name }}
                  </div></mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label i18n>Статус</mat-label>
              <mat-select
                matInput
                formControlName="status"
                (valueChange)="onSearchFormSubmit()"
              >
                <mat-option
                  *ngFor="let item of dictionaries.Statuses | async"
                  [value]="item.id"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <button mat-mini-fab type="submit" color="primary">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button type="button" (click)="onSearchFormReset()">
            <mat-icon>search_off</mat-icon>
          </button>
          <help-btn
            page="podsistema__sozdaniya_i_redaktirovaniya_tselej_i_kpi_"
          ></help-btn>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div class="header">
        @if((model?.positions?.length || 0) > 1) {
        <div>
          <mat-button-toggle-group>
            @for (item of model?.positions; track item.id) {
            <mat-button-toggle
              class="custon-tb"
              [value]="item.id"
              [checked]="selectedPositionId == item.id"
              (change)="onPositionChange(item.id)"
              >{{item.name}}
              <span
                *ngIf="item.total.execution !== null"
                [style.color]="item.total.color"
                >({{item.total.execution | number : '1.0-0'}}%)</span
              ></mat-button-toggle
            >
            }
          </mat-button-toggle-group>
        </div>
        } @else {
        <div>
          <span i18n>Цели и KPI</span>
        </div>
        }

        <!-- <div class="position-list">
          @for (item of model?.positions; track item.id) {
          <div class="position-list-item" [class.selected]="item.id == selectedPositionId"
          (click)="onPositionChange(item.id)"
          >{{item.name}}</div>
          }
        </div> -->
        <!-- } -->
        <!-- <div style="flex: 1 1 auto;"></div> -->
        <div>
          <div class="slider">
            <mat-slide-toggle
              [(ngModel)]="isShortView"
              labelPosition="before"
              color="primary"
            ></mat-slide-toggle>
          </div>
          @if (!isController && !isViewer) {
          <button
            mat-mini-fab
            color="primary"
            (click)="onClickNew(true)"
            i18n-matTooltip
            matTooltip="Добавить цель"
          >
            <mat-icon>ads_click</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="onClickNew(false)"
            i18n-matTooltip
            matTooltip="Добавить показатель"
          >
            <mat-icon>straighten</mat-icon>
          </button>

          <button
            mat-mini-fab
            color="primary"
            (click)="onAddFromLib()"
            i18n-matTooltip
            matTooltip="Добавить из библиотеки"
          >
            <mat-icon>library_books</mat-icon>
          </button>
          }
          <button mat-icon-button (click)="onDownloadClick()" color="primary">
            <mat-icon>download</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="padding-bottom: 0;">
    <!-- <loader [isLoading]="true"></loader> -->
    <loader [isLoading]="isLoadingData || isLoading"></loader>

    <div>
      <table mat-table [dataSource]="tableRows">
        <ng-container matColumnDef="drag">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if(element.status?.canEdit && !isController && !isViewer){

            <mat-icon
              color="primary"
              cdkDrag
              cdkDragHandle
              [class.disabled]="!element.status?.canEdit"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragMoved)="onDragMoved($event)"
              (cdkDragEnded)="cdkDragEnded($event)"
              [cdkDragData]="element"
              [cdkDragDisabled]="!element.status?.canEdit"
              (cdkDragConstrainPosition)="dragConstrainPosition"
              cdkDragRootElement="tr"
              >drag_indicator</mat-icon
            >
            }
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onEditRow(element)" i18n
                >Просмотр</button
              >
              @if (!isController && !isViewer) { @if(element.status?.id == 1){
              <button mat-menu-item (click)="onClickNew(true, element)" i18n
                >Добавить цель</button
              >
              <button mat-menu-item (click)="onClickNew(false, element)" i18n
                >Добавить показатель</button
              >
              }

              <button mat-menu-item (click)="CopyKpi(element)" i18n
                >Копировать</button
              >
              }
            </mat-menu>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Цель/Показатель</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.padding-left.px]="element.level*40"
          >
            <div class="flex-center clickable">
              @if(!!hasChildrenRows[element.id]) {
              <button
                mat-icon-button
                (click)="toogleRow(element); $event.stopPropagation() "
              >
                <mat-icon class="mat-icon-rtl-mirror" color="primary">
                  {{expandedRows[element.id] ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              } @else {
              <button mat-icon-button disabled></button>
              }
              <mat-icon color="primary" style="flex: 0 0 auto;">
                @switch (element.categoryId) { @case (1) { ads_click } @case (2)
                { checklist } @case (3) { straighten } }
              </mat-icon>
              <a (click)="onEditRow(element)">{{element.name}}</a>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef i18n
            >Эффективность сотрудника</td
          >
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef i18n>Код</th>
          <td mat-cell *matCellDef="let element">
            {{element.code}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef i18n>План</th>
          <td mat-cell *matCellDef="let element">
            <view-typed-value [typeId]="element.typeId" [value]="element.plan"></view-typed-value> 
           
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="fact">
          <th mat-header-cell *matHeaderCellDef i18n>Факт</th>
          <td mat-cell *matCellDef="let element">
            <view-typed-value [typeId]="element.typeId" [value]="element.fact"></view-typed-value> 
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef i18n>Вес</th>
          <td mat-cell *matCellDef="let element">
            {{element.weight | number : '1.0-0'}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="execution">
          <th mat-header-cell *matHeaderCellDef i18n>Исполнение</th>
          <td mat-cell *matCellDef="let element" class="custom-color">
            <div>
              {{element.execution | number : '1.1-1'}}%
            </div>
            <mat-progress-bar
              mode="determinate"
              [value]="element.execution"
              [style.color]="element.color"
            ></mat-progress-bar>
          </td>
          <td mat-footer-cell *matFooterCellDef class="custom-color">
            <!-- <div>
              {{totalPercent | number : '1.0-0'}}%
            </div> -->
            <mat-progress-bar
              mode="determinate"
              [value]="total?.execution"
              [style.color]="total?.color"
            ></mat-progress-bar>
          </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef i18n>Ед. Изм.</th>
          <td mat-cell *matCellDef="let element">{{element.unit}}</td>
          <td mat-footer-cell *matFooterCellDef>
            {{total?.execution | number : '1.1-1'}}%
          </td>
        </ng-container>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef i18n>Период</th>
          <td mat-cell *matCellDef="let element">
            {{element.period.name}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef i18n>Статус</th>
          <td mat-cell *matCellDef="let element" class="status">
            <mat-chip [style.background-color]="element.status?.color"
            matBadge="!" matBadgeSize="small" [matBadgeHidden]="!element.needApprove"
              >{{element.status?.name}}</mat-chip
            >            
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="trend">
          <th mat-header-cell *matHeaderCellDef i18n>Тренд</th>
          <td mat-cell *matCellDef="let element">{{element.trend.name}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="creator">
          <th mat-header-cell *matHeaderCellDef i18n>Автор</th>
          <td mat-cell *matCellDef="let element">
            <div class="user">
              <avatar [url]="element.creator.photo"></avatar>
              {{element.creator.name}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="isShortView ? shortDisplayedColumns : displayedColumns;"
        ></tr>
        <tr
          class="list-row"
          mat-row
          *matRowDef="let row; columns: isShortView ? shortDisplayedColumns : displayedColumns;"
          [attr.data-level]="row.level"
          [attr.data-id]="row.id"
          [attr.data-can-drop]="row.isGroup && (row.status?.canEdit)"
          (click)="onRowClick(row)"
          [class.selected]="row.id == selectedRow?.id"
        ></tr>
        <tr
          class="total-row"
          mat-footer-row
          *matFooterRowDef="isShortView ? shortDisplayedColumns : displayedColumns; sticky: true"
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
