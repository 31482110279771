<mat-card>
  <mat-card-title>
    <div style="padding: 1rem;">
      <mat-toolbar class="action-toolbar">
        <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [disabled]="selection.isEmpty()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item i18n (click)="onChangePositions('approvers')"
              >Изменение согласующих</button
            >
            <button mat-menu-item i18n (click)="onChangePositions('inspectors')"
              >Изменение контролеров</button
            >
            <button mat-menu-item i18n (click)="onChangePositions('viewers')"
              >Изменение наблюдателей</button
            >
            <mat-divider></mat-divider>
            <button mat-menu-item i18n (click)="onChangeAccess(false)"
              >Отключить от системы</button
            >

            <button mat-menu-item i18n (click)="onChangeAccess(true)"
              >Подключить к системе</button
            >
            <mat-divider></mat-divider>
            <button mat-menu-item i18n (click)="onChangeArchived(true)"
              >В архив</button
            >
            <button mat-menu-item i18n (click)="onChangeArchived(false)"
              >Из архива</button
            >
            <mat-divider></mat-divider>
            <button mat-menu-item i18n (click)="onAddKpisFromTemplates()"
              >Установить KPI</button
            >
          </mat-menu>
        </div>
        <span style="flex: 1 1 10rem;" class="example-spacer"></span>

        <form class="search-form" [formGroup]="filterForm">
          <mat-form-field>
            <mat-label i18n>Фильтр</mat-label>
            <input matInput formControlName="searchString" />
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label i18n>Департамент</mat-label>
            <select-department formControlName="departments" ></select-department>
            <!-- <mat-select formControlName="departments" multiple>
              <mat-option
                *ngFor="let item of departments | async"
                [value]="item.id"
              >
                <div [style.padding-left.px]="24*item.level">
                  {{ item.name }}
                </div>
              </mat-option>
            </mat-select> -->
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Статус</mat-label>
            <mat-select matInput formControlName="status" multiple>
              <mat-option [value]="true" i18n>В архиве</mat-option>
              <mat-option [value]="false" i18n>Активен</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Доступ</mat-label>
            <mat-select matInput formControlName="access" multiple>
              <mat-option [value]="true" i18n>Да</mat-option>
              <mat-option [value]="false" i18n>Нет</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="filterForm.reset()">
            <mat-icon>search_off</mat-icon>
          </button>
        </form>
      </mat-toolbar>
    </div>
  </mat-card-title>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>

  
    <table mat-table [dataSource]="tableRows" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            color="primary"
            (click)="onEditDepartment(null)"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="onEditDepartment(element)">
            <mat-icon color="primary">edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" i18n
          >Наименование</th
        >
        <td mat-cell *matCellDef="let element">
          <div class="flex-center">
            <div>
              <mat-icon color="primary">home</mat-icon>
            </div>
            <div>
              {{element.name}}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="code" i18n
          >Код</th
        >
        <td mat-cell *matCellDef="let element">
          {{element.code}}
        </td>
      </ng-container>
      <ng-container matColumnDef="approvers">
        <th mat-header-cell *matHeaderCellDef i18n>Согласующие</th>
        <td mat-cell *matCellDef="let element">
          <div class="chip-wrapper">
            <mat-chip-set>
              @for (row of element.approvers; track row.id) {
              <user-widget [user]="row.user" [short]="true"></user-widget>
              }
            </mat-chip-set>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="inspectors">
        <th mat-header-cell *matHeaderCellDef i18n>Контролёры</th>
        <td mat-cell *matCellDef="let element">
          <div class="chip-wrapper">
            <mat-chip-set>
              @for (row of element.inspectors; track row.id) {
              <user-widget [user]="row.user" [short]="true"></user-widget>
              }
            </mat-chip-set>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="viewers">
        <th mat-header-cell *matHeaderCellDef i18n>Наблюдатели</th>
        <td mat-cell *matCellDef="let element">
          <div class="chip-wrapper">
            <mat-chip-set>
              @for (row of element.viewers; track row.id) {
              <user-widget [user]="row.user" [short]="true"></user-widget>
              }
            </mat-chip-set>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="employee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="user" i18n
          >Сотрудник</th
        >
        <td mat-cell *matCellDef="let element">
          <!-- @for (item of row.approvers; track item.id) { -->
          @if (!!element.user){
          <user-widget [user]="element.user"></user-widget>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="archived"
          start="desc"
          i18n
          >Статус</th
        >
        <td mat-cell *matCellDef="let element">
          @if(element.archived){
            <span i18n>В архиве</span>
          }
          
        </td>
      </ng-container>
      <ng-container matColumnDef="department">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="departmentName"
          i18n
          >Департамент</th
        >
        <td mat-cell *matCellDef="let element">
          {{element.departmentName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="disableAccess">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="disableAccess"
          start="asc"
          i18n
          >Доступ</th
        >
        <td mat-cell *matCellDef="let element">
          @if(!element.disableAccess){
          <mat-icon>done</mat-icon>
          }
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="PaginatorPageSizes" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
