import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OrgStructureService } from "../../services/org-structure.service";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from "@angular/material/tree";
import { Subject, debounceTime } from "rxjs";
export enum RowTypeEnum {
  department,
  position,
}

interface ITreeNode {
  id: number;
  name: string;
  user?: any;
  children?: [];
  positions?: [];
}

interface IFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  position: any | null;
}

@Component({
  selector: "app-select-position-wnd",
  templateUrl: "./select-position-wnd.component.html",
  styleUrl: "./select-position-wnd.component.scss",
})
export class SelectPositionWndComponent implements OnInit {
  public isLoading: boolean = true;
  public selectedItems: any[] = [];
  private _allRows: any[] = [];
  private $onSearch = new Subject();

  public filterText: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private orgStructureService: OrgStructureService
  ) {
    this.selectedItems = (data || []).slice();
    // console.log(data)
    this.$onSearch.pipe(debounceTime(300)).subscribe((res) => {
      this.prepareData();
    });
  }
  onChangeSearchText() {
    this.$onSearch.next(null);
  }

  public isChecked(node: any) {
    // console.log(node);
    return this.selectedItems.findIndex((f) => f.id == node.id) >= 0;
  }

  public toogleItem(node: any) {
    let index = this.selectedItems.findIndex((f) => f.id == node.id);
    if (index == -1) {
      this.selectedItems.push(node);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  private _transformer = (node: ITreeNode, level: number) => {
    return {
      expandable:
        (!!node.children && node.children.length > 0) ||
        (!!node.positions && node.positions?.filter((f:any) => f?.user?.isBitrixUser).length > 0 ),
      name: node.name,
      level: level,
      position: node,
    };
  };
  treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => {
      let result: any[] = node.children || [];
      if (Array.isArray(node.positions)) {
        result?.push(
          ...node.positions.filter((f:any) => f?.user?.isBitrixUser).map((m: any) => {
            let res = { ...m, isPosition: true };
            return res;
          })
        );
      }
      // console.log(result);
      return result;
    }
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IFlatNode) => node.expandable;

  private GetFiltredItem(rows: any[]): any[] {
    var result: any[] = [];
    for (var row of rows) {
      if (row.isPosition) continue;
      var children = this.GetFiltredItem(row.children);
      const text = this.filterText.toLowerCase();
      var positions = (row.positions || []).filter(
        (f: any) =>
          f.name?.toLowerCase().indexOf(text) > -1 ||
          f.user?.name?.toLowerCase().indexOf(text) > -1
      );
      if (positions.length == 0 && children.length == 0) continue;

      result.push({
        ...row,
        children: children,
        positions: positions,
      });
    }
    return result;
  }

  private prepareData() {
    let data: any[] = [];
    if (!!this.filterText) {
      data = this.GetFiltredItem(this._allRows);
    } else {
      data = JSON.parse(JSON.stringify(this._allRows));
    }
    data = JSON.parse(JSON.stringify(data));
    this.dataSource.data = data;
    setTimeout(() => {
      if (!!this.filterText) {
        this.treeControl.expandAll();
      } else {
        this.treeControl.dataNodes.forEach((n) => {
          if (n.level == 0) this.treeControl.expand(n);
        });
      }
    });
  }
  ngOnInit(): void {
    this.orgStructureService.GetList().subscribe((res: ITreeNode[]) => {
      this._allRows = res;
      this.prepareData();
      this.isLoading = false;
    });
  }
}
