import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IListItem{
  id:number;
  name:string;
  canDelete:boolean;
}

export interface IFunctionParametrModel{
  name: string;
  displayName: string;
  valueType: string;
  required: boolean;
}
export interface ICalcFunctionModel{
  id:number;
  name:string;
  description:string;
  execCode:string;
  planCode:string;
  factCode:string;
  parametrs:IFunctionParametrModel[];
}

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(private http: HttpClient) { }

  public GetList(): Observable<IListItem[]> {
    return this.http.get<IListItem[]>("/api/functions/list");
  }

  public Get(id: number): Observable<ICalcFunctionModel> {
    return this.http.get<ICalcFunctionModel>(`/api/functions/get/${id}`);
  }

  public Save(model: ICalcFunctionModel): Observable<number> {
    return this.http.post<number>(`/api/functions/save`, model);
  }
  public Remove(id: number): Observable<number> {
    return this.http.post<number>(`/api/functions/remove`, id);
  }
}
