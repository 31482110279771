import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IFunctionParametrModel } from "src/app/services/functions.service";

@Component({
  selector: "app-edit-function-parametr-wnd",
  templateUrl: "./edit-function-parametr-wnd.component.html",
  styleUrl: "./edit-function-parametr-wnd.component.scss",
})
export class EditFunctionParametrWndComponent {
  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    displayName: new FormControl("", Validators.required),
    valueType: new FormControl("", Validators.required),
    required: new FormControl(false),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFunctionParametrModel,
    public dialogRef: MatDialogRef<EditFunctionParametrWndComponent>
  ) {
    this.mainForm.setValue(data);
  }

  onSave() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      this.dialogRef.close(this.mainForm.getRawValue());
    }
  }
}
