import { Injectable } from "@angular/core";
import XLSX, { WorkSheet } from "sheetjs-style";
import { saveAs } from "file-saver";

export interface IColumnInfo {
  title: string;
  field?: (row: any) => any;
  fieldMask?: string;
  color?: (row: any) => string;
}

export interface IFilterInfo {
  title: string;
  value: string;
}

const border = {
  right: {
    style: "thin",
    color: "000000",
  },
  left: {
    style: "thin",
    color: "000000",
  },
  top: {
    style: "thin",
    color: "000000",
  },
  bottom: {
    style: "thin",
    color: "000000",
  },
};

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor() {}

  private dataTableIndex: number = 0;
  private headerSizes: number[] = [];

  private AddSheetRow(sheet: WorkSheet, data: any[]) {
    // console.log(data);
    XLSX.utils.sheet_add_aoa(sheet, [data], {
      origin: { r: this.dataTableIndex, c: 0 },
    });
    this.dataTableIndex++;
  }
  private ProcessRows(
    sheet: WorkSheet,
    rows: any[],
    columns: IColumnInfo[],
    level: number
  ) {
    
    for (let row of rows) {
      this.AddSheetRow(
        sheet,
        columns.map((column, index) => {
          let cellValue = column.field ? column.field(row) : "";
          if (cellValue === null || cellValue === undefined) cellValue = "";
          let len =
            `${cellValue}`.length +
            `${typeof cellValue == "number" ? Math.ceil(cellValue) : cellValue}`
              .length /
              3;
          if (this.headerSizes[index] < len) this.headerSizes[index] = len;
          var result: any = {
            v: cellValue,
            t: (typeof cellValue == "number" ) ? "n" : "s",
            s: {
              
              border: border,
            },
            z:null
          };
          if (column.fieldMask !== undefined) {
            result.z = column.fieldMask;
          }
          if (column.color){
            let color = column.color(row) || '';
            if (color){
              result.s.fill = {
                fgColor: { rgb: `${color}`.replace("#", "") },
              }
            }
          }
          return result;
        })
      );
      // if (Array.isArray(row.children)) {
      //   this.ProcessRows(sheet, row.children, columns, level+1);
      // }
    }
  }
  public ExportKpis(
    fileName: string,
    sheetName: string,
    columns: IColumnInfo[],
    rows: any[],
    filters: IFilterInfo[]
  ) {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    this.dataTableIndex = 0;
    this.headerSizes = columns.map((m) => m.title.length);
    //добавляем фильтры
    filters.forEach((item) => {
      this.AddSheetRow(ws, [
        {
          v: item.title,
          s: {
            font: {
              bold: true,
            },
          },
        },
        {
          v: item.value,
        },
      ]);
    });
    this.dataTableIndex++;
    //Заголовки колонок
    this.AddSheetRow(
      ws,
      columns.map((item) => {
        return {
          v: item.title,
          s: {
            font: {
              bold: true,
            },
            border: border,
          },
        };
      })
    );

    this.ProcessRows(ws, rows, columns,0);

    //выставляем размеры колонок
    ws["!cols"] = columns.map((m, idx) => {
      return { wch: this.headerSizes[idx] + 2 };
      // return null;
    });

    let data = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var buf = new ArrayBuffer(data.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < data.length; i++) view[i] = data.charCodeAt(i) & 0xff;

    saveAs(
      new Blob([buf], { type: "application/octet-stream" }),
      `${fileName}.xlsx`
    );
  }
}
