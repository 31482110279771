import {
  CdkDragStart,
  CdkDragMove,
  CdkDragEnd,
  Point,
  DragRef,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { KpiTypeYesNoValues, KpiTypeFiveVariantsValues, KpiTypeThreeVariantsValues } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import {
  IKpiTemplateModel,
  KpiTemplateService,
} from "src/app/services/kpi-template.service";
import { MessagesService } from "src/app/services/messages.service";
interface IDropInfo {
  targetId: number;
  action?: "before" | "after" | "inside";
}
@Component({
  selector: "app-kpi-templates",
  templateUrl: "./kpi-templates.component.html",
  styleUrl: "./kpi-templates.component.scss",
})
export class KpiTemplatesComponent {


  destroy$: Subject<boolean> = new Subject<boolean>();
  public isLoading: boolean = true;
  public displayedColumns = [
    "drag",
    "actions",
    "name",
    "code",
    "plan",
    "weight",
    "unit",
    "trend",
  ];

  public expandedRows: Record<number, boolean> = {};
  public hasChildrenRows: Record<number, boolean> = {};
  public tableRows: IKpiTemplateModel[] = [];
  public selectedRow: IKpiTemplateModel | null = null;
  private _allRows: IKpiTemplateModel[] = [];
  private _dragElement: IKpiTemplateModel | null = null;
  public dropAction: IDropInfo | null = null;
  /**
   *
   */
  constructor(
    private _messagesService: MessagesService,
    public bx: Bitrix24Service,
    @Inject(DOCUMENT) private document: Document,
    private kpiTemplateService: KpiTemplateService
  ) {}

  private loadObs: Subject<any> = new Subject();
  private loadData() {
    this.hasChildrenRows = {};
    this.isLoading = true;
    this.loadObs.next(null);

    this.kpiTemplateService
      .GetList()
      .pipe(takeUntil(this.loadObs))
      .subscribe(this.ProcessData.bind(this));
  }
  private fillRows(
    sourceDs: IKpiTemplateModel[],
    targetDs: IKpiTemplateModel[],
    parentId: number | null,
    level: number,
    fullTree: boolean = false
  ) {
    let rows = sourceDs.filter((f) => f.parentId == parentId);

    for (let row of rows) {
      row.level = level;
      targetDs.push(row);
      if (!!this.expandedRows[row.id] || fullTree) {
        this.fillRows(sourceDs, targetDs, row.id, level + 1, fullTree);
      }
    }
  }
  private BuildTableRows() {
    let result: IKpiTemplateModel[] = [];
    this.fillRows(this._allRows, result, null, 0);
    this.tableRows = result;
  }

  private ProcessData(rows: IKpiTemplateModel[]) {
    this.isLoading = false;
    this._allRows = rows;
    this.hasChildrenRows = {};
    let hasSelected = false;
    rows.forEach((e) => {
      if (this.selectedRow?.id == e.id) {
        hasSelected = true;
      }
      if (e.parentId == null) {
      } else {
        this.hasChildrenRows[e.parentId] = true;
      }
    });
    this.BuildTableRows();

    if (!hasSelected) {
      this.selectedRow = null;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this._messagesService.onUpdateKpi
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loadData();
      });
    this.loadData();
  }
  onEditRow(row: any) {
    this.bx.editKpiTemplate(row.id, row.isGroup, null).then((res) => {});
  }
  public onClickRemove(element: IKpiTemplateModel) {
    if (confirm($localize`Вы действительно хотите удалить '${element.name}'?`) == false)
      return;

    this.isLoading = true;
    this.kpiTemplateService.RemoveKpi(element.id).subscribe((res) => {
      this.loadData();
    });
  }

  public onClickCopy(row:any){
    this.isLoading = true;
    this.kpiTemplateService.Copy(row.id).subscribe(res =>{
      this.loadData();
    })
  }

  public onClickNew(isGroup: boolean, row: any | null = null) {
    let selectedRow = row;
    if (selectedRow == null) {
      selectedRow = this.selectedRow;
    }
    let parentId = null;

    if (selectedRow != null) {
      if (selectedRow.isGroup) {
        parentId = selectedRow.id;
      } else {
        parentId = selectedRow.parentId;
      }
    }
    this.bx.editKpiTemplate(null, isGroup, parentId).then((res) => {});
  }

  onRowClick(row: any) {
    if (this.selectedRow == row) {
      this.selectedRow = null;
    } else {
      this.selectedRow = row;
    }
  }
  toogleRow(element: any) {
    this.expandedRows[element.id] = !this.expandedRows[element.id];
    this.BuildTableRows();
  }

  private movedRows: Element[] = [];
  // drag&drop
  onDragStarted(event: CdkDragStart) {
    this._dragElement = event.source.data;
    const rootElement = event.source.getRootElement();
    rootElement.classList.add("list-row-drag");
    const elementLevel = parseInt(
      rootElement.getAttribute("data-level") || "-1"
    );
    let parent = rootElement.parentElement;
    this.movedRows = [];

    if (parent) {
      let isFind = false;
      for (let i = 0; i < parent?.children?.length; i++) {
        let elem = parent.children.item(i);
        if (elem == null) continue;

        if (elem == rootElement) {
          isFind = true;
          continue;
        }
        if (isFind) {
          let level = parseInt(elem?.getAttribute("data-level") || "-1");
          if (level > elementLevel) {
            elem.classList.add("list-row-drag");
            this.movedRows.push(elem);
          } else {
            break;
          }
        }
      }
    }

    // this.BuildTableRows();

    //console.log(event);
  }
  onDragMoved(event: CdkDragMove) {
    this.movedRows.forEach((elem) => {
      elem.setAttribute(
        "style",
        event.source.getRootElement().getAttribute("style") || ""
      );
    });

    let rowElement = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );
    while (rowElement != null && !rowElement.classList.contains("list-row")) {
      rowElement = rowElement.parentElement;
    }
    if (rowElement == null) {
      this.clearDragInfo();
      return;
    }
    if (rowElement.classList.contains("list-row-drag")) {
      this.clearDragInfo();
      return;
    }
    const targetRect = rowElement.getBoundingClientRect();
    const canDrop = rowElement.getAttribute("data-can-drop") == "true";

    let targetId = parseInt(rowElement.getAttribute("data-id") || "-1");

    this.dropAction = {
      targetId: targetId,
    };

    if (canDrop) {
      const oneThird = targetRect.height / 3;
      if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropAction["action"] = "before";
      } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropAction["action"] = "after";
      } else {
        // inside

        this.dropAction["action"] = "inside";
      }
    } else {
      const oneSecond = targetRect.height / 2;
      if (event.pointerPosition.y - targetRect.top < oneSecond) {
        // before
        this.dropAction["action"] = "before";
      } else {
        // after
        this.dropAction["action"] = "after";
      }
    }
    this.showDragInfo();
  }

  showDragInfo() {
    this.clearDragInfo();
    if (this.dropAction) {
      this.document
        .querySelector(`.list-row[data-id="${this.dropAction?.targetId}"]`)
        // .getElementById(`node-${this.dropActionTodo?.targetId}`)
        ?.classList.add("drop-" + this.dropAction?.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropAction = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  cdkDragEnded(event: CdkDragEnd) {
    // console.log(event);
    const dropAction = this.dropAction;
    const rootElement = event.source.getRootElement();
    const elements = [rootElement, ...this.movedRows];
    this.movedRows = [];
    setTimeout(() => {
      event.source.reset();
      elements.forEach((elem) => {
        elem.classList.add("cdk-drag-animating");
        elem.classList.remove("list-row-drag");
        elem.setAttribute("style", "");
      });
      setTimeout(() => {
        elements.forEach((elem) => {
          elem.classList.remove("cdk-drag-animating");
        });
      }, 300);
    });

    this.clearDragInfo(true);
    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);

    // debugger
    var targetRow = this.tableRows.find((f) => f.id == dropAction?.targetId);
    if (targetRow == null) {
      return;
    }
    let parentId: number | null = null;

    if (dropAction?.action == "inside") {
      event.source.data.parentId = targetRow.id;
      parentId = targetRow.id;
      this.expandedRows[targetRow.id] = true;
    } else {
      event.source.data.parentId = targetRow.parentId;
    }
    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    this.isLoading = true;
    this.kpiTemplateService
      .ChangeParent(event.source.data.id, parentId)
      .subscribe((res) => {
        this.loadData();
      });

    // this.hasChildrenRows = {};
    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    // this.BuildTableRows();

    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);
  }

  dragConstrainPosition(
    userPointerPosition: Point,
    dragRef: DragRef,
    dimensions: DOMRect,
    pickupPositionInElement: Point
  ): Point {
    return userPointerPosition;
  }
}
