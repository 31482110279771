<mat-card>
  <mat-card-title>
    <div style="padding: 1rem;">
      <mat-toolbar class="action-toolbar">
        <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [disabled]="selection.isEmpty()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item i18n (click)="onChangeAccess(false)"
              >Отключить от системы</button
            >
            <button mat-menu-item i18n (click)="onChangeAccess(true)"
              >Подключить к системе</button
            >
          </mat-menu>
        </div>
        <span style="flex: 1 1 auto;" class="example-spacer"></span>

        <form class="search-form" [formGroup]="filterForm">
          <mat-form-field>
            <mat-label i18n>Фильтр</mat-label>
            <input matInput formControlName="searchString" />
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Статус</mat-label>
            <mat-select matInput formControlName="status" multiple>
              <mat-option [value]="true" i18n>Уволен</mat-option>
              <mat-option [value]="false" i18n>Активен</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Доступ</mat-label>
            <mat-select matInput formControlName="access" multiple>
              <mat-option [value]="true" i18n>Да</mat-option>
              <mat-option [value]="false" i18n>Нет</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-slide-toggle formControlName="showAll" labelPosition="before">
            <span i18n style="text-wrap: nowrap;">Показать всех</span>
          </mat-slide-toggle> -->
          <button mat-icon-button type="button" (click)="filterForm.reset()">
            <mat-icon>search_off</mat-icon>
          </button>
        </form>
      </mat-toolbar>
    </div>
  </mat-card-title>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>
    <!-- <div class="align-right">
      <form class="search-form" [formGroup]="filterForm">
        <mat-form-field>
          <mat-label i18n>Фильтр</mat-label>
          <input matInput formControlName="searchString" />
        </mat-form-field>

        <mat-slide-toggle
          formControlName="showAll"
          labelPosition="before"
          style="margin-bottom: 1rem;"
        >
          <span i18n style="text-wrap: nowrap;">Показать всех</span>
        </mat-slide-toggle>
        <button
          mat-icon-button
          type="button"
          style="margin-bottom: 1rem;"
          (click)="filterForm.reset()"
        >
          <mat-icon>search_off</mat-icon>
        </button>
      </form>
    </div> -->

    <table mat-table [dataSource]="tableRows" matSort>
      <!-- <table mat-table [dataSource]="tableRows | archiveFilter:'fired': showAll " matSort> -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex-center">
            <button
              mat-icon-button
              color="primary"
              (click)="onEditMember(null)"
              i18n-matTooltip
              matTooltip="Добавить сотрудника"
            >
              <mat-icon>add_user</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              (click)="onSync()"
              i18n-matTooltip
              matTooltip="Синхронизировать пользователей"
            >
              <mat-icon>sync</mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="onEditMember(element.id)"
            color="primary"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="isBitrixUser"
          ><mat-icon>cloud_queue</mat-icon></th
        >
        <td mat-cell *matCellDef="let element">
          @if(element.isBitrixUser){
          <mat-icon color="primary">cloud</mat-icon>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" i18n
          >Наименование</th
        >
        <td mat-cell *matCellDef="let element">
          <user-widget [user]="element" [short]="false"></user-widget>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="position" i18n
          >Должность</th
        >
        <td mat-cell *matCellDef="let element">
          {{element.position}}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="fired"
          start="desc"
          i18n
          >Статус</th
        >
        <td mat-cell *matCellDef="let element">
          @if (element.fired ) {
          <span i18n>Уволен</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="hasAccess">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="hasAccess"
          start="desc"
          i18n
          >Доступ</th
        >
        <td mat-cell *matCellDef="let element">
          @if(element.hasAccess){
          <mat-icon>done</mat-icon>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="PaginatorPageSizes" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
