import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of, share, shareReplay, tap } from "rxjs";
import { IDictionaryItem } from "../models";
import { IHierarchyDicItem, IThrendDicItemModel } from "../models/IDictionaryItem";

@Injectable({
  providedIn: "root",
})
export class DictionaryService {
  constructor(private http: HttpClient) {}

  private _allPeriods = this.http
    .get<IHierarchyDicItem[]>("/api/dictionary/period")
    .pipe(shareReplay(1));

  public PeriodsWithOutHidden: Observable<
    IHierarchyDicItem[]
  > = this._allPeriods
    .pipe(
      map((res) => {
        let result: IHierarchyDicItem[] = [];

        let fill = (parentId: number | null, level: number) => {
          let rows = res.filter((f) => f.parentId == parentId);
          rows = rows.sort((a, b) => a.orderId - b.orderId);
          for (let row of rows) {
            if (row.isHidden) continue;
            row.level = level;
            result.push(row);
            fill(row.id, level + 1);
          }
        };

        fill(null, 0);
        // console.log(result);
        return result;
      })
    )
    .pipe(shareReplay(1));
  // public Periods:Observable<IDictionaryItem[]> = this.http.get

  public GetDefaultPeriodId(): Observable<number | null> {
    return this._periods.pipe(
      map((m) => {
        let item = m.find((f) => f.isDefault == true);
        if (item == null) return m[0].id;
        return item.id;
      })
    );
  }

  private _periods = this._allPeriods
    .pipe(
      map((res) => {
        let result: IHierarchyDicItem[] = [];

        let fill = (parentId: number | null, level: number) => {
          let rows = res.filter((f) => f.parentId == parentId);
          rows = rows.sort((a, b) => {
            if (a.orderId < b.orderId) return -1;
            if (a.orderId > b.orderId) return 1;
            if (a.code < b.code) return -1;
            if (a.code > b.code) return 1;
            return 0
            });
          for (let row of rows) {
            row.level = level;
            result.push(row);
            fill(row.id, level + 1);
          }
        };

        fill(null, 0);
        // console.log(result);
        return result;
      })
    )
    .pipe(shareReplay(1));

  public get Periods(): Observable<IHierarchyDicItem[]> {
    return this._periods;
    // let s = this._periods;
    // s.subscribe();
    // return s;
  }



  public Units: Observable<IDictionaryItem[]> = this.http
    .get<IDictionaryItem[]>("/api/dictionary/unitMeasure")
    .pipe(shareReplay(1));
  public Thrends: Observable<IThrendDicItemModel[]> = this.http
    .get<IThrendDicItemModel[]>("/api/dictionary/trend")
    .pipe(shareReplay(1));
  public KpiType: Observable<IDictionaryItem[]> = this.http
    .get<IDictionaryItem[]>("/api/dictionary/type")
    .pipe(shareReplay(1));
  public Category: Observable<IDictionaryItem[]> = this.http
    .get<IDictionaryItem[]>("/api/dictionary/category")
    .pipe(shareReplay(1));
  public Statuses: Observable<IDictionaryItem[]> = this.http
    .get<IDictionaryItem[]>("/api/dictionary/status")
    .pipe(shareReplay(1));

  public OrgStructure: Observable<any[]> = this.http
    .get<IDictionaryItem[]>("/api/orgstructure/get")
    .pipe(shareReplay(1));
}
