import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { SelectDepartmentWndComponent } from '../select-department-wnd/select-department-wnd.component';

@Component({
  selector: 'select-department',
  templateUrl: './select-department.component.html',
  styleUrl: './select-department.component.scss',
  providers: [
    [     
      { provide: MatFormFieldControl, useExisting: SelectDepartmentComponent },
    ],
  ],
})
export class SelectDepartmentComponent  implements MatFormFieldControl<any[]>, OnInit,OnDestroy, ControlValueAccessor  {

  static nextId = 0;



  @Input() value: any[] = [];

  @Output() onItemsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  isDisabled: boolean = false;

  onChange(_: any) {}
  onTouch(_: any) {
  
  }

  constructor(public dialog: MatDialog,
    @Optional() @Self() public ngControl: NgControl,
  private _elementRef: ElementRef<HTMLElement>,) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void> ;
  @HostBinding() id = `select-department-form-${SelectDepartmentComponent.nextId++}`;

  @Input() 
  get placeholder() {
  return this._placeholder;
}
set placeholder(plh) {
  this._placeholder = plh;
  this.stateChanges.next();
}
private _placeholder!: string;


focused = false;

onFocusIn(event: FocusEvent) {
  if (!this.focused) {
    this.focused = true;
    this.stateChanges.next();
  }
}

onFocusOut(event: FocusEvent) {
  // if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
  //   this.touched = true;
  //   this.focused = false;
  //   this.onTouched();
  //   this.stateChanges.next();
  // }
}
get empty() {
  return this.value == null || this.value.length == 0;
}

@HostBinding('class.floating')
get shouldLabelFloat() {
  return this.focused || !this.empty;
}

@Input()
get required(): boolean {
  return this._required;
}
set required(value: BooleanInput) {
  this._required = coerceBooleanProperty(value);
  this.stateChanges.next();
}
private _required = false;

@Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    // this._disabled ? this.addressForm.disable() : this.addressForm.enable();
    this.isDisabled = this._disabled;
    this.stateChanges.next();
  }
  private _disabled = false;

 get errorState(): boolean {
    return false
  }

  controlType = 'select-department-form';
  autofilled?: boolean | undefined;

  getContentText(){
    let result = "";
    if (Array.isArray(this.value) && this.value.length > 0){
      result = this.value.map(m => m.name).join(", ");
    }
    return result;
  }
 
  setDescribedByIds(ids: string[]) {
    // const controlElement = this._elementRef.nativeElement.querySelector(
    //   'select-position'
    // )!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent): void {
    // throw new Error("Method not implemented.");
    this.onSelectClick();
  }

  private isOpened :boolean = false;
  public onSelectClick() {
    if (this.isOpened) return;
    this.isOpened = true;
    const dialogRef = this.dialog.open(SelectDepartmentWndComponent, {
      minWidth:"400px",
      minHeight:"640px",
      // width:"400px",
      height:"640px",
      data: this.value,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isOpened = false;
      if (!result) return;
      this.value = result;
      this.onItemsChanged.emit(result);
      this.onChange(result);
      
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.stateChanges.complete();    
  }

  onRemoveClick(item:any){
    if(confirm($localize`Вы действительно хотите удалить '${item.name}'?`) == false) return;
    let index = this.value.indexOf(item);
    if (index > -1 ){
      var result = this.value.slice();
      result.splice(index,1);
      this.value = result;
      this.onItemsChanged.emit(result);
      this.onChange(result);
    }
  }

  writeValue(obj: any[]): void {
    this.value = obj;
   
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.isDisabled = isDisabled;   
  }
}
