import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface IInvoiceInfo{
   id:number;
   isCreated:boolean;
   isPaid:boolean; 
   period:string;
   userLimit:number; 
   title:string;
}

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public GetTariffs(): Observable<any[]> {
    return this.http.get<any[]>(`/api/payment/tariffs`);
  }

  public GetCompaniesInfo(): Observable<any[]> {
    return this.http.get<any[]>(`/api/payment/companies`);
  }

  public CreateInvoice(model:any): Observable<number> {
    return this.http.post<number>(`/api/payment/createinvoice`,model);
  }

  public GetInvoice(invoiceId:number): Observable<IInvoiceInfo> {
    return this.http.get<IInvoiceInfo>(`/api/payment/getinvoice/${invoiceId}`);
  }
  public DownloadInvoice(invoiceId:number):Observable<any> {
    return this.http.get(`/api/payment/invoice/download/${invoiceId}`,
    {  observe: 'response', responseType: 'blob' });
  }
  public GetInvoices(): Observable<IInvoiceInfo[]> {
    return this.http.get<IInvoiceInfo[]>(`/api/payment/getinvoices`);
  }
}
