import {
  CdkDragEnd,
  CdkDragMove,
  CdkDragRelease,
  CdkDragStart,
  DragRef,
  Point,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  IPeriodDicModel,
  SettingsDictionaryService,
} from "src/app/services/settings-dictionary.service";
import { EditPeriodWndComponent } from "../edit-period-wnd/edit-period-wnd.component";

interface ITreeItem {
  id: number;
  level: number;
  data: IPeriodDicModel;
  hasChildren: boolean;
}
interface IDropInfo {
  targetId: number;
  action?: "before" | "after" | "inside";
}
@Component({
  selector: "app-periods",
  templateUrl: "./periods.component.html",
  styleUrl: "./periods.component.scss",
})
export class PeriodsComponent implements OnInit {
  private _allRows: IPeriodDicModel[] = [];
  public dropAction: IDropInfo | null = null;
  private _dragElement: ITreeItem | null = null;
  public showHidden: boolean = false;

  public isLoading: boolean = true;
  public rows: ITreeItem[] = [];
  public expandedRows: Record<number, boolean> = {};
  constructor(
    private settingsDictionaryService: SettingsDictionaryService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private PrepareTree(parentId: number | null, level: number) {
    let rows = this._allRows.filter((f) => f.parentId == parentId);
    rows.sort((a, b) => a.orderId - b.orderId);
    for (let row of rows) {
      if (!this.showHidden) {
        if (row.isHidden) continue;
      }
      // if (level < 2) {
      //   this.expandedRows[row.id] = true;
      // }

      let item: ITreeItem = {
        id: row.id,
        level: level,
        data: row,
        hasChildren: this._allRows.find((f) => f.parentId == row.id) != null,
      };
      this.rows.push(item);
      if (this.expandedRows[row.id]) {
        this.PrepareTree(row.id, level + 1);
      }
    }
  }

  public buildTree() {
    this.rows = [];
    this.PrepareTree(null, 0);
  }
  private LoadData() {
    this.isLoading = true;
    this.settingsDictionaryService.GetPeriods().subscribe((res) => {
      this._allRows = res;
      this.buildTree();
      this.isLoading = false;
    });
  }

  ngOnInit(): void {
    this.LoadData();
  }

  public onAddSub(period: IPeriodDicModel) {
    this.onEdit({
      ...period,
      id: 0,
      name: "",
      code: "",
      parentId: period.id,
    });
  }
  public onEdit(period: IPeriodDicModel | null) {
   // console.log(period);
    const dialogRef = this.dialog.open(EditPeriodWndComponent, {
      minWidth: "400px",
      minHeight: "640px",
      data: period,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result !== "") {
        this.isLoading = true;
        
        if (result.id == 0) {
          result.orderId = 0;
          let nums = this._allRows
            .filter((f) => f.parentId == (period?.parentId || null))
            .map((m) => m.orderId);
          if (nums.length > 0) {
            result.orderId = Math.max(...nums) + 1;
          }
        }
        this.settingsDictionaryService
          .SavePeriods([result])
          .subscribe((res) => {
            this.LoadData();
          });
      }
    });
  }

  toogleRow(row: ITreeItem) {
    this.expandedRows[row.id] = !this.expandedRows[row.id];
    this.buildTree();
  }

  private movedRows: Element[] = [];
  // drag&drop
  onDragStarted(event: CdkDragStart) {
    // console.log(event);
    // if (event.source.element.nativeElement.localName !== 'mat-icon'){
    //   event.source.disabled = true;
    //   return;
    // }
    this._dragElement = event.source.data;
    const rootElement = event.source.getRootElement();
    rootElement.classList.add("list-row-drag");
    const elementLevel = parseInt(
      rootElement.getAttribute("data-level") || "-1"
    );
    let parent = rootElement.parentElement;
    this.movedRows = [];

    if (parent) {
      let isFind = false;
      for (let i = 0; i < parent?.children?.length; i++) {
        let elem = parent.children.item(i);
        if (elem == null) continue;

        if (elem == rootElement) {
          isFind = true;
          continue;
        }
        if (isFind) {
          let level = parseInt(elem?.getAttribute("data-level") || "-1");
          if (level > elementLevel) {
            elem.classList.add("list-row-drag");
            this.movedRows.push(elem);
          } else {
            break;
          }
        }
      }
    }

    // this.BuildTableRows();

   // console.log(event);
  }
  onDragMoved(event: CdkDragMove) {
    this.movedRows.forEach((elem) => {
      elem.setAttribute(
        "style",
        event.source.getRootElement().getAttribute("style") || ""
      );
    });

    let rowElement = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );
    while (rowElement != null && !rowElement.classList.contains("list-row")) {
      rowElement = rowElement.parentElement;
    }
    if (rowElement == null) {
      this.clearDragInfo();
      return;
    }
    if (rowElement.classList.contains("list-row-drag")) {
      this.clearDragInfo();
      return;
    }
    const targetRect = rowElement.getBoundingClientRect();
    const canDrop = true; //rowElement.getAttribute("data-can-drop") == "true";

    let targetId = parseInt(rowElement.getAttribute("data-id") || "-1");

    this.dropAction = {
      targetId: targetId,
    };

    if (canDrop) {
      const oneThird = targetRect.height / 3;
      if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropAction["action"] = "before";
      } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropAction["action"] = "after";
      } else {
        // inside

        this.dropAction["action"] = "inside";
      }
    } else {
      const oneSecond = targetRect.height / 2;
      if (event.pointerPosition.y - targetRect.top < oneSecond) {
        // before
        this.dropAction["action"] = "before";
      } else {
        // after
        this.dropAction["action"] = "after";
      }
    }
    this.showDragInfo();
  }

  showDragInfo() {
    this.clearDragInfo();
    if (this.dropAction) {
      this.document
        .querySelector(`.list-row[data-id="${this.dropAction?.targetId}"]`)
        // .getElementById(`node-${this.dropActionTodo?.targetId}`)
        ?.classList.add("drop-" + this.dropAction?.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropAction = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  cdkDragEnded(event: CdkDragEnd) {
    // console.log(event);
    const dropAction = this.dropAction;
    const rootElement = event.source.getRootElement();
    const elements = [rootElement, ...this.movedRows];
    this.movedRows = [];
    setTimeout(() => {
      event.source.reset();
      elements.forEach((elem) => {
        elem.classList.add("cdk-drag-animating");
        elem.classList.remove("list-row-drag");
        elem.setAttribute("style", "");
      });
      setTimeout(() => {
        elements.forEach((elem) => {
          elem.classList.remove("cdk-drag-animating");
        });
      }, 300);
    });

    this.clearDragInfo(true);
    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);

    //  debugger;
    var targetRow = this._allRows.find((f) => f.id == dropAction?.targetId);
    if (targetRow == null) {
      return;
    }
    let parentId: number | null = null;
    let orderId: number = 0;
    if (dropAction?.action == "inside") {
      // event.source.data.parentId = targetRow.id;
      parentId = targetRow.id;
      orderId =
        Math.max(
          ...this._allRows
            .filter((f) => f.parentId == parentId)
            .map((m) => m.orderId)
        ) + 1;
    //  console.log(orderId);
      this.expandedRows[targetRow.id] = true;
    } else {
      if (dropAction?.action == "before") {
        orderId = targetRow.orderId - 0.5;
      } else {
        orderId = targetRow.orderId + 0.5;
      }
      parentId = targetRow.parentId;
      // event.source.data.parentId = targetRow.parentId;
    }

    event.source.data.data.orderId = orderId;
    event.source.data.data.parentId = parentId;
    let levelRows = this._allRows.filter((f) => f.parentId == parentId);
    levelRows = levelRows.sort((a, b) => a.orderId - b.orderId);
    levelRows.forEach((e, i) => (e.orderId = i));

    this.isLoading = true;
    this.settingsDictionaryService.SavePeriods(levelRows).subscribe((res) => {
      this.LoadData();
    });

    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    // this.isLoading = true;
    // this.kpiService
    //   .ChangeParent(event.source.data.id, parentId)
    //   .subscribe((res) => {
    //     this.loadData();
    //   });

    // this.hasChildrenRows = {};
    // this._allRows.forEach((e) => {
    //   if (e.parentId == null) {
    //   } else {
    //     this.hasChildrenRows[e.parentId] = true;
    //   }
    // });
    // this.BuildTableRows();

    // event.source.
    // console.log(event.source.element.nativeElement.style.transform);
  }

  dragConstrainPosition(
    userPointerPosition: Point,
    dragRef: DragRef,
    dimensions: DOMRect,
    pickupPositionInElement: Point
  ): Point {
    return userPointerPosition;
  }
}
