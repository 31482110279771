@switch (typeId) { @case (1) {
{{getValueByProp(KpiTypeYesNoValues,"name",value)}} } @case (3) {
{{getValueByProp(KpiTypeThreeVariantsValues,"name",value)}} } @case (4) {
<div class="starts">
  <mat-icon
    *ngFor="let s of getValueByProp(KpiTypeFiveVariantsValues,'arr',value)"
    >star</mat-icon
  >
</div>

} @default { {{value | number : '1.2-2'}} } }
