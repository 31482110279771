<mat-card>
  <mat-card-title>
    <div style="padding: 1rem;">
      <form class="search-form" [formGroup]="filterForm">
        <mat-form-field>
          <mat-label i18n>Фильтр</mat-label>
          <input matInput formControlName="searchString" />
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Статус</mat-label>
          <mat-select matInput formControlName="status" multiple>
            <mat-option [value]="true" i18n>В архиве</mat-option>
            <mat-option [value]="false" i18n>Активен</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          type="button"
          style="margin-bottom: 1rem;"
          (click)="filterForm.reset()"
        >
          <mat-icon>search_off</mat-icon>
        </button>
      </form>
    </div>
  </mat-card-title>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>

    <table mat-table [dataSource]="tableRows" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            color="primary"
            (click)="onEditDepartment(null)"
          >
            <mat-icon color="primary">add_circle</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="onEditDepartment(element)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n
          >Наименование</th
        >
        <td mat-cell *matCellDef="let element">
          <div class="flex-center">
            <div>
              <mat-icon color="primary">home</mat-icon>
            </div>
            <div>
              {{element.name}}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="parentName" i18n
          >Головной департамент</th
        >
        <td mat-cell *matCellDef="let element">
          @if(element.parentName != null){
          <div class="flex-center">
            <div>
              <mat-icon color="primary">home</mat-icon>
            </div>
            <div>
              {{element.parentName}}
            </div>
          </div>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="archived"
          start="desc"
          i18n
          >Статус</th
        >
        <td mat-cell *matCellDef="let element">
          @if (element.archived) {
          <span i18n>В архиве</span>
          }
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="PaginatorPageSizes" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
