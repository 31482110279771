import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, Subject, takeUntil } from "rxjs";
import { PaginatorPageSizes } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { MessagesService } from "src/app/services/messages.service";
import { OrgStructureService } from "src/app/services/org-structure.service";

@Component({
  selector: "departments-list",
  templateUrl: "./departments-list.component.html",
  styleUrl: "./departments-list.component.scss",
})
export class DepartmentsListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public PaginatorPageSizes = PaginatorPageSizes;

  destroy$: Subject<boolean> = new Subject<boolean>();
  public displayedColumns = ["name","parent", "status", "actions"];
  public tableRows: MatTableDataSource<any> = new MatTableDataSource();
  public isLoading: boolean = true;

  public filterForm = new FormGroup({
    searchString: new FormControl(null),
    status: new FormControl([]),
  });

  constructor(
    private orgStructureService: OrgStructureService,
    private bitrix24Service: Bitrix24Service,
    private _messagesService: MessagesService
  ) {}

  private LoadData() {
    this.isLoading = true;
    this.orgStructureService.GetDepartmentList().subscribe((res) => {
      res.forEach( r => {
        if (r.parentId != null){
          r.parentName = res.find(f => f.id == r.parentId)?.name;
        }
      } );
      this.tableRows.data = res;
      this.isLoading = false;
    });
  }

  ngAfterViewInit() {
    this.tableRows.sort = this.sort;
    this.tableRows.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.tableRows.filterPredicate = (row: any, filter: string) => {
      // console.log(row,filter);
      let result = true;
      let formData = this.filterForm.getRawValue();
      if (
        formData.searchString !== null &&
        formData.searchString !== undefined
      ) {
        result = row.name.toLowerCase().indexOf(formData.searchString) >= 0;
      }

      if (Array.isArray(formData.status) && formData.status.length > 0) {
        result =
          result && formData.status.find((f) => f == row.archived) != null;
      }
      return result;
    };
    this.tableRows.filter = "{}";
    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((res) => {
      this.tableRows.filter = JSON.stringify(res);
    });

    let me = this;
    this._messagesService.onUpdateDepartment
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        
        this.LoadData();
      });
    this.LoadData();
  }

  onEditDepartment(row: any) {
    this.bitrix24Service
      .editDepartment(row?.id || 0, row?.parentId || null)
      .then((res) => {});
  }
}
