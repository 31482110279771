import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent implements OnChanges {
@Input() public url:string = "";
public bgUrl: any;



constructor(private sanitization: DomSanitizer) {
  
  
}
  ngOnChanges(changes: SimpleChanges): void {
    this.bgUrl = this.sanitization.bypassSecurityTrustStyle('url(\'' + this.url + '\')');
  }
}
