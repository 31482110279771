<h2 mat-dialog-title>{{data}}</h2>
<mat-dialog-content>
  <form [formGroup]="mainForm">
    <mat-form-field>
      <mat-label i18n>Должности</mat-label>
      <select-position formControlName="positions"> </select-position>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onSave('remove')" i18n>Убрать</button>
    <button mat-button (click)="onSave('add')" i18n>Добавить</button>
    <button mat-button (click)="onSave('clear')" i18n>Сбросить</button>
    <button mat-button mat-dialog-close i18n>Отмена</button>
</mat-dialog-actions>
