import { Component, Inject, OnInit } from "@angular/core";
import { PLACE_DATA } from "../../consts";
import IPlaceParamsData from "../../services/bx24/entities/placeParamsData";
import { Bitrix24Service } from "../../services/bitrix24.service";
import { MessagesService } from "src/app/services/messages.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { OrgStructureService } from "src/app/services/org-structure.service";
import { Observable, map } from "rxjs";

@Component({
  selector: "edit-position-page",
  templateUrl: "./edit-position-page.component.html",
  styleUrl: "./edit-position-page.component.scss",
})
export class EditPositionPageComponent implements OnInit {
  public isLoading: boolean = false;

  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    code: new FormControl(null),
    user: new FormControl(null as any | null),
    departmentId: new FormControl(null as number | null, Validators.required),
    approvers: new FormControl([]),
    viewers: new FormControl([]),
    inspectors: new FormControl([]),
    archived: new FormControl(false),
    disableAccess: new FormControl(false),
  });
  private model!: any;

  public departments: Observable<any[]> | null = null;

  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private bx: Bitrix24Service,
    private _messagesService: MessagesService,
    private orgStructureService: OrgStructureService
  ) {
    // console.log(data);
    this.departments = orgStructureService.GetDepartmentList().pipe(
      map((res: any[]) => {
        let result: any[] = [];

        var processTree = (row: any, level: number) => {
          result.push({
            ...row,
            level: level,
          });
          var children = res.filter((f) => f.parentId == row.id);
          for (let item of children) {
            processTree(item, level + 1);
          }
        };

        for (let item of res) {
          if (item.parentId == null) {
            processTree(item, 0);
          }
        }
        // console.log(result);
        return result;
      })
    );
    this.model = {
      id: 0,
      departmentId: data.data.departmentId,
    };
    // console.log(this.model)
    if (!data.data.positionId) {
      if (!!this.model.departmentId) {
        this.mainForm.controls["departmentId"].setValue(
          parseInt(this.model.departmentId)
        );
      }
    }
  }

  private LoadData(id: number, sendObject: boolean) {
    this.isLoading = true;
    this.orgStructureService.GetPosition(id).subscribe((res) => {
      this.model = res;
      for (const field in this.mainForm.controls) {
        const control = this.mainForm.get(field); // 'control' is a FormControl
        control?.setValue(res[field] || null);
      }
      if (sendObject) {
        this._messagesService.UpdatePosition(res);
      }
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    if (this.data?.data?.positionId > 0) {
      this.LoadData(this.data.data.positionId, false);
    }
  }

  onBtnSave() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      const value = this.mainForm.getRawValue();
      let model = {
        ...this.model,
        code: value.code,
        name: value.name,
        user: value.user,
        approvers: value.approvers,
        inspectors: value.inspectors,
        viewers:value.viewers,
        archived: value.archived === true,
        departmentId: value.departmentId,
        disableAccess:value.disableAccess === true
      };
      this.isLoading = true;
      this.orgStructureService.SavePosition(model, true).subscribe((res) => {
        this._messagesService.UpdateEmployee(null);
        this.isLoading = false;
        this.LoadData(res, true);
      },error =>{
        //CodeExistsException
        if (error.error?.error == "CodeExistsException"){
          alert(`Департамент с таким кодом уже существует`);
        } else {
          alert(`Во время выполнения произошлв ошибка`);
        }
        this.isLoading = false;
        

      });
    }

    // filter(): void {
    //   const filterValue = this.input.nativeElement.value.toLowerCase();
    //   this.filteredOptions = this.options.filter(o => o.toLowerCase().includes(filterValue));
    // }
  }
}
