import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import moment from "moment";
import { IPeriodDicModel } from "src/app/services/settings-dictionary.service";

@Component({
  selector: "app-edit-period-wnd",
  templateUrl: "./edit-period-wnd.component.html",
  styleUrl: "./edit-period-wnd.component.scss",
})
export class EditPeriodWndComponent {
  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    code: new FormControl("", Validators.required),
    from: new FormControl(null, Validators.required),
    to: new FormControl(null, Validators.required),
    isDefault: new FormControl(false, Validators.required),
    isHidden: new FormControl(false, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPeriodDicModel | null,
    public dialogRef: MatDialogRef<EditPeriodWndComponent>
  ) {
    if (data != null) {
     // console.log(data);
      for (const field in this.mainForm.controls) {
        const control = this.mainForm.get(field); // 'control' is a FormControl
        control?.setValue((<any>data)[field]);
      }
    } else {
    }
  }

  onSave() {
    // console.log(this.mainForm.getRawValue());
    this.mainForm.markAllAsTouched();

    if (this.mainForm.valid) {
      let fm = this.mainForm.getRawValue();
      let model: IPeriodDicModel = {
        ...(this.data == null ? { id: 0, parentId: null, orderId: 0 } : this.data),
        name: fm.name || "",
        code: fm.code,
        isDefault: fm.isDefault== true,
        isHidden: fm.isHidden == true,
        // parentId:this.data?.parentId || null,
        from: moment(fm.from).format("YYYY-MM-DD"),
        to: moment(fm.to).format("YYYY-MM-DD"),
      };      
      this.dialogRef.close(model);
    }
  }
}
