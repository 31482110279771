<div class="wrapper" [class.disabled]="isDisabled">
  <div class="content">
    <mat-chip-set>
      @for (item of value; track item) {
      <mat-chip (removed)="onRemoveClick(item)">
        <div class="name-wrapper">
          <div *ngIf="!!item?.user" class="photo">
            <avatar [url]="item?.user?.photo" > </avatar>
          </div>
          <div>
            <div>
              {{item.name}}
            </div>
            <div>{{item.user?.name}}</div>
          </div>
        </div>

        <button matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>      
      }    
    </mat-chip-set>

    <!-- @for (item of value; track item) {
    <div class="selected-item">
      {{item.name}}
      
      <div class="close-btn" (click)="onRemoveClick(item)">
          <mat-icon>clear</mat-icon>
      </div>
      
    </div>
    } -->
  </div>
  <div class="caret">
    <button
      mat-icon-button
      [disableRipple]="true"
      (click)="onSelectClick()"
      [disabled]="isDisabled"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</div>
