import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IKpi, IKpiListItem } from "../models/index";
import moment from "moment";
import { PrepareModelData } from "../helpers/helper";

export interface IUserPositionKpiList{
  id:number;
  name:string;
  rows: IKpiListItem[];
  total: any;
  totalComplite: any;
}
export interface IListInfo {
  user: any;
  positions:IUserPositionKpiList[];
  total: any;
  totalComplite: any;
}

@Injectable({
  providedIn: "root",
})
export class KpiService {
  constructor(private http: HttpClient) {}

  public GetKpiList(searchModel: any): Observable<IListInfo> {
    return this.http.get<IListInfo>("/api/kpi/list", {
      params: PrepareModelData(searchModel),
    });
  }
  public GetUserKpiList(
    positionId: number,
    searchModel: any
  ): Observable<IListInfo> {
    return this.http.get<IListInfo>(`/api/kpi/list/${positionId}`, {
      params: PrepareModelData(searchModel),
    });
  }

  public GetKpi(id: number): Observable<IKpi> {
    return this.http.get<IKpi>(`/api/kpi/get/${id}`);
  }

  public SaveKpi(model: IKpi, process: boolean): Observable<number> {
    return this.http.post<number>(`/api/kpi/save/${process}`, model);
  }
  public CopyKpi(kpiId: Number): Observable<number> {
    return this.http.post<number>(`/api/kpi/copy/${kpiId}`, {});
  }
  public ChangeStatus(
    kpiId: number,
    statusId: number,
    comment: string | null,
    isController: boolean
  ): Observable<any> {
    if (isController) {
      return this.http.post(`/api/kpi/changestatuscontroller/${kpiId}`, {
        statusId: statusId,
        comment: comment,
      });
    } else {
      return this.http.post(`/api/kpi/changestatus/${kpiId}`, {
        statusId: statusId,
        comment: comment,
      });
    }
  }

  public ChangeParent(kpiId: number, parentId: number | null): Observable<any> {
    return this.http.post(`/api/kpi/changeparent/${kpiId}`, parentId);
  }

  public AddFromTemplates(
    positionIds: number[],
    periodIds: number[],
    ids: number[]
  ): Observable<number> {
    return this.http.post<number>(`/api/kpi/addfromtemplate`, {
      positionIds,
      periodIds,
      ids,
    });
  }
  public BulkCreationKpis(
    model:any
  ): Observable<number> {
    return this.http.post<number>(`/api/kpi/bulkcreationkpis`,model);
  }
}
