import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { forkJoin, map, tap } from "rxjs";
import { PLACE_DATA } from "src/app/consts";
import IPlaceParamsData from "src/app/services/bx24/entities/placeParamsData";
import { IInvoiceInfo, PaymentService } from "src/app/services/payment.service";

@Component({
  selector: "payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrl: "./payment-page.component.scss",
})
export class PaymentPageComponent implements OnInit {
  public isLoading: boolean = true;  
  public isCreatingInvoice: boolean = false;
  public isInvoiceCreated: boolean = false;
  private _period!: string;
  private _userLimit!: number;

  public invoiceModel!:IInvoiceInfo;

  public periodName!: string;
  public price!: any;

  public paymentInfo: any[] = [];
  public selectedPaymentInfo: any;

  public mainForm = new FormGroup({
    companyName: new FormControl(null as string | null, Validators.required),
    companyFullName: new FormControl(
      null as string | null,
      Validators.required
    ),
    director: new FormControl(null as string | null), //new FormControl(null as string | null, Validators.required),
    inn: new FormControl(null as string | null, Validators.required),
    kpp: new FormControl(null as string | null, Validators.required),
    ogrn: new FormControl(null as string | null, Validators.required),
    address: new FormControl(null as string | null, Validators.required),
    regAddress: new FormControl(null as string | null, Validators.required),
    phone: new FormControl(null as string | null, Validators.required),
  });
  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private paymentService: PaymentService
  ) {
    this._period = data.data.period;
    this._userLimit = parseInt(data.data.userLimit);
  }

  private async LoadData() {
    this.isLoading = true;
    var result = await Promise.all([
      this.paymentService.GetTariffs().toPromise(),
      this.paymentService.GetCompaniesInfo().toPromise(),
    ]);
    // console.log(result);

    var period = (result[0] || []).find((f) => f.code == this._period);
    this.periodName = period.name;
    this.price = period.tariffPrices.find(
      (f: any) => f.userLimit == this._userLimit
    );
    this.paymentInfo = result[1] || [];

    if (this.paymentInfo.length == 0) {
      this.paymentInfo.push({
        companyName: null,
        companyFullName: null,
        director: null,
        inn: null,
        kpp: null,
        ogrn: null,
        address: null,
        regAddress: null,
        phone: null,
      });
    }
    this.selectedPaymentInfo = this.paymentInfo[0];
    this.setPaymentInfo();
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.LoadData();
    // this.isLoading = false;
    // this.isInvoiceCreated =true;
    // this.isCreatingInvoice = false;
  }

  setPaymentInfo() {
    this.mainForm.setValue(this.selectedPaymentInfo);
  }

  onDownloadClick(invoice: IInvoiceInfo) {
    this.isLoading = true;
    this.paymentService.DownloadInvoice(invoice.id).subscribe((res) => {
      // debugger
      let data = res.body as Blob;
      let a = document.createElement("a");
      a.download = invoice.title + ".pdf";
      a.href = window.URL.createObjectURL(data);
      a.click(); 
      this.isLoading = false;
      setTimeout(() => {
        a.remove();
      },30000);
    });
  }

  CheckInvoice(invoiceId: number) {
    if (invoiceId == 0) return;
    this.isCreatingInvoice = true;
    this.paymentService.GetInvoice(invoiceId).subscribe((res) => {
      if (res.isCreated) {
        this.invoiceModel = res;
        this.isCreatingInvoice = false;
      } else {
        setTimeout(() => {
          this.CheckInvoice(invoiceId);
        }, 5000);
      }
    });
  }

  async onCreateCheck() {
    this.mainForm.markAllAsTouched();

    if (this.mainForm.valid) {
      this.isLoading = true;
      let invoiceId = await this.paymentService
        .CreateInvoice({
          period: this._period,
          userLimit: this._userLimit,
          companyInfo: this.mainForm.getRawValue(),
        })
        .toPromise();
      this.CheckInvoice(invoiceId || 0);
      this.isInvoiceCreated = true;
      this.isLoading = false;
    }
  }
}
