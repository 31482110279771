<loader [isLoading]="isLoading"></loader>
<!-- <loader [isLoading]="true"></loader> -->
<mat-grid-list cols="3" rowHeight="fit">
  <mat-grid-tile class="pr-5">
    <mat-card>
      <mat-card-content >
        <ng-template
          #rowsTemplate
          let-rows
          let-level="level"
          let-listId="listId"
        >
          <div
            class="table-level"
            cdkDropList
            data-type="dep,pos"
            [attr.data-id]="listId"
            [cdkDropListData]="rows"
            (cdkDropListDropped)="drop($event, rows)"
          >
            @for (row of rows; track row.id) {
            <div
              class="table-row-wrapper"
              cdkDrag
              data-type="dep"
              [cdkDragData]="row"
              (cdkDragMoved)="dragMoved($event)"
            >
              <div
                class="table-row pointer"
                data-type="dep"
                [attr.data-id]="row.id"
                [class.selected]="selectedDepartment == row"
                (click)="onSelectDepartment(row); $event.stopPropagation()"
              >
                <div class="table-cell table-drug-column" cdkDragHandle>
                  <mat-icon color="primary">drag_indicator</mat-icon>
                </div>
                <div class="table-cell" [style.padding-left.px]="level*20">
                  <div class="tree-column" [id]="'node-'+row.id">
                    @if (!!row.children && row.children.length > 0) {

                    <button
                      mat-icon-button
                      (click)="toogleRow(row); $event.stopPropagation() "
                    >
                      <mat-icon class="mat-icon-rtl-mirror"  color="primary">
                        {{expandedRows[row.id] ? 'expand_more' :
                        'chevron_right'}}
                      </mat-icon>
                    </button>
                    } @else {
                    <button mat-icon-button disabled></button>
                    }
                    <div class="flext-centers">
                      <!-- <mat-icon>home</mat-icon> -->
                      <span>{{row.name}}</span>
                    </div>
                  </div>
                </div>
                <div class="table-cell table-column-50">
                  <button
                    mat-icon-button
                    (click)="onEditDepartment(row);$event.stopPropagation() "
                  >
                    <mat-icon  color="primary">edit</mat-icon>
                  </button>
                </div>
              </div>

              @if (expandedRows[row.id] && !!row.children && row.children.length
              > 0) {
              <ng-container
                *ngTemplateOutlet="rowsTemplate; context: {$implicit: row.children, level:level+1, listId:row.id}"
              >
              </ng-container>
              }
            </div>
            }
          </div>
        </ng-template>

        <div class="table">
          <div class="table-row table-header-row">
            <div class="table-cell table-drug-column"></div>
            <div class="table-cell" i18n>Орг. структура</div>
            <div class="table-cell table-column-50">
              <button
                mat-icon-button
                color="primary"
                (click)="onAddDepartment()"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>

          <ng-container
            *ngTemplateOutlet="rowsTemplate; context: {$implicit: data, level:0, listId:0}"
          >
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile class="pl-5" colspan="2">
    <mat-card>
      <mat-card-content >

        <ng-template
          #positionsTemplate
          let-rows
          let-level="level"
          let-listId="listId"
        >
          <div
            class="table-level"
            cdkDropList
            data-type="pos"
            [attr.data-id]="listId"
            [cdkDropListData]="rows"
            (cdkDropListDropped)="drop($event, rows)"
            [cdkDropListEnterPredicate]="checkDropListDisabled"
          >
            @for (row of rows; track row.id) {
            <div
              class="table-row-wrapper"
              cdkDrag
              data-type="pos"
              [cdkDragData]="row"
              (cdkDragMoved)="dragMoved($event)"
              (cdkDragReleased)="dragRelease($event)"
            >
              <div class="table-row" data-type="pos" [attr.data-id]="row.id">
                <div class="table-cell table-drug-column" cdkDragHandle>
                  <mat-icon  color="primary">drag_indicator</mat-icon>
                </div>
                <div class="table-cell" [style.padding-left.px]="level*20">
                  <div class="tree-column" [id]="'node-'+row.id">
                    @if (!!row.children && row.children.length > 0) {
                    <button mat-icon-button (click)="tooglePositionRow(row)">
                      <mat-icon class="mat-icon-rtl-mirror"  color="primary">
                        {{expandedPositions[row.id] ? 'expand_more' :
                        'chevron_right'}}
                      </mat-icon>
                    </button>
                    } @else {
                    <button mat-icon-button disabled></button>
                    }

                    <mat-icon  color="primary">persone</mat-icon>
                    <a
                      class="pointer"
                      (click)="onPositionClick(row); $event.stopPropagation()"
                      >{{row.name}}</a
                    >
                  </div>
                </div>
                <div class="table-cell table-column-deps">
                  <div class="chip-wrapper">
                    <mat-chip-set>
                      @if (!!row.user){
                        <user-widget [user]="row.user" [short]="false"></user-widget> 
                      <!-- <mat-chip>
                        <div class="name-wrapper">
                          <div class="photo">
                            <avatar [url]="row.user?.photo"> </avatar>
                          </div>
                          <div>
                            <div>
                              {{row.user?.name}}
                            </div>
                          </div>
                        </div>
                      </mat-chip> -->
                      }
                    </mat-chip-set>
                  </div>
                </div>
                <div class="table-cell table-column-deps">
                  <div class="chip-wrapper">
                    <mat-chip-set>
                      @if (row.approvers.length > 0){
                        <user-widget [user]="row.approvers[0]?.user" [short]="true"
                        [position]="row.approvers[0].name"
                        ></user-widget> 
                      <!-- <mat-chip>
                        <div class="name-wrapper">
                          <div *ngIf="!!row.approvers[0]?.user" class="photo">
                            <avatar [url]="row.approvers[0]?.user?.photo">
                            </avatar>
                          </div>
                          <div>
                            <div>
                              {{row.approvers[0].name}}
                            </div>
                            <div>{{row.approvers[0].user?.name}}</div>
                          </div>
                        </div>
                      </mat-chip> -->
                      } @if (row.approvers.length > 1){
                      <mat-chip class="addition-chip">
                        <div class="additional-items">
                          +{{row.approvers.length-1}}
                        </div>
                      </mat-chip>
                      }
                    </mat-chip-set>
                  </div>
                </div>
                <div class="table-cell table-column-deps">
                  <div class="chip-wrapper">
                    <mat-chip-set>
                      @if (row.inspectors.length > 0){
                        <user-widget [user]="row.inspectors[0]?.user" [short]="true"
                        [position]="row.inspectors[0].name"
                        ></user-widget>   
                      <!-- <mat-chip>
                        <div class="name-wrapper">
                          <div *ngIf="!!row.inspectors[0]?.user" class="photo">
                            <avatar [url]="row.inspectors[0]?.user?.photo">
                            </avatar>
                          </div>
                          <div>
                            <div>
                              {{row.inspectors[0].name}}
                            </div>
                            <div>{{row.inspectors[0].user?.name}}</div>
                          </div>
                        </div>
                      </mat-chip> -->
                      } @if (row.inspectors.length > 1){
                      <mat-chip class="addition-chip">
                        <div class="additional-items">
                          +{{row.inspectors.length-1}}
                        </div>
                      </mat-chip>
                      }
                    </mat-chip-set>
                  </div>
                </div>
                <div class="table-cell table-column-deps">
                  <div class="chip-wrapper">
                    
                    <mat-chip-set>
                      @if (row.viewers.length > 0){
                        
                        <user-widget [user]="row.viewers[0]?.user" [short]="true"
                        [position]="row.viewers[0].name"
                        ></user-widget>   
                      <!-- <mat-chip>
                        <div class="name-wrapper">
                          <div *ngIf="!!row.inspectors[0]?.user" class="photo">
                            <avatar [url]="row.inspectors[0]?.user?.photo">
                            </avatar>
                          </div>
                          <div>
                            <div>
                              {{row.inspectors[0].name}}
                            </div>
                            <div>{{row.inspectors[0].user?.name}}</div>
                          </div>
                        </div>
                      </mat-chip> -->
                      } @if (row.viewers.length > 1){
                      <mat-chip class="addition-chip">
                        <div class="additional-items">
                          +{{row.viewers.length-1}}
                        </div>
                      </mat-chip>
                      }
                    </mat-chip-set>
                  </div>
                </div>
                <div class="table-cell table-column-40">
                  <button mat-icon-button disabled="">
                    
                  </button>
                </div>
              </div>

              @if (expandedPositions[row.id] && !!row.children &&
              row.children.length > 0) {
              <ng-container
                *ngTemplateOutlet="positionsTemplate; context: {$implicit: row.children, level:level+1, listId:row.id}"
              >
              </ng-container>
              }
            </div>
            }
          </div>
        </ng-template>

        <div class="table">
          <div class="table-row table-header-row">
            <div class="table-cell table-drug-column"></div>
            <div class="table-cell" i18n>Должность</div>
            <div class="table-cell table-column-deps" i18n>Сотрудник</div>
            <div class="table-cell table-column-deps" i18n>Согласующие</div>
            <div class="table-cell table-column-deps" i18n>Контролёры</div>
            <div class="table-cell table-column-deps" i18n>Наблюдатели</div>
            <div class="table-cell table-column-40">
              <button mat-icon-button color="primary" (click)="onAddPosition()">
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>
          @if(selectedDepartment == null){
            <div class="table-row table-header-row">
              <div class="table-cell"></div>
              <div class="table-cell text-center" i18n>Выберите элемен орг. структуры</div>
              <div class="table-cell"></div>
            </div>
          }
          <ng-container
            *ngTemplateOutlet="positionsTemplate; context: {$implicit: selectedDepartment?.positions, level:0, listId:0}"
          >
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

