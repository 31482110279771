import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from "@angular/material/tree";
import {
  KpiTypeYesNoValues,
  KpiTypeFiveVariantsValues,
  KpiTypeThreeVariantsValues,
} from "src/app/consts";
import { DictionaryService } from "src/app/services/dictionary.service";
import { IFunctionParametrModel } from "src/app/services/functions.service";
import {
  IKpiTemplateModel,
  KpiTemplateService,
} from "src/app/services/kpi-template.service";
import { KpiService } from "src/app/services/kpi.service";
import { MessagesService } from "src/app/services/messages.service";

interface ITreeNode {
  id: number;
  name: string;
  user?: any;
  children?: [];
}

interface IFlatNode {
  id: number;
  expandable: boolean;
  name: string;
  level: number;
}

interface IKpiInfo {
  id: number;
  parentId: number | null;
  level: number;
  isGroup: boolean;
  name: string;
  code: string | null;
  plan: number | null;
  weight: number;
  unit: any;
  typeId: Number;
  approved: boolean;
  data: any[];
}

@Component({
  selector: "app-add-kpis-wizard",
  templateUrl: "./add-kpis-wizard.component.html",
  styleUrl: "./add-kpis-wizard.component.scss",
})
export class AddKpisWizardComponent implements OnInit {
  public KpiTypeYesNoValues = KpiTypeYesNoValues;
  public KpiTypeFiveVariantsValues = KpiTypeFiveVariantsValues;
  public KpiTypeThreeVariantsValues = KpiTypeThreeVariantsValues;

  public isLoading: boolean = false;
  public periodForm = new FormGroup({
    periodId: new FormControl(null as number[] | null, Validators.required),
  });
  private _allRows: IKpiTemplateModel[] = [];
  public selectedItems: number[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialogRef: MatDialogRef<AddKpisWizardComponent>,
    public dictionaries: DictionaryService,
    private kpiTemplateService: KpiTemplateService,
    private _messagesService: MessagesService,
    private _kpiService: KpiService
  ) {
    // console.log(data);
  }
  public isChecked(node: any) {
    return this.selectedItems.findIndex((f) => f == node.id) >= 0;
  }

  private processChildren(parentId: number, add: boolean) {
    let rows = this._allRows.filter((f) => f.parentId == parentId);
    for (let row of rows) {
      let index = this.selectedItems.findIndex((f) => f == row.id);
      if (index == -1) {
        if (add) {
          this.selectedItems.push(row.id);
        }
      } else {
        if (!add) {
          this.selectedItems.splice(index, 1);
        }
      }
      this.processChildren(row.id, add);
    }
  }

  public toogleItem(node: any) {
    let index = this.selectedItems.findIndex((f) => f == node.id);
    if (index == -1) {
      this.selectedItems.push(node.id);
    } else {
      this.selectedItems.splice(index, 1);
    }
    this.processChildren(node.id, index == -1);
  }

  private _transformer = (node: ITreeNode, level: number) => {
    return {
      id: node.id,
      expandable: this._allRows.find((f) => f.parentId == node.id) != null,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => {
      let result: any[] = this._allRows.filter((f) => f.parentId === node.id);
      return result;
    }
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IFlatNode) => node.expandable;

  ngOnInit(): void {
    this.isLoading = true;
    this.kpiTemplateService.GetList().subscribe((res) => {
      this._allRows = res;
      var root = res.filter((f) => f.parentId == null);
      this.dataSource.data = root;
      setTimeout(() => {
        this.treeControl.dataNodes.forEach((n) => {
          if (n.level == 0) this.treeControl.expand(n);
        });
      });
      this.isLoading = false;
    });
  }
  public kpisInfo: IKpiInfo[] = [];
  private _allKpisInfo: IKpiInfo[] = [];

  public hasChildrenRows: Record<number, boolean> = {};
  public expandedRows: Record<number, boolean> = {};
  toogleRow(element: any) {
    this.expandedRows[element.id] = !this.expandedRows[element.id];
    this.buildKpis();
  }
  private addKpiRow(row: IKpiInfo, level: number) {
    row.level = level;
    this.kpisInfo.push(row);
    let children = this._allKpisInfo.filter((f) => f.parentId == row.id);
    this.hasChildrenRows[row.id] = children.length > 0;
    if (!this.expandedRows[row.id]) return;

    for (const element of children) {
      this.addKpiRow(element, level + 1);
    }
  }

  private buildKpis() {
    this.kpisInfo = [];

    let rootRows = this._allKpisInfo.filter(
      (f) => this._allKpisInfo.find((r) => r.id == f.parentId) == null
    );
    for (const row of rootRows) {
      this.addKpiRow(row, 0);
    }
  }

  onStepperChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 0) {
      this.kpisInfo = [];
    }
    if (event.selectedIndex == 1) {
      this.hasChildrenRows = {};
      this.expandedRows = {};
      let rows = this._allRows.filter(
        (f) => this.selectedItems.find((i) => i == f.id) != null
      );
      rows.sort((a: any, b: any) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
      });
      this._allKpisInfo = [];

      rows.forEach((row) => {
        this.expandedRows[row.id] = true;
        let data: any[] = [];
        if (Array.isArray((<any>row.trend).parametrs)) {
          var fields: IFunctionParametrModel[] = (<any>row.trend).parametrs;
          for (const field of fields) {
            var fControl: FormControl;
            let value = (<any>row).functionParametrs[field.name];
            if (field.required) {
              fControl = new FormControl(value, Validators.required);
            } else {
              fControl = new FormControl(value);
            }
            let formInfo = {
              info: field,
              form: new FormGroup({
                value: fControl,
              }),
            };
            data.push(formInfo);
          }
        }
        this._allKpisInfo.push({
          name: row.name,
          code: row.code,
          data: data,
          approved : true,
          id: row.id,
          isGroup: row.isGroup,
          level: 0,
          parentId: row.parentId,
          plan: row.plan,
          weight: row.weight,
          unit: row.unit,
          typeId: row.type.id,
        });
      });
      this.buildKpis();
    }
    if (event.selectedIndex == 2) {
      this.CreateKpis();
    }
  }
  CreateKpis() {
    let saveModel = {
      periods: this.periodForm.controls["periodId"].value,
      positions: this.data.map((m) => m.id),
      kpis: this._allKpisInfo.map((m) => {
        let data: any = {};
        for (const item of m.data) {
          data[item.info.name] = item.form.controls["value"].value;
        }
        return {
          id: m.id,
          plan: m.plan,
          weight: m.weight,
          approved: m.approved,
          data: data,
        };
      }),
    }; 
    this.isLoading = true;
    this._kpiService.BulkCreationKpis(saveModel).subscribe((res) => {
      this.isLoading = false;
    });
  }
}
