import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'archiveFilter'
})
export class ArchiveFilterPipe implements PipeTransform {

  transform(value: any[], field:string, showAll:boolean): any[] {
    if (value == null) return [];
    if (showAll) return value;
    return value.filter( f => f[field] == false)
  }

}
