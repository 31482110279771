import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDictionaryItem } from '../models';
import { Observable } from 'rxjs';
import { PrepareModelData } from '../helpers/helper';

export interface IKpiTemplateEditModel {
  id: number;
  name: string;
  description: string | null;
  trendId: number;
  typeId: number;
  unitId: number;
  code: string | null;
  isGroup: boolean;
  parentId: number | null;
  plan: number | null;
  weight: number;
  functionParametrs:Record<string,any>;
}

export interface IKpiTemplateModel {
  id: number;
  name: string;
  description: string;
  trend: IDictionaryItem;
  type: IDictionaryItem;
  unit: IDictionaryItem;
  code: string | null;
  isGroup: boolean;
  parentId: number | null;
  plan: number | null;
  weight: number;
  level?:number;
}

@Injectable({
  providedIn: 'root'
})
export class KpiTemplateService {

  constructor(private http: HttpClient) { }


  public GetList(): Observable<IKpiTemplateModel[]> {
    return this.http.get<IKpiTemplateModel[]>("/api/kpitemplate/list");
  }

  public Get(id: number): Observable<IKpiTemplateEditModel> {
    return this.http.get<IKpiTemplateEditModel>(`/api/kpitemplate/get/${id}`);
  }

  public SaveKpi(model: IKpiTemplateEditModel): Observable<number> {
    return this.http.post<number>(`/api/kpitemplate/save`, model);
  }
  public RemoveKpi(id:number): Observable<number> {
    return this.http.post<number>(`/api/kpitemplate/remove/${id}`, {});
  }

  public ChangeParent(kpiId: number, parentId: number | null): Observable<any> {
    return this.http.post(`/api/kpitemplate/changeparent/${kpiId}`, parentId);
  }

  public Copy(kpiId: number): Observable<number> {
    return this.http.post<number>(`/api/kpitemplate/copy`, kpiId);
  }
}
