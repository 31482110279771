<!-- <h2 mat-dialog-title i18n>Установка Kpi</h2> -->
<loader [isLoading]="isLoading"></loader>
<mat-dialog-content class="top-padding-0">
  <mat-stepper
    #stepper
    [linear]="true"
    (selectionChange)="onStepperChange($event)"
  >
    <mat-step [stepControl]="periodForm">
      <ng-template matStepLabel i18n>Выбор периода и целей</ng-template>
      <form [formGroup]="periodForm">
        <mat-form-field class="w100p">
          <mat-label i18n>Период</mat-label>
          <mat-select matInput formControlName="periodId" [multiple]="true">
            <mat-option
              *ngFor="let item of dictionaries.PeriodsWithOutHidden | async"
              [value]="item.id"
            >
              <div [style.padding-left.px]="24*item.level">
                {{ item.name }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>

          <mat-checkbox [checked]="isChecked(node)" (change)="toogleItem(node)"
            ><div class="name-wrapper">
              <mat-icon color="primary">
                @if (node.isGroup) { ads_click } @else { checklist }</mat-icon
              >
              <div>
                <div>{{node.name}}</div>
              </div>
            </div>
          </mat-checkbox>
        </mat-tree-node>

        <mat-tree-node
          *matTreeNodeDef="let node;when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>

          <mat-checkbox [checked]="isChecked(node)" (change)="toogleItem(node)"
            ><div class="name-wrapper">
              <mat-icon color="primary">
                @if (node.isGroup) { ads_click } @else { checklist }</mat-icon
              >

              <div>
                <div>{{node.name}}</div>
              </div>
            </div>
          </mat-checkbox>
        </mat-tree-node>
      </mat-tree>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n>Редактирование значений</ng-template>
      <div>
        <table mat-table [dataSource]="kpisInfo">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef i18n>Цель/Показатель</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex-center clickable">
                @if(!!hasChildrenRows[element.id]) {
                <button
                  mat-icon-button
                  (click)="toogleRow(element); $event.stopPropagation() "
                >
                  <mat-icon class="mat-icon-rtl-mirror" color="primary">
                    {{expandedRows[element.id] ? 'expand_more' :
                    'chevron_right'}}
                  </mat-icon>
                </button>
                } @else {
                <button mat-icon-button disabled></button>
                }
                <mat-icon color="primary" style="flex: 0 0 auto;">
                  @if (element.isGroup) { ads_click } @else { checklist }
                </mat-icon>
                <span>{{element.name}}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef i18n>Код</th>
            <td mat-cell *matCellDef="let element">
              {{element.code}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="approved">
            <th mat-header-cell *matHeaderCellDef i18n>Согласовано</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [(ngModel)]="element.approved"> </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef i18n>Ед. Изм.</th>
            <td mat-cell *matCellDef="let element">{{element.unit.name}}</td>
          </ng-container>
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef i18n>Вес</th>
            <td mat-cell *matCellDef="let element" class="p05">
              <!-- {{element.weight | number : '1.0-0'}} -->
              <mat-form-field class="inner-field">
                <input
                  matInput
                  type="number"
                  [min]="0"
                  step="1"
                  pattern="\d*"
                  [(ngModel)]="element.weight"
                />
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef i18n>План</th>
            <td mat-cell *matCellDef="let element" class="p05">
              @switch (element.typeId) { @case (1) {
              <mat-form-field class="inner-field">
                <mat-select matInput [(ngModel)]="element.plan">
                  <mat-option
                    *ngFor="let item of KpiTypeYesNoValues"
                    [value]="item.value"
                    >{{ item.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              } @case (2) {
              <mat-form-field class="inner-field">
                <input
                  matInput
                  type="number"
                  [(ngModel)]="element.plan"
                  type="number"
                />
              </mat-form-field>

              } @case (3) { } @case (4) { } @default { } }
            </td>
          </ng-container>
          <ng-container matColumnDef="fields">
            <th mat-header-cell *matHeaderCellDef i18n>Дополнительные поля</th>
            <td mat-cell *matCellDef="let element">
              <div class="function-fields-conteiner">
                @for (formInfo of element.data; track $index) {
                <form [formGroup]="formInfo.form">
                  <mat-form-field class="inner-field">
                    <mat-label>{{formInfo.info.displayName}}</mat-label>
                    @switch (formInfo.info.valueType) { @case ("number") {
                    <input matInput formControlName="value" type="number" />
                    } @case ("text") {
                    <input matInput formControlName="value" />
                    } }
                  </mat-form-field>
                </form>
                }
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="['name','code','approved','unit','weight','plan','fields']"
          ></tr>
          <tr
            class="list-row"
            mat-row
            *matRowDef="let row; columns: ['name','code','approved','unit','weight','plan','fields']"
          ></tr>
        </table>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n>Сохранение</ng-template>
      <p>Показатели созданы</p>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (stepper.selectedIndex < 2) {
  <button mat-button i18n mat-dialog-close>Отмена</button>
  <button mat-button i18n (click)="stepper.previous()">Назад</button>
  <button mat-button i18n (click)="stepper.next()">Далее</button>
  } @else {
  <button mat-button i18n mat-dialog-close>Закрыть</button>
  }
</mat-dialog-actions>
