import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { EditFunctionParametrWndComponent } from "src/app/components/edit-function-parametr-wnd/edit-function-parametr-wnd.component";
import {
  FunctionsService,
  ICalcFunctionModel,
  IFunctionParametrModel,
  IListItem,
} from "src/app/services/functions.service";

@Component({
  selector: "app-functions-page",
  templateUrl: "./functions-page.component.html",
  styleUrl: "./functions-page.component.scss",
})
export class FunctionsPageComponent implements OnInit {
  public isLoading: boolean = true;
  public execCode: string = "";
  public planCode: string = "";
  public factCode: string = "";
  public selectedId: number | null = null;
  public isReadOnly: boolean = true;
  public listItems: IListItem[] = [];
  public errorMessage: string | null = null;
  public currentModel: ICalcFunctionModel | null = null;

  public functionParametrs: IFunctionParametrModel[] = [];

  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    description: new FormControl(""),
  });
  constructor(
    private functionsService: FunctionsService,
    public dialog: MatDialog
  ) {}

  private LoadFunctions() {
    this.isLoading = true;
    this.functionsService.GetList().subscribe((res) => {
      this.listItems = res;
      this.isLoading = false;
    });
  }

  private canCancel(): boolean {
    if (this.currentModel == null) return true;
    let data = this.mainForm.getRawValue();
    if (
      this.execCode == this.currentModel.execCode &&
      this.planCode == this.currentModel.planCode &&
      this.factCode == this.currentModel.factCode &&
      data.name == this.currentModel.name &&
      data.description == this.currentModel.description &&
      JSON.stringify(this.functionParametrs) == JSON.stringify(this.currentModel.parametrs) 
    )
      return true;

    if (confirm($localize`Отменить изменения?`)) {
      return true;
    }

    return false;
  }

  ngOnInit(): void {
    this.mainForm.disable();
    this.LoadFunctions();
  }

  private setModel(model: ICalcFunctionModel) {
    this.currentModel = model;
    if (model.id === 0) {
      this.mainForm.enable();
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
      this.mainForm.disable();
    }
    if (model.parametrs == null) {
      this.functionParametrs = [];
    } else {
      this.functionParametrs = model.parametrs.map((m) => {
        return { ...m };
      });
    }
    this.mainForm.controls["name"].setValue(model.name);
    this.mainForm.controls["description"].setValue(model.description);
    this.execCode = model.execCode;
    this.planCode = model.planCode;
    this.factCode = model.factCode;
  }

  onItemClick(itemId: any) {
    if (this.currentModel != null) {
      if (!this.isReadOnly) {
        if (!this.canCancel()) {
          return;
        }
      }
    }
    this.selectedId = itemId;
    if (this.selectedId === 0) {
      this.setModel({
        id: 0,
        description: "",
        name: "",
        execCode: "return  fact / plan * 100",
        planCode:"",
        factCode:"",
        parametrs: [],
      });
    } else {
      this.isLoading = true;
      this.functionsService.Get(this.selectedId || 0).subscribe((res) => {
        this.setModel(res);
        this.isLoading = false;
      });
    }
  }
  public toogleEdit() {
    if (this.isReadOnly) {
      this.isReadOnly = false;
      this.mainForm.enable();
    } else {
      if (!this.canCancel()) {
        return;
      }
      if (this.currentModel != null) this.setModel(this.currentModel);
    }
  }
  public onDelete(item: IListItem) {
    if (
      confirm($localize`Вы действительно хотите удалить '${item.name}'?`) ==
      false
    )
      return;

    this.isLoading = true;
    this.functionsService.Remove(item.id).subscribe((res) => {
      this.selectedId = null;
      this.currentModel = null;
      this.LoadFunctions();
    });
  }
  public onRemoveParams(item: IFunctionParametrModel) {
    if (
      confirm($localize`Вы действительно хотите удалить '${item.name}'?`) ==
      false
    ) {
      return;
    }
    let index = this.functionParametrs.indexOf(item);
    if (index >= 0) {
      this.functionParametrs.splice(index, 1);
    }
  }

  public onEditParams(item: IFunctionParametrModel | null) {
    //EditFunctionParametrWndComponent
    var model: IFunctionParametrModel =
      item == null
        ? {
            name: "",
            displayName: "",
            required: false,
            valueType: "number",
          }
        : { ...item };
    const dialogRef = this.dialog.open(EditFunctionParametrWndComponent, {
      width: "350px",
      data: model,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (item == null) {
        this.functionParametrs.push(result);
      } else {
        Object.assign(item, result);
      }
    });
  }

  public onSave() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      let data = this.mainForm.getRawValue();
      this.isLoading = true;
      let model: ICalcFunctionModel = {
        id: this.currentModel?.id || 0,
        name: data.name || "",
        description: data.description || "",
        execCode: this.execCode,
        planCode: this.planCode,
        factCode: this.factCode,
        parametrs: this.functionParametrs,
      };
      this.errorMessage = null;
      this.functionsService.Save(model).subscribe(
        (res) => {
          this.selectedId = res;
          this.setModel({
            ...model,
            id: res,
            parametrs: this.functionParametrs,
          });
          this.LoadFunctions();
          setTimeout(() => {
            this.isReadOnly = false;
            this.mainForm.enable();
          });
        },
        (error) => {
          this.errorMessage = error.error.message;
          this.isLoading = false;
        }
      );
    }
  }
}
