<mat-chip class="user-widget" [class.short]="short">
  <div class="name-wrapper" [matTooltip]="user?.name || ''">
    <div class="photo">
      <avatar [url]="user?.photo || ''"> </avatar>
    </div>
    @if(!short){
    <div>
      @if(!!position){
      <div>
        {{position}}
      </div>
      }
      <div>{{user?.name}}</div>
    </div>
    }
  </div>
</mat-chip>
