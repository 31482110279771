import {
  ApplicationRef,
  ComponentFactoryResolver,
  InjectionToken,
  NgModule,
  inject,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { KpiListPageComponent } from "./pages/kpi-list-page/kpi-list-page.component";
import { EditKpiPageComponent } from "./pages/edit-kpi-page/edit-kpi-page.component";
import { TableGridComponent } from "./components/table-grid/table-grid.component";
import { TableColumnDirective } from "./components/table-grid/table-column.directive";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { Bitrix24Service } from "./services/bitrix24.service";
import { AppComponent } from "./app.component";
import { PLACE_DATA } from "./consts";
import { DictionaryService } from "./services/dictionary.service";
import { PortalModule } from "@angular/cdk/portal";
import { ReactiveFormsModule } from "@angular/forms";
import {
  CdkMenu,
  CdkMenuItem,
  CdkMenuItemRadio,
  CdkMenuGroup,
  CdkMenuItemCheckbox,
  CdkMenuTrigger,
} from "@angular/cdk/menu";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { MatTableModule } from "@angular/material/table";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatGridListModule } from "@angular/material/grid-list";
import { UserSettingsPageComponent } from "./pages/user-settings-page/user-settings-page.component";
import { HttpAuthInterceptor } from "./helpers/HttpAuthInterceptor";
import { OrgStructureService } from "./services/org-structure.service";

import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { EvaluationUsersPageComponent } from "./pages/evaluation-users-page/evaluation-users-page.component";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { provideNativeDateAdapter } from "@angular/material/core";
import { SelectPositionWndComponent } from "./components/select-position-wnd/select-position-wnd.component";
import { SelectPositionComponent } from "./components/select-position/select-position.component";
import { MatChipsModule } from "@angular/material/chips";
import { OrgStructureComponent } from "./pages/settings-page/org-structure/org-structure.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { EditPositionPageComponent } from "./pages/edit-position-page/edit-position-page.component";
import { MessagesService } from "./services/messages.service";
import { SelectUsersComponent } from "./components/select-users/select-users.component";
import { SelectUsersWndComponent } from "./components/select-users-wnd/select-users-wnd.component";
import { EditDepartmentPageComponent } from "./pages/edit-department-page/edit-department-page.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DepartmentsListComponent } from "./pages/settings-page/departments-list/departments-list.component";
import { PositionsListComponent } from "./pages/settings-page/positions-list/positions-list.component";
import { EmployeesListComponent } from "./pages/settings-page/employees-list/employees-list.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoaderComponent } from "./components/loader/loader.component";
import { NotPermitedPageComponent } from "./pages/not-permited-page/not-permited-page.component";
import { LicenseInfoComponent } from "./pages/settings-page/license-info/license-info.component";
import { PaymentPageComponent } from "./pages/payment-page/payment-page.component";
import { ChooseTariffPageComponent } from "./pages/choose-tariff-page/choose-tariff-page.component";
import { SettingsPageComponent } from "./pages/settings-page/settings-page.component";
import { KpiTemplatesComponent } from "./pages/settings-page/kpi-templates/kpi-templates.component";
import { EditKpiTemplatePageComponent } from "./pages/edit-kpi-template-page/edit-kpi-template-page.component";
import { AddKpiFromLibWndComponent } from "./components/add-kpi-from-lib-wnd/add-kpi-from-lib-wnd.component";
import { HelpBtnComponent } from "./components/help-btn/help-btn.component";
import { ArchiveFilterPipe } from "./pipes/archive-filter.pipe";
import { EditUserPageComponent } from "./pages/edit-user-page/edit-user-page.component";
import { ControlledListPageComponent } from "./pages/controlled-list-page/controlled-list-page.component";
import { DefaultPageComponent } from "./pages/default-page/default-page.component";
import { WidgetComponent } from "./pages/default-page/widget/widget.component";
import { UserWidgetComponent } from "./components/user-widget/user-widget.component";
import { DictionariesPageComponent } from "./pages/dictionaries-page/dictionaries-page.component";
import { PeriodsComponent } from "./pages/dictionaries-page/periods/periods.component";
import { SettingsDictionaryService } from "./services/settings-dictionary.service";
import { UnitMeasuresComponent } from "./pages/dictionaries-page/unit-measures/unit-measures.component";
import { A11yModule } from "@angular/cdk/a11y";
import { EditPeriodWndComponent } from "./pages/dictionaries-page/edit-period-wnd/edit-period-wnd.component";
import { ApiKeysPageComponent } from "./pages/api-keys-page/api-keys-page.component";
import { ViewKeyWndComponent } from "./pages/api-keys-page/view-key-wnd/view-key-wnd.component";
import { LicenseService } from "./services/license.service";
import { LicenseErrorPageComponent } from "./pages/license-error-page/license-error-page.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeRu from "@angular/common/locales/ru";
import localeEn from "@angular/common/locales/en";
import { ViewinglistPageComponent } from "./pages/viewinglist-page/viewinglist-page.component";
import { GenerateKpiReportWndComponent } from "./components/generate-kpi-report-wnd/generate-kpi-report-wnd.component";
import { ReportService } from "./services/report.service";
import { ViewTypedValueComponent } from "./components/view-typed-value/view-typed-value.component";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CrudPositionsWndComponent } from "./components/crud-positions-wnd/crud-positions-wnd.component";
import { SelectDepartmentWndComponent } from "./components/select-department-wnd/select-department-wnd.component";
import { SelectDepartmentComponent } from "./components/select-department/select-department.component";
import { FunctionsPageComponent } from "./pages/functions-page/functions-page.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatBadgeModule } from "@angular/material/badge";

import { AceModule } from "ngx-ace-wrapper";
import { ACE_CONFIG } from "ngx-ace-wrapper";
import { AceConfigInterface } from "ngx-ace-wrapper";
import "brace";
import "brace/mode/text";
import "brace/theme/xcode";
import "brace/mode/lua";
import { EditFunctionParametrWndComponent } from "./components/edit-function-parametr-wnd/edit-function-parametr-wnd.component";
import { AddKpisWizardComponent } from "./components/add-kpis-wizard/add-kpis-wizard.component";
import { MatStepperModule } from "@angular/material/stepper";

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

// registerLocaleData(localeEn);
// registerLocaleData(localeRu);
// const components = [KpiListPageComponent,EditKpiPageComponent];
// const views:any= {
//   'edit-kpi':EditKpiPageComponent,
// }

@NgModule({
  declarations: [
    KpiListPageComponent,
    EditKpiPageComponent,
    TableGridComponent,
    TableColumnDirective,
    ProgressBarComponent,
    AppComponent,
    UserSettingsPageComponent,
    SettingsPageComponent,
    SelectPositionComponent,
    SelectPositionWndComponent,
    EvaluationUsersPageComponent,
    AvatarComponent,
    OrgStructureComponent,
    EditPositionPageComponent,
    SelectUsersComponent,
    SelectUsersWndComponent,
    EditDepartmentPageComponent,
    DepartmentsListComponent,
    PositionsListComponent,
    EmployeesListComponent,
    LoaderComponent,
    NotPermitedPageComponent,
    LicenseInfoComponent,
    PaymentPageComponent,
    ChooseTariffPageComponent,
    KpiTemplatesComponent,
    EditKpiTemplatePageComponent,
    AddKpiFromLibWndComponent,
    HelpBtnComponent,
    ArchiveFilterPipe,
    EditUserPageComponent,
    ControlledListPageComponent,
    DefaultPageComponent,
    WidgetComponent,
    UserWidgetComponent,
    DictionariesPageComponent,
    PeriodsComponent,
    UnitMeasuresComponent,
    EditPeriodWndComponent,
    ApiKeysPageComponent,
    ViewKeyWndComponent,
    LicenseErrorPageComponent,
    ViewinglistPageComponent,
    GenerateKpiReportWndComponent,
    ViewTypedValueComponent,
    CrudPositionsWndComponent,
    SelectDepartmentWndComponent,
    SelectDepartmentComponent,
    FunctionsPageComponent,
    EditFunctionParametrWndComponent,
    AddKpisWizardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    PortalModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItemCheckbox,
    CdkMenuGroup,
    CdkMenuItemRadio,
    CdkMenuItem,
    MatTableModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatGridListModule,
    MatDialogModule,
    FormsModule,
    MatTreeModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatChipsModule,
    DragDropModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTooltipModule,
    A11yModule,
    MatButtonToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatSidenavModule,
    AceModule,
    MatStepperModule,
    MatBadgeModule,
  ],
  providers: [
    Bitrix24Service,
    OrgStructureService,
    DictionaryService,
    MessagesService,
    SettingsDictionaryService,
    LicenseService,
    ReportService,
    { provide: PLACE_DATA, useValue: null },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    // { provide: LOCALE_ID, useValue: "ru" },
    provideAnimationsAsync(),
    // provideNativeDateAdapter(),
    provideMomentDateAdapter(MY_FORMATS),
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private resolver: ComponentFactoryResolver,
    private bx24: Bitrix24Service
  ) {}

  // ngDoBootstrap(appRef: ApplicationRef) {
  //   // components.forEach((componentDef: any) => {
  //   //   const factory = this.resolver.resolveComponentFactory(componentDef);
  //   //   let elements = document.querySelectorAll(factory.selector);
  //   //   elements.forEach((elem) => {
  //   //     appRef.bootstrap(componentDef, elem);
  //   //   });
  //   // });
  // }
}
