import {
  Component,
  EnvironmentInjector,
  ViewChild,
  ViewContainerRef,
  inject,
  AfterViewInit,
  createComponent,
  Injector,
} from "@angular/core";
import { EditKpiPageComponent } from "./pages/edit-kpi-page/edit-kpi-page.component";
import { KpiListPageComponent } from "./pages/kpi-list-page/kpi-list-page.component";
import { Bitrix24Service } from "./services/bitrix24.service";
import { PLACE_DATA } from "./consts";
import { UserSettingsPageComponent } from "./pages/user-settings-page/user-settings-page.component";
import { EvaluationUsersPageComponent } from "./pages/evaluation-users-page/evaluation-users-page.component";
import { EditPositionPageComponent } from "./pages/edit-position-page/edit-position-page.component";
import { EditDepartmentPageComponent } from "./pages/edit-department-page/edit-department-page.component";
import { NotPermitedPageComponent } from "./pages/not-permited-page/not-permited-page.component";
import { ChooseTariffPageComponent } from "./pages/choose-tariff-page/choose-tariff-page.component";
import { PaymentPageComponent } from "./pages/payment-page/payment-page.component";
import { SettingsPageComponent } from "./pages/settings-page/settings-page.component";
import { EditKpiTemplatePageComponent } from "./pages/edit-kpi-template-page/edit-kpi-template-page.component";
import { EditUserPageComponent } from "./pages/edit-user-page/edit-user-page.component";
import { ControlledListPageComponent } from "./pages/controlled-list-page/controlled-list-page.component";
import { DefaultPageComponent } from "./pages/default-page/default-page.component";
import { DictionariesPageComponent } from "./pages/dictionaries-page/dictionaries-page.component";
import { ApiKeysPageComponent } from "./pages/api-keys-page/api-keys-page.component";
import { LicenseService } from "./services/license.service";
import { LicenseErrorPageComponent } from "./pages/license-error-page/license-error-page.component";
import { ViewinglistPageComponent } from "./pages/viewinglist-page/viewinglist-page.component";
import { FunctionsPageComponent } from "./pages/functions-page/functions-page.component";

const defaultView = DefaultPageComponent;
// const defaultView = KpiListPageComponent;

const permitionPage = NotPermitedPageComponent;
const views: any = {
  "kpis": KpiListPageComponent,
  "edit-kpi": EditKpiPageComponent,
  "edit-kpi-template": EditKpiTemplatePageComponent,
  // "user-settings": UserSettingsPageComponent,
  orgstructure: SettingsPageComponent,
  settings: SettingsPageComponent,
  "evaluation-users": EvaluationUsersPageComponent,
  "controlled-list": ControlledListPageComponent,
  "user-kpis-page": KpiListPageComponent,
  "edit-position": EditPositionPageComponent,
  "edit-department": EditDepartmentPageComponent,
  "choose-tariff-page": ChooseTariffPageComponent,
  "payment-page": PaymentPageComponent,
  "edit-user": EditUserPageComponent,
  "dictionaries-page": DictionariesPageComponent,
  "api-keys-page": ApiKeysPageComponent,
  "viewing-list":ViewinglistPageComponent,
  "functions-page":FunctionsPageComponent,
  // "user-kpis-page":UserKpiPageComponent
};

const adminPages: any = {
  orgstructure: true,
  settings: true,
  "dictionaries-page": true,
  "api-keys-page": true,
  "functions-page": true,
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements AfterViewInit {
  
  
  constructor(private bx24: Bitrix24Service, private vcr: ViewContainerRef, public licenseService:LicenseService) {
    this.licenseService.onLicenseError.subscribe(res =>{
        // console.log(res);
        this.vcr.clear();
        const injector: Injector = Injector.create({
          providers: [{ provide: PLACE_DATA, useValue: res }],
        });
        this.vcr.createComponent(LicenseErrorPageComponent, {
          injector: injector,
        });
        
    });
  }
  
  ngAfterViewInit() {
    this.bx24.getAuth().subscribe((res) => {
      // console.log(res);
      const injector: Injector = Injector.create({
        providers: [{ provide: PLACE_DATA, useValue: res.PARAMS || null }],
      });

      let view = "";
      let component: any = defaultView;
      if (!res.PARAMS?.view || views[res.PARAMS?.view] == undefined) {
        let params = location.search.split("?");
        if (params.length > 1) {
          params = params[1].split("&");
          params.forEach((f) => {
            let arr = f.split("=");
            if (arr[0].toLowerCase() == "view") {
              view = arr[1] || "";
            }
          });
        }
        if (view != "" && views[view] !== undefined) {
          component = views[view];
        } else {
          component = defaultView;
        }
      } else {
        view = res.PARAMS?.view;
        component = views[res.PARAMS?.view];
      }
      if (adminPages[view] == true) {
        if (!res.IS_ADMIN) {
          this.vcr.createComponent(permitionPage, {
            injector: injector,
          });
          return;
        }
      }
      this.vcr.createComponent(component, {
        injector: injector,
      });
    });
  }
}
