<div class="edit-card">
  <!-- <div> -->
  <mat-card class="mat-edit-card">
    <!-- <mat-card-header>
      <mat-card-title> Цель или Показатель</mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
      <loader [isLoading]="isLoading"></loader>
      <div class="edit-wrapper">
        <div class="edit-wrapper-content">
          <form [formGroup]="kpiForm">
            <mat-form-field>
              <mat-label i18n
                >Наименование {{isGroup ? 'цели' : 'показателя'}}</mat-label
              >
              <input matInput formControlName="name" maxlength="256" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n
                >Код {{isGroup ? 'цели' : 'показателя'}}</mat-label
              >
              <input matInput formControlName="code" maxlength="256"  />
            </mat-form-field>
            @if(!isGroup){
              <mat-form-field>
                <mat-label i18n>Тип</mat-label>
                <mat-select matInput formControlName="typeId">
                  <mat-option
                    *ngFor="let item of dictionaries.KpiType | async"
                    [value]="item.id"
                    >{{ item.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              }
            @if(kpiForm.controls["typeId"].value == 2){
            <mat-form-field>
              <mat-label i18n>Единица измерения</mat-label>
              <mat-select matInput formControlName="unitId">
                <mat-option
                  *ngFor="let item of dictionaries.Units | async"
                  [value]="item.id"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          }
          <mat-form-field>
            <mat-label i18n>Вес</mat-label>
            <input
              matInput
              type="number"
              [min]="0"
              step="1"
              pattern="\d*"
              formControlName="weight"
            />
          </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Тренд</mat-label>
              <mat-select matInput formControlName="trendId">
                <mat-option
                  *ngFor="let item of dictionaries.Thrends | async"
                  [value]="item.id"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
                          <!-- Доп поля -->
                          <div class="function-fields-conteiner">
                            @for (formInfo of functionFieldsForms; track $index) {
                            <form [formGroup]="formInfo.form">
                              <mat-form-field>
                                <mat-label>{{formInfo.info.displayName}}</mat-label>
                                @switch (formInfo.info.valueType) { @case ("number") {
                                <input matInput formControlName="value" type="number" />
                                } @case ("text") {
                                <input matInput formControlName="value" />
                                } }
                              </mat-form-field>
                            </form>
                            }
                          </div>
                          <!-- end Доп поля -->
           

            @switch (kpiForm.controls["typeId"].value) { @case (1) {
            <mat-form-field>
              <mat-label i18n>План</mat-label>
              <mat-select matInput formControlName="plan">
                <mat-option
                  *ngFor="let item of KpiTypeYesNoValues"
                  [value]="item.value"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            } @case (2) {
            <mat-form-field>
              <mat-label i18n>План</mat-label>
              <input
                matInput
                type="number"
                formControlName="plan"
                type="number"
              />
            </mat-form-field>

            } @case (3) { } @case (4) { } @default { } }

            <!-- @if(!isGroup){ -->
            <!-- <mat-form-field>
              <mat-label i18n>План</mat-label>
              <input matInput type="number" formControlName="plan" />
            </mat-form-field> -->
            <!-- } -->
            <mat-form-field>
              <mat-label i18n>Описание</mat-label>
              <textarea
                matInput
                rows="3"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </form>
        </div>

        <div class="action-buttons">
          <button mat-raised-button (click)="onBtnSave()" color="primary" i18n
            >Сохранить</button
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- </div> -->
</div>
<!-- <div class="status-buttons">
    <mat-card>
      <mat-card-actions>
        <div class="action-buttons">
          <button mat-stroked-button (click)="onBtnCancel()">Согласовать</button>
          <button mat-stroked-button (click)="onBtnCancel()">Доработать</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div> -->
