import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ApiKeysService,
  IApiKeyModel,
} from "src/app/services/api-keys.service";
import { ViewKeyWndComponent } from "./view-key-wnd/view-key-wnd.component";

@Component({
  selector: "api-keys-page",
  templateUrl: "./api-keys-page.component.html",
  styleUrl: "./api-keys-page.component.scss",
})
export class ApiKeysPageComponent implements OnInit {
  public isLoading: boolean = true;
  public rows: IApiKeyModel[] = [];
  public displayedColumns = ["actions", "date", "creator"];
  constructor(
    private apiKeysService: ApiKeysService,
    public dialog: MatDialog
  ) {}

  private LoadData() {
    this.isLoading = true;
    this.apiKeysService.GetKeys().subscribe((res) => {
      this.isLoading = false;
      this.rows = res;
    });
  }

  ngOnInit(): void {
    this.LoadData();
  }

  remove(id: number) {
    if (confirm($localize`Удалить ключ?`)) {
      this.isLoading = true;
      this.apiKeysService.RemoveKey(id).subscribe((res) => {
        this.LoadData();
      });
    }
  }

  addKey() {
    this.isLoading = true;
    this.apiKeysService.GenerateKey().subscribe((res) => {
      // this.isLoading = false;
      this.LoadData();
      const dialogRef = this.dialog.open(ViewKeyWndComponent, {
        
        data: res,
      });
    });
  }
}
