<mat-tab-group mat-stretch-tabs="true" [(selectedIndex)]="selectedTab">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">date_range</mat-icon>
      <span i18n>Периоды</span>
      <!-- <help-btn
        class="help-btn-custom"
        page="organizatsionnaya_struktura"
      ></help-btn> -->
    </ng-template>
    <app-periods></app-periods>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">straighten</mat-icon>
      <span i18n>Единицы измерения</span>
      <!-- <help-btn
        class="help-btn-custom"
        page="organizatsionnaya_struktura"
      ></help-btn> -->
    </ng-template>
      <app-unit-measures></app-unit-measures>
  </mat-tab>
</mat-tab-group>
