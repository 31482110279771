import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, map, Observable, Subject, takeUntil } from "rxjs";
import { AddKpisWizardComponent } from "src/app/components/add-kpis-wizard/add-kpis-wizard.component";
import { CrudPositionsWndComponent } from "src/app/components/crud-positions-wnd/crud-positions-wnd.component";
import { PaginatorPageSizes } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { MessagesService } from "src/app/services/messages.service";
import { OrgStructureService } from "src/app/services/org-structure.service";

@Component({
  selector: "positions-list",
  templateUrl: "./positions-list.component.html",
  styleUrl: "./positions-list.component.scss",
})
export class PositionsListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();
  public displayedColumns = [
    "select",
    "name",
    "code",
    "employee",
    "department",
    "approvers",
    "inspectors",
    "viewers",
    "status",
    "disableAccess",
    "actions",
  ];
  public tableRows: MatTableDataSource<any> = new MatTableDataSource();
  public isLoading: boolean = true;
  public PaginatorPageSizes = PaginatorPageSizes;
  public departments: Observable<any[]> | null = null;
  public selection = new SelectionModel<any>(true, []);

  public filterForm = new FormGroup({
    searchString: new FormControl(null),
    status: new FormControl([]),
    access: new FormControl([]),
    departments: new FormControl([]),
  });

  constructor(
    private orgStructureService: OrgStructureService,
    private bitrix24Service: Bitrix24Service,
    private _messagesService: MessagesService,
    public dialog: MatDialog
  ) {}

  private LoadData() {
    this.isLoading = true;
    this.orgStructureService.GetPositionsList().subscribe((res) => {
      this.selection.clear();
      this.tableRows.data = res;
      // this.tableRows.data = [...res,...res,...res,...res,...res,...res,...res,...res,...res];
      this.isLoading = false;
    });
  }
  ngAfterViewInit() {
    this.tableRows.sort = this.sort;
    this.tableRows.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.departments = this.orgStructureService.GetDepartmentList().pipe(
      map((res: any[]) => {
        let result: any[] = [];

        var processTree = (row: any, level: number) => {
          result.push({
            ...row,
            level: level,
          });
          var children = res.filter((f) => f.parentId == row.id);
          for (let item of children) {
            processTree(item, level + 1);
          }
        };

        for (let item of res) {
          if (item.parentId == null) {
            processTree(item, 0);
          }
        }
        // console.log(result);
        return result;
      })
    );

    this.tableRows.filterPredicate = (row: any, filter: string) => {
      // console.log(row,filter);
      let result = true;
      let formData = this.filterForm.getRawValue();

      if (
        formData.searchString === null ||
        formData.searchString === undefined
      ) {
      } else {
        result =
          result &&
          (row.name.toLowerCase().indexOf(formData.searchString) >= 0 ||
            (row.code || "").toLowerCase().indexOf(formData.searchString) >=
              0 ||
            (row.user?.name || "")
              .toLowerCase()
              .indexOf(formData.searchString) >= 0 ||
            (row.departmentName || "")
              .toLowerCase()
              .indexOf(formData.searchString) >= 0);
      }
      if (
        result &&
        formData.departments != null &&
        formData.departments.length > 0
      ) {
        result =
          result &&
          formData.departments.find((f) => row.departmentId == (<any>f).id) != null;
      }
      if (Array.isArray(formData.status) && formData.status.length > 0) {
        result =
          result && formData.status.find((f) => f == row.archived) != null;
      }

      if (Array.isArray(formData.access) && formData.access.length > 0) {
        let access: any = !row.disableAccess;
        result = result && formData.access.find((f) => f == access) != null;
      }
      return result;
    };
    this.tableRows.filter = "{}";
    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((res) => {
      this.selection.clear();
      this.tableRows.filter = JSON.stringify(res);
    });

    this.tableRows.sortingDataAccessor = (row: any, property: string) => {
      // console.log(row,property)
      switch (property) {
        case "user":
          return row.user?.name;
        default:
          return row[property];
      }
    };
    let me = this;
    this._messagesService.onUpdatePosition
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(100))
      .subscribe((res) => {
        this.LoadData();
      });
    this.LoadData();
  }

  onEditDepartment(row: any) {
    this.bitrix24Service
      .editPosition(row?.id || 0, row?.departmentId || null)
      .then((res) => {});
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableRows.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tableRows.filteredData);
  }

  onAddKpisFromTemplates(){
     const dialogRef = this.dialog.open(AddKpisWizardComponent, {
      minWidth: "400px",
      // minHeight: "640px",
      data: this.selection.selected,
    });
  }

  onChangePositions(role: string) {
    let title = "";
    switch (role) {
      case "approvers":
        title = $localize`Изменение согласующих`;
        break;
      case "inspectors":
        title = $localize`Изменение контролеров`;
        break;
      case "viewers":
        title = $localize`Изменение наблюдателей`;
        break;
    }
    const dialogRef = this.dialog.open(CrudPositionsWndComponent, {
      minWidth: "400px",
      // minHeight: "640px",
      data: title,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.isLoading = true;
      this.orgStructureService
        .CrudPositionData(
          role,
          result.action,
          this.selection.selected.map((m) => m.id),
          result.ids
        )
        .subscribe((res) => {
          this._messagesService.UpdatePosition(null);
          this.LoadData();
        });
    });
  }
  public onChangeAccess(access: boolean) {
    this.isLoading = true;
    this.orgStructureService
      .ChangePositionsAccess(
        access,
        this.selection.selected.map((m) => m.id)
      )
      .subscribe((res) => {
        this._messagesService.UpdatePosition(null);
        this._messagesService.onUpdatePosition.next(null);
        this._messagesService.UpdateEmployee(null);
        this._messagesService.onUpdateEmployee.next(null);
        // this.LoadData();
      });
  }
  public onChangeArchived(archived: boolean) {
    this.isLoading = true;
    this.orgStructureService
      .ChangePositionsArchived(
        archived,
        this.selection.selected.map((m) => m.id)
      )
      .subscribe((res) => {
        this._messagesService.UpdatePosition(null);
        this._messagesService.onUpdatePosition.next(null);
        this._messagesService.UpdateEmployee(null);
        this._messagesService.onUpdateEmployee.next(null);
        // this.LoadData();
      });
  }
}
