<div class="main-grid-wrapper">
    <div class="main-grid-container">
      <table class="main-grid-table">
        <thead class="main-grid-header">
          <tr class="main-grid-row-head">
            <!-- <th
              class="main-grid-cell-head main-grid-cell-static main-grid-cell-action"
            >
              <span class="main-grid-interface-settings-icon"></span>
            </th> -->
            <th
              *ngFor="let column of columns"
              class="main-grid-cell-head main-grid-cell-left main-grid-col-sortable main-grid-draggable"
              data-sort-order="asc"
            >
              <div class="main-grid-cell-inner">
                <span class="main-grid-cell-head-container"
                  ><span class="main-grid-head-title">
                    <ng-container *ngTemplateOutlet="column.header">
                    </ng-container> </span
                  ><span
                    class="main-grid-resize-button"
                    onclick="event.stopPropagation(); "
                    title=""
                  ></span>
                  <!-- <span class="main-grid-control-sort main-grid-control-sort-desc"></span> -->
                </span>
              </div>
            </th>
            <th
              class="main-grid-cell-head main-grid-cell-static main-grid-special-empty"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows" class="main-grid-row main-grid-row-body">
            <!-- <td class="main-grid-cell main-grid-cell-action">
              <span class="main-grid-cell-content"
                >
                <a href="#" class="main-grid-row-action-button"></a>
            </span>
            </td> -->
            <td
              *ngFor="let column of columns"
              class="main-grid-cell main-grid-cell-left"
              data-editable="true"
            >
              <div class="main-grid-cell-inner">
                <span class="main-grid-cell-content">
                  <ng-container
                    *ngTemplateOutlet="column.cell;context: {$implicit: row }"
                  >
                  </ng-container>
                </span>
              </div>
            </td>
  
            <td class="main-grid-cell"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>