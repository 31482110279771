<loader [isLoading]="isLoading"></loader>

<mat-card>
  <mat-card-content>
    <div class="info-wrapper">
      <div *ngIf="info?.userLimit != null ">
        <label i18n>Кол-во пользователей: </label>
        {{info?.userLimit}}
      </div>
      <div>
        <label i18n>Подписка до: </label>
        @if (info?.isBlocked) { Не активна } @else { {{info?.expirationDate |
        date}} }
      </div>
      <div class="flex-auto"></div>
      <div>
        <button
          mat-raised-button
          color="primary"
          (click)="bitrix24Service.openView('choose-tariff-page')"
          i18n
          >Продлить</button
        >
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <table mat-table [dataSource]="invoices">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef i18n>Наименование</th>
        <td mat-cell *matCellDef="let element">
          {{element.title}}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef i18n>Статус</th>
        <td mat-cell *matCellDef="let element" i18n
          >@if (element.isPaid) { Оплачен } @else { Создан }</td
        >
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef i18n>Дата создание</th>
        <td mat-cell *matCellDef="let element">
          {{element.creationDate}}
        </td>
      </ng-container>
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          @if (element.isCreated) {
          <button
            mat-icon-button
            (click)="onDownloadClick(element)"
            i18n-matTooltip
            matTooltip="Скачать"
          >
            <mat-icon>download</mat-icon>
          </button>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
