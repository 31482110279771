import { Component, Inject, OnInit } from "@angular/core";
import IPlaceParamsData from "../../services/bx24/entities/placeParamsData";
import { PLACE_DATA } from "../../consts";
import { Bitrix24Service } from "../../services/bitrix24.service";
import { DictionaryService } from "../../services/dictionary.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { KpiService } from "../../services/kpi.service";
import { IDictionaryItem, IKpi } from "../../models";
import { Observable, debounceTime, map, merge } from "rxjs";
import { MessagesService } from "src/app/services/messages.service";
import {
  KpiTypeYesNoValues,
  KpiTypeThreeVariantsValues,
  KpiTypeFiveVariantsValues,
} from "../../consts";
import { IFunctionParametrModel } from "src/app/services/functions.service";

interface IFormInfo {
  info: IFunctionParametrModel;
  form: FormGroup;
}

@Component({
  selector: "div[edit-kpi]",
  templateUrl: "./edit-kpi-page.component.html",
  styleUrl: "./edit-kpi-page.component.scss",
})
export class EditKpiPageComponent implements OnInit {
  [x: string]: any;
  public kpiForm = new FormGroup({
    name: new FormControl("", Validators.required),
    code: new FormControl(null as string | null),
    periodId: new FormControl(null as number | null, Validators.required),
    categoryId: new FormControl(null as number | null, Validators.required),
    unitId: new FormControl(null as number | null, Validators.required),
    trendId: new FormControl(null as number | null, Validators.required),
    typeId: new FormControl(null as number | null, Validators.required),
    plan: new FormControl(null as number | null, Validators.required),
    fact: new FormControl(null as number | null),
    weight: new FormControl(1, Validators.required),
    comment: new FormControl(""),
  });

  public functionFieldsForms: IFormInfo[] = [];

  public KpiTypeYesNoValues = KpiTypeYesNoValues;
  public KpiTypeFiveVariantsValues = KpiTypeFiveVariantsValues;
  public KpiTypeThreeVariantsValues = KpiTypeThreeVariantsValues;

  public model?: IKpi | null = null;

  public isLoading: boolean = false;

  public isGroup: boolean = false;
  public isController: boolean = false;
  public isViewer: boolean = false;
  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private bx: Bitrix24Service,
    private kpiService: KpiService,
    public dictionaries: DictionaryService,
    private _messagesService: MessagesService
  ) {
    // console.log(data);
    this.isController = data.data?.role == "controller";
    this.isViewer = data.data?.role == "viewer";

    this.isGroup = data.data?.isGroup == "true";
    if (this.isController) {
      this.kpiForm.controls["comment"].addValidators(Validators.required);
    }
    if (!!data.data?.periodId) {
      this.kpiForm.controls["periodId"].setValue(parseInt(data.data?.periodId));
      if (!!data.data.parentId) {
        this.kpiForm.controls["periodId"].disable();
      }
    }
    if (this.isGroup) {
      this.kpiForm.controls["categoryId"].setValue(1);
      this.kpiForm.controls["plan"].setValue(100);

      if (this.data.id == null) {
        setTimeout(() => {
          dictionaries.Units.subscribe((res) => {
            let percentUnit = res.find((f) => f.name == "%");
            if (percentUnit != null) {
              this.kpiForm.controls["unitId"].setValue(percentUnit.id);
            }
          });
        });
      }
      this.kpiForm.controls["typeId"].setValue(2);
    } else {
      this.kpiForm.controls["categoryId"].setValue(3);
    }
    this.kpiForm.controls["categoryId"].disable();

    if (this.isViewer) {
      this.kpiForm.disable();
    }
  }

  public getStartArray() {
    let l = 0;
    if (this.kpiForm.controls["fact"].value != null) {
      l = this.kpiForm.controls["fact"].value / 20;
    }
    return new Array(l);
  }

  private LoadData(id: number) {
    this.isLoading = true;
    this.kpiService.GetKpi(id).subscribe((res: any) => {
      this.isGroup = res.isGroup;
      this.model = res;
      let isGroup = res.isGroup == true || res.isGroup == "true";

      for (const field in this.kpiForm.controls) {
        const control = this.kpiForm.get(field); // 'control' is a FormControl
        control?.setValue(res[field]);
        // if (field == 'plan') continue;
        if (field == "comment") continue;
        control?.enable();
        if (!res.canEdit) control?.disable();
      }
      if (res.canEditFact) {
        this.kpiForm.get("fact")?.enable();
      }
      if (res.parentId != null) {
        this.kpiForm.controls["periodId"].disable();
      }
      this.kpiForm.controls["categoryId"].disable();

      if (isGroup) {
        this.kpiForm.controls["fact"].disable();
      }

      if (this.isViewer) {
        this.kpiForm.disable();
      }
      this.isLoading = false;
    });
  }

  private async onTrendChanged() {
    let hasPlanValidator = false;
    let isPlanReadOnly = this.model != null && (   !this.model?.canEdit);
    let isFactReadOnly = this.model != null && ( !this.model?.canEditFact && !this.model?.canEdit);
    if ((this.kpiForm.controls["typeId"].value || 0) == 1) {
      this.kpiForm.controls.unitId.clearValidators();
    } else if ((this.kpiForm.controls["typeId"].value || 0) > 2) {
      hasPlanValidator = false;
      // this.kpiForm.controls.plan.clearValidators();
      this.kpiForm.controls.unitId.clearValidators();
    } else {
      hasPlanValidator = true;
      this.kpiForm.controls.unitId.addValidators(Validators.required);
      // this.kpiForm.controls.plan.addValidators(Validators.required);
    }

    let trendId = this.kpiForm.controls["trendId"].value || 0;
    this.functionFieldsForms = [];
    if (trendId < 0) {
      let dic: any[] = (await this.dictionaries.Thrends.toPromise()) || [];
      let currentValue = dic?.find((f) => f.id == trendId);
      var fields: IFunctionParametrModel[] = currentValue.parametrs;
      for (const field of fields) {
        var fControl: FormControl;
        let value = null;
        if (
          this.model?.functionParametrs != null &&
          this.model?.functionParametrs[field.name] !== undefined
        ) {
          value = this.model?.functionParametrs[field.name];
        }
        if (field.required) {
          fControl = new FormControl(value, Validators.required);
        } else {
          fControl = new FormControl(value);
        }
        let formInfo = {
          info: field,
          form: new FormGroup({
            value: fControl,
          }),
        };
        if (this.isViewer) {
          formInfo.form.disable();
        }
        if ((this.model?.id || 0) > 0 && !this.model?.canEdit) {
          formInfo.form.disable();
        }
        this.functionFieldsForms.push(formInfo);
      }
      if (currentValue.hasPlanFunction) {
        isPlanReadOnly = true;
        hasPlanValidator = false;
      } else {
        hasPlanValidator = true;
      }
      if (currentValue.hasFactFunction) {
        isFactReadOnly = true;
      } else {
      }
    } else {
      // hasPlanValidator = true;
    }

    isPlanReadOnly = this.isGroup || isPlanReadOnly;
    isFactReadOnly = this.isGroup || isFactReadOnly;
    if (hasPlanValidator) {
      this.kpiForm.controls.plan.addValidators(Validators.required);
    } else {
      this.kpiForm.controls.plan.clearValidators();
    }

    if (isPlanReadOnly) {
      this.kpiForm.controls.plan.disable();
    } else {
      this.kpiForm.controls.plan.enable();
    }
    if (isFactReadOnly) {
      this.kpiForm.controls.fact.disable();
    } else {
      this.kpiForm.controls.fact.enable();
    }

    this.kpiForm.controls.unitId.updateValueAndValidity();
    this.kpiForm.controls.plan.updateValueAndValidity();
  }

  ngOnInit(): void {
    if (this.data.id != null) {
      this.LoadData(this.data.id);
    }

    merge(
      this.kpiForm.controls["trendId"].valueChanges,
      this.kpiForm.controls["typeId"].valueChanges
    )
      .pipe(debounceTime(100))
      .subscribe((res) => {
        this.onTrendChanged();
      });
  }

  public onBtnCancel() {
    this.bx.closeCurrentWnd();
  }

  public onSetStatus(statusId: number) {
    if (this.isController) {
      this.kpiForm.markAllAsTouched();
      if (!this.kpiForm.valid) {
        return;
      }
    }
    this.isLoading = true;
    this.kpiService
      .ChangeStatus(
        this.model?.id || 0,
        statusId,
        this.kpiForm.controls["comment"].value || null,
        this.isController
      )
      .subscribe((res) => {
        // this.bx.closeCurrentWnd();
        this.isLoading = false;
        this._messagesService.UpdateKpi(null);
        this.LoadData(this.model?.id || 0);
      });
  }

  public onSaveController(statusId: number) {}

  public onBtnSave(process: boolean = false) {
    let value = this.kpiForm.getRawValue();
    // let typeId = value.typeId || 0;
    let plan = value.plan;
    // if (typeId == 1) {

    //   // this.kpiForm.controls["unitId"].setValue(-1);
    // } else if (typeId > 2) {
    //   plan = 100;
    //   // this.kpiForm.controls["unitId"].setValue(-1);
    // }
    setTimeout(() => {
      this.kpiForm.markAllAsTouched();

      let isValid = this.kpiForm.valid;
      let params: Record<string, any> = {};
      for (const item of this.functionFieldsForms) {
        item.form.markAllAsTouched();
        isValid = isValid && (item.form.disabled || item.form.valid);
        params[item.info.name] = item.form.controls["value"].value;
        if (item.form.invalid) {
          console.log(item.form.errors);
        }
      }

      if (isValid) {
        value = this.kpiForm.getRawValue();
        let model: IKpi = {
          id: this.model?.id,
          name: value.name || "",
          code: value.code,
          periodId: value.periodId || 0,
          categoryId: value.categoryId || 0,
          trendId: value.trendId || 0,
          typeId: value.typeId || 0,
          unitId: value.unitId || -1,
          plan: plan,
          fact: value.fact,
          ownerPositionUserId:
            this.model?.ownerPositionUserId == null
              ? this.data.positionId
              : this.model?.ownerPositionUserId,
          isGroup:
            this.model == null
              ? this.data.data?.isGroup == "true"
              : this.model.isGroup,
          parentId:
            this.model == null ? this.data.data.parentId : this.model.parentId,
          weight: value.weight,
          comment: value.comment,
          functionParametrs: params,
        };
        this.isLoading = true;
        this.kpiService.SaveKpi(model, process).subscribe(
          (res) => {
            this.isLoading = false;
            this.LoadData(res);
            this._messagesService.UpdateKpi(model);
          },
          (error) => {
            if (error.error?.error == "EmptyFactException") {
              this.isLoading = false;
              alert(
                $localize`Не заполнено фактическое значение перед отправкой на согласование факта`
              );
            }
          }
        );
      } else {
        console.log(this.kpiForm.errors);
      }
    });
    console.log(this.kpiForm.getRawValue());
  }
}
