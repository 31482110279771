import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss",
})
export class LoaderComponent {
  @HostBinding("class.display")
  @Input()
  isLoading: boolean = false;
}
