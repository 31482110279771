import { Component, Input } from '@angular/core';
import { KpiTypeYesNoValues, KpiTypeFiveVariantsValues, KpiTypeThreeVariantsValues } from 'src/app/consts';

@Component({
  selector: 'view-typed-value',
  templateUrl: './view-typed-value.component.html',
  styleUrl: './view-typed-value.component.scss'
})
export class ViewTypedValueComponent {
  @Input() typeId:number | null = null;
  @Input() value:number | null = null;
  
  public KpiTypeYesNoValues = KpiTypeYesNoValues;
  public KpiTypeFiveVariantsValues = KpiTypeFiveVariantsValues;
  public KpiTypeThreeVariantsValues = KpiTypeThreeVariantsValues;


  public getValueByProp(data: any[], propName: string, value: number | null) {
    for (let row of data) {
      if (row.value == value) return row[propName];
    }
    return null;
  }
}
