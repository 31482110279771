<div class="wrapper" [class.disabled]="isDisabled">
  <div class="content">
    
      {{getContentText()}}
    
    <!-- <mat-chip-set>
        @for (item of value; track item) {
        <mat-chip (removed)="onRemoveClick(item)">
          <div class="name-wrapper">           
            <div>
              <div>
                {{item.name}}
              </div>              
            </div>
          </div>
  
          <button matChipRemove [attr.aria-label]="'remove ' + item.name">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>      
        }    
      </mat-chip-set> -->

    <!-- @for (item of value; track item) {
      <div class="selected-item">
        {{item.name}}
        
        <div class="close-btn" (click)="onRemoveClick(item)">
            <mat-icon>clear</mat-icon>
        </div>
        
      </div>
      } -->
  </div>
  <div class="caret">
    <div tabindex="-1"
      mat-icon-button
      
      (click)="onSelectClick()"
      
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </div>
</div>
