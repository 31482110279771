import { ContentChild, Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTableColumn]'
})
export class TableColumnDirective {

  @ContentChild('header') header!: TemplateRef<any>;
  @ContentChild('cell') cell!: TemplateRef<any>;


}
