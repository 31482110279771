import { Component, Input } from '@angular/core';
import { IUserInfoModel } from 'src/app/models';


@Component({
  selector: 'user-widget',
  templateUrl: './user-widget.component.html',
  styleUrl: './user-widget.component.scss'
})
export class UserWidgetComponent {
  @Input() user:IUserInfoModel | null = null;
  @Input() position:string = "";
  @Input() short:boolean = false;
}
