import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'help-btn',
  templateUrl: './help-btn.component.html',
  styleUrl: './help-btn.component.scss'
})
export class HelpBtnComponent implements OnChanges {
  @Input() page:string | null = null;

  public url?:string;
  ngOnChanges(changes: SimpleChanges): void {
    this.url =`https://help.targetplanning.ru/${this.page}.htm`;
  }
}
