import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import {
  IUnitMeasureDicModel,
  SettingsDictionaryService,
} from "src/app/services/settings-dictionary.service";

@Component({
  selector: "app-unit-measures",
  templateUrl: "./unit-measures.component.html",
  styleUrl: "./unit-measures.component.scss",
})
export class UnitMeasuresComponent implements OnInit {
  public isLoading: boolean = true;
  public rows: IUnitMeasureDicModel[] = [];
  public editRow: IUnitMeasureDicModel | null = null;
  public editRowBack: IUnitMeasureDicModel | null = null;

  constructor(
    private settingsDictionaryService: SettingsDictionaryService,
    private _ngZone: NgZone
  ) {}
  private LoadData() {
    this.isLoading = true;
    this.settingsDictionaryService.GetUnitMeasuresFull().subscribe((res) => {
      this.rows = res;
      if (this.editRow != null) {
        this.editRow = this.rows.find((f) => f.id == this.editRow?.id) || null;
      }
      this.isLoading = false;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    this.isLoading = true;
    this.rows.forEach((row, idx) => {
      row.orderId = idx;
    });
    this.settingsDictionaryService
      .SaveUnitMeasure(this.rows)
      .subscribe((res) => {
        this.isLoading = false;
      });
  }
  ngOnInit(): void {
    this.LoadData();
  }

  @HostListener("window:click", ["$event"])
  public onMouseClick(event: PointerEvent) {
    if (this.editRow != null) {
      if ((<any>event.target).localName == "div") {
        event.stopPropagation();
        this.saveRow(this.editRow);
        this.editRow = null;
        // this._ngZone.run(() => {
        // });
      }
    }
  }

  private saveRow(row: IUnitMeasureDicModel) {
    if (
      row.name == this.editRowBack?.name &&
      row.id == this.editRowBack?.id &&
      row.isHidden == this.editRowBack?.isHidden
    )
      return;
    this.isLoading = true;
    this.settingsDictionaryService.SaveUnitMeasure([row]).subscribe((res) => {
      this.isLoading = false;
      if (row.id == 0) {
        this.LoadData();
      }
    });
  }

  public onEditClick(row: IUnitMeasureDicModel) {
    if (this.editRow != null) {
      this.saveRow(this.editRow);
    }
    if (this.editRow == row) {
      this.editRow = null;
    } else {
      this.editRow = row;
      this.editRowBack = { ...row };
    }
  }
  public onKeyPress(event: KeyboardEvent) {
    if (event.key == "Enter") {
      if (this.editRow != null) {
        this.saveRow(this.editRow);
        this.editRow = null;
      }
    }
  }
  public onFocusInput(event: any) {
    event.target.select();
  }

  public onAdd() {
    let item: IUnitMeasureDicModel = {
      id: 0,
      name: "unit",
      orderId: this.rows.length,
      isHidden: false,
    };
    this.rows.push(item);
    this.editRow = item;
  }
}
