<loader [isLoading]="isLoading"></loader>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{periodName}}&nbsp;
    </mat-card-title>
    <mat-card-subtitle>
      {{price?.name}}&nbsp;
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    {{price?.price}}
  </mat-card-content>
</mat-card>
<div class="columns">
  <div class="column">
    <div class="column-wrapper">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <div class="title">
              <div i18n>Платёжная информация</div>
              <div>
                @if (paymentInfo.length > 1) {
                <mat-select
                  [(ngModel)]="selectedPaymentInfo"
                  (ngModelChange)="setPaymentInfo()"
                >
                  @for (item of paymentInfo; track $index) {
                  <mat-option [value]="item">{{item.companyName}}</mat-option>

                  }
                </mat-select>
                }
              </div>
            </div>
          </mat-card-title>
          <mat-card-subtitle>
            &nbsp;
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="mainForm">
            <mat-form-field>
              <mat-label i18n>Наименование компании</mat-label>
              <input matInput formControlName="companyName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Полное компании</mat-label>
              <input matInput formControlName="companyFullName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>ИНН</mat-label>
              <input matInput formControlName="inn" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>КПП</mat-label>
              <input matInput formControlName="kpp" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>ОГРН</mat-label>
              <input matInput formControlName="ogrn" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Адрес регистрации</mat-label>
              <input matInput formControlName="regAddress" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Почтовый адрес</mat-label>
              <input matInput formControlName="address" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Телефон</mat-label>
              <input matInput formControlName="phone" />
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="column">
    <div class="column-wrapper">
      <mat-card class="card-invoice-info">
        <mat-card-content>
          <div class="invoice-info">
            @if(isInvoiceCreated){

            <loader [isLoading]="isCreatingInvoice" i18n>Создание счета</loader>
            @if(!isCreatingInvoice){
            <div class="invoice-download">
              <button
                mat-raised-button
                color="primary"
                (click)="onDownloadClick(invoiceModel)"
                i18n
                >Скачать счёт</button
              >
            </div>
            } }
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
@if(!isInvoiceCreated){
<mat-card>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="onCreateCheck()" i18n
      >Создать счёт</button
    >
  </mat-card-actions>
</mat-card>
}
