import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrl: './widget.component.scss'
})
export class WidgetComponent {
  @Output() onTitleClick:EventEmitter<any> = new EventEmitter();

  @Input() titleText:string = "";
  @Input() videoHref:string = "";
  @Input() icon:string = "";
  @Input() iconColor:string = "";
  @Input() iconBackgroundColor:string = "";

}
