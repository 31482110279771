<div class="wrapper">
  <div class="data">
    <div class="conteiner">
      <div class="conteiner-icon">
        <div
          class="conteiner-icon__wrapper"
          [style.color]="iconColor"
          [style.background-color]="iconBackgroundColor"
        >
          <mat-icon>{{icon}}</mat-icon>
        </div>
      </div>
      <div class="conteiner-data">
        <div
          class="conteiner-title"
          [class.cilable]="onTitleClick.observed"
          (click)="onTitleClick.emit($event)"
        >
          {{titleText}}
        </div>
        <div class="conteiner-content">
          <ng-content></ng-content>
        </div>
      </div>
      @if (videoHref) {
      <a target="_blank" [href]="videoHref">
        <mat-icon>play_circle_outline</mat-icon>
      </a>
      }
    </div>
  </div>
</div>
