import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrepareModelData } from "../helpers/helper";
export interface IUserModel {
  id: number;
  name: string | null;
  email: string | null;
  lastName: string | null;
  secondName: string | null;
  isBitrixUser: boolean;
  fired: boolean;
  disabledAccess: boolean;
}
@Injectable({
  providedIn: "root",
})
export class OrgStructureService {
  constructor(private http: HttpClient) {}

  public GetList(): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/get");
  }

  public GetDepartmentList(): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getdepartmentlist");
  }

  public GetPositionsList(): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getpositionslist");
  }
  public GetUsersList(): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getuserslist");
  }

  public SyncUsers(): Observable<any[]> {
    return this.http.post<any>("/api/orgstructure/syncusers", {});
  }

  public CheckSyncUsers(): Observable<boolean> {
    return this.http.post<boolean>("/api/orgstructure/CheckSync", {});
  }

  public GetUsers(): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getusers");
  }
  public GetUser(userId: number): Observable<IUserModel> {
    return this.http.get<IUserModel>(`/api/orgstructure/getuser/${userId}`);
  }

  public SaveUser(model: IUserModel): Observable<number> {
    return this.http.post<number>(`/api/orgstructure/saveuser`, model);
  }

  public SaveApprovers(positionId: number, userIds: number[]): Observable<any> {
    return this.http.post(
      `/api/orgstructure/saveapprovers/${positionId}`,
      userIds
    );
  }

  public SaveInspectors(
    positionId: number,
    userIds: number[]
  ): Observable<any> {
    return this.http.post(
      `/api/orgstructure/saveinspectors/${positionId}`,
      userIds
    );
  }

  public GetMembersToEvaluate(searchModel: any): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getMembersToEvaluate", {
      params: PrepareModelData(searchModel),
    });
  }
  public GetMembersToView(searchModel: any): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getMembersToView", {
      params: PrepareModelData(searchModel),
    });
  }
  public GetMembersToControl(searchModel: any): Observable<any[]> {
    return this.http.get<any[]>("/api/orgstructure/getMembersToControl", {
      params: PrepareModelData(searchModel),
    });
  }
  public SaveDepartment(model: any): Observable<any> {
    return this.http.post<any>(`/api/orgstructure/savedepartment`, model);
  }

  public GetPosition(positionId: number): Observable<any> {
    return this.http.get<any>(`/api/orgstructure/getposition/${positionId}`);
  }
  public GetDepartment(departmentId: number): Observable<any> {
    return this.http.get<any>(
      `/api/orgstructure/getdepartment/${departmentId}`
    );
  }

  public SavePosition(model: any, full: boolean): Observable<any> {
    return this.http.post<any>(`/api/orgstructure/saveposition/${full}`, model);
  }

  public ChangeUsersAccess(
    access: boolean,
    userIds: number[]
  ): Observable<number> {
    return this.http.post<number>(
      `/api/orgstructure/changeusersaccess/${access}`,
      userIds
    );
  }
  public ChangePositionsAccess(
    access: boolean,
    ids: number[]
  ): Observable<number> {
    return this.http.post<number>(
      `/api/orgstructure/changepositionsaccess/${access}`,
      ids
    );
  }
  public ChangePositionsArchived(
    archived: boolean,
    ids: number[]
  ): Observable<number> {
    return this.http.post<number>(
      `/api/orgstructure/changepositionsarchived/${archived}`,
      ids
    );
  }

  public CrudPositionData(
    filed: string,
    action: string,
    targetPositionIds: number[],
    ids: number[]
  ): Observable<any> {
    return this.http.post<number>(`/api/orgstructure/changepositions`, {
      field: filed,
      action: action,
      targetIds: targetPositionIds,
      ids: ids,
    });
  }
}
