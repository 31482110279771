import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, Subject, takeUntil } from "rxjs";
import { PaginatorPageSizes } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { MessagesService } from "src/app/services/messages.service";
import { OrgStructureService } from "src/app/services/org-structure.service";

@Component({
  selector: "employees-list",
  templateUrl: "./employees-list.component.html",
  styleUrl: "./employees-list.component.scss",
})
export class EmployeesListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public PaginatorPageSizes = PaginatorPageSizes;

  destroy$: Subject<boolean> = new Subject<boolean>();
  public displayedColumns = [
    "select",
    "type",
    "name",
    "position",
    "status",
    "hasAccess",
    "actions",
  ];
  // public displayedColumns = ["name", "status"];
  public tableRows: MatTableDataSource<any> = new MatTableDataSource();
  public isLoading: boolean = true;
  public showAll: boolean = false;

  public selection = new SelectionModel<any>(true, []);

  public filterForm = new FormGroup({
    searchString: new FormControl(null),
    status: new FormControl([]),
    access: new FormControl([]),
  });

  constructor(
    private orgStructureService: OrgStructureService,

    public bx: Bitrix24Service,
    private _messagesService: MessagesService
  ) {}

  private LoadData() {
    this.isLoading = true;
    this.orgStructureService.GetUsersList().subscribe((res) => {
      this.selection.clear();
      this.tableRows.data = res;
      this.isLoading = false;
    });
  }

  ngAfterViewInit() {
    this.tableRows.sort = this.sort;
    this.tableRows.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    // this.tableRows.sortingDataAccessor = (row:any,property:string) =>{
    //   console.log(row,property)
    //   switch(property){
    //     case "user": return row.user?.name;
    //     default : return row[property];
    //   }
    // }
    this.tableRows.filterPredicate = (row: any, filter: string) => {
      // console.log(row,filter);
      let formData = this.filterForm.getRawValue();
      if (
        formData.searchString === null ||
        formData.searchString === undefined
      ) {
      } else {
        if (
          !(
            row.name.toLowerCase().indexOf(formData.searchString) >= 0 ||
            (row.position || "").toLowerCase().indexOf(formData.searchString) >=
              0
          )
        ) {
          return false;
        }
      }
      let result = true;
      if (Array.isArray(formData.status) && formData.status.length > 0) {
        let fired: any = row.fired;
        result = result && formData.status.find((f) => f == fired) != null;
      }

      if (Array.isArray(formData.access) && formData.access.length > 0) {
        let access: any = row.hasAccess;
        result = result && formData.access.find((f) => f == access) != null;
      }

      return result;
    };
    this.tableRows.filter = "{}";
    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((res) => {
      this.tableRows.filter = JSON.stringify(res);
      this.selection.clear();
    });
    let me = this;
    this._messagesService.onUpdateEmployee
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.LoadData();
      });
    this.LoadData();
  }

  public onChangeAccess(access: boolean) {
    this.isLoading = true;
    this.orgStructureService
      .ChangeUsersAccess(
        access,
        this.selection.selected.map((m) => m.id)
      )
      .subscribe((res) => {
        this.LoadData();
      });
  }

  onEditMember(memberId: number | null) {
    this.bx.editMember(memberId).then((res) => {});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableRows.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tableRows.filteredData);
  }

  private checkSync(){
    this.orgStructureService.CheckSyncUsers().subscribe(res => {
      if (res == true){
        this._messagesService.UpdateEmployee(null);
        this.LoadData();
      } else {
        setTimeout(() => {
          this.checkSync();
        }, 1000);
      }
    })
  }

  onSync() {
    this.isLoading = true;
    this.orgStructureService.SyncUsers().subscribe((res) => {
      this.checkSync();

      // this._messagesService.onUpdateEmployee.next(null)
      // this.LoadData();
    });
  }
}
