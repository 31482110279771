import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { debounceTime } from "rxjs";
import { GenerateKpiReportWndComponent } from "src/app/components/generate-kpi-report-wnd/generate-kpi-report-wnd.component";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { LicenseService } from "src/app/services/license.service";
import { MessagesService } from "src/app/services/messages.service";

@Component({
  selector: "default-page",
  templateUrl: "./default-page.component.html",
  styleUrl: "./default-page.component.scss",
})
export class DefaultPageComponent implements OnInit {
  public isLoading = true;
  public isAdmin: boolean = false;
  public info: any = null;
  constructor(
    public bx: Bitrix24Service,
    private licenseService: LicenseService,
    private messagesService: MessagesService,
    public dialog: MatDialog
  ) {
    bx.getAuth().subscribe((res) => {
      this.isAdmin = res.IS_ADMIN == true;
    });

    // <script>
    (function (w: any, d: any, u: any) {
      var s = d.createElement("script");
      s.async = true;
      s.src = u + "?" + ((Date.now() / 60000) | 0);
      var h = d.getElementsByTagName("script")[0];
      h.parentNode.insertBefore(s, h);
    })(
      window,
      document,
      "https://cdn-ru.bitrix24.ru/b29396708/crm/site_button/loader_4_nzmw6m.js"
    );
    // </script>
  }
  loadData() {
    this.licenseService.GetInfo().subscribe((res) => {
      this.info = res;
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    this.messagesService.onMessage.pipe(debounceTime(100)).subscribe((res) => {
      this.loadData();
    });
    this.loadData();
  }
  onKpiReportClick() {
    //GenerateKpiReportWndComponent
    const dialogRef = this.dialog.open(GenerateKpiReportWndComponent, {
      minWidth: "400px",
      // minHeight: "640px",
    });
  }
}
