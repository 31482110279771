import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import { ILicenseInfo, LicenseService } from "src/app/services/license.service";
import { MessagesService } from "src/app/services/messages.service";
import { IInvoiceInfo, PaymentService } from "src/app/services/payment.service";

@Component({
  selector: "app-license-info",
  templateUrl: "./license-info.component.html",
  styleUrl: "./license-info.component.scss",
})
export class LicenseInfoComponent implements OnInit,OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public info: ILicenseInfo | null = null;
  public isLoading: boolean = true;

  public invoices: IInvoiceInfo[] = [];
  public displayedColumns = ["title","status","date", "download"];
  constructor(
    public licenseService: LicenseService,
    public bitrix24Service: Bitrix24Service,
    private paymentService: PaymentService,
    private _messagesService: MessagesService
  ) {}

  async LoadData() {
    let promices = await Promise.all([
      this.licenseService.Info.toPromise(),
      this.paymentService.GetInvoices().toPromise(),
    ]);

    this.info = promices[0];
    this.invoices = promices[1] || [];
    this.isLoading = false;
  }
  onDownloadClick(invoice: IInvoiceInfo) {
    this.isLoading = true;
    this.paymentService.DownloadInvoice(invoice.id).subscribe((res) => {
      // debugger
      let data = res.body as Blob;
      let a = document.createElement("a");
      a.download = invoice.title + ".pdf";
      a.href = window.URL.createObjectURL(data);
      a.click();
      this.isLoading = false;
      setTimeout(() => {
        a.remove();
      },30000);
    });
  }
  ngOnDestroy(): void {    
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this._messagesService.onUpdateInvoices
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        
        this.LoadData();
      });
    this.LoadData();
  }
}
