import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from "@angular/material/tree";
import { Subject, debounceTime } from "rxjs";
import { OrgStructureService } from "src/app/services/org-structure.service";
interface ITreeNode {
  id: number;
  name: string;
  photo?: any;
}

interface IFlatNode {
  expandable: boolean;
  name: string;
  data: any;
  level: number;
}

@Component({
  selector: "app-select-users-wnd",
  templateUrl: "./select-users-wnd.component.html",
  styleUrl: "./select-users-wnd.component.scss",
})
export class SelectUsersWndComponent {
  public isLoading: boolean = true;
  public selectedItems: any[] = [];
  private _allRows: any[] = [];
  private $onSearch = new Subject();

  public filterText: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orgStructureService: OrgStructureService
  ) {
    if (Array.isArray(data.selectedItems)) {
      this.selectedItems = (data.selectedItems || []).slice();
    } else {
      if (data.selectedItems) {
        this.selectedItems = [data.selectedItems];
      }
    }
    this.$onSearch.pipe(debounceTime(300)).subscribe((res) => {
      this.prepareData();
    });
  }
  onChangeSearchText() {
    this.$onSearch.next(null);
  }

  public isChecked(node: any) {
    // console.log(node);
    return this.selectedItems.findIndex((f) => f.id == node.id) >= 0;
  }

  public toogleItem(node: any) {
    let index = this.selectedItems.findIndex((f) => f.id == node.id);
    if (index == -1) {
      this.selectedItems = [node];
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  private _transformer = (node: ITreeNode, level: number) => {
    return {
      expandable: false,
      name: node.name,
      data: node,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => {
      return [];
    }
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IFlatNode) => node.expandable;

  private prepareData() {
    if (!!this.filterText) {
      const text = this.filterText.toLowerCase();
      this.dataSource.data = this._allRows.filter(
        (f) => f.name.toLowerCase().indexOf(text) > -1
      );
    } else {
      this.dataSource.data = this._allRows.slice();
    }

    
    
  }
  ngOnInit(): void {
    this.orgStructureService.GetUsers().subscribe((res: ITreeNode[]) => {
      this._allRows = res;
      this.prepareData();
      this.isLoading = false;
    });
  }
}
