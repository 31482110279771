<mat-tab-group mat-stretch-tabs="true"  [(selectedIndex)]="selectedTab">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">schema</mat-icon>
      <span i18n>Штатное расписание</span>
      <help-btn class="help-btn-custom" page="organizatsionnaya_struktura"></help-btn>
    </ng-template>
    <app-org-structure></app-org-structure
  ></mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">extension</mat-icon>
      <span i18n>Департаменты</span>
      <help-btn class="help-btn-custom" page="departamenty"></help-btn>
    </ng-template>
    <departments-list></departments-list
  ></mat-tab>
  <mat-tab >
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">groups</mat-icon>
      <span i18n>Должности</span>
      <help-btn class="help-btn-custom" page="dolzhnosti"></help-btn>
    </ng-template>
    <positions-list></positions-list
  ></mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">person</mat-icon>
      <span i18n>Сотрудники</span>
      <help-btn class="help-btn-custom" page="sotrudniki"></help-btn>
    </ng-template>
    <employees-list></employees-list
  ></mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">assignment</mat-icon>
      <span i18n>Библиотека KPI</span>
      <help-btn class="help-btn-custom" page="shablony_kpi"></help-btn>
    </ng-template>
    <app-kpi-templates></app-kpi-templates
  ></mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">description</mat-icon>
      <span i18n>Лицензия</span>
      <help-btn class="help-btn-custom" page="litsenzii_i_prochie_moduli"></help-btn>
    </ng-template>
    <app-license-info></app-license-info
  ></mat-tab>
</mat-tab-group>
