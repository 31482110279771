import { InjectionToken } from "@angular/core";
import IPlaceParamsData from "./services/bx24/entities/placeParamsData";

const PLACE_DATA = new InjectionToken<IPlaceParamsData>("PlaceParamsData");

const KpiTypeYesNoValues = [
  { value: 100, name: $localize`Да` },
  { value: 0, name: $localize`Нет` },
];

const KpiTypeThreeVariantsValues = [
  { value: 60, name: $localize`Плохо` },
  { value: 80, name: $localize`Средне` },
  { value: 100, name: $localize`Хорошо` },
];
const KpiTypeFiveVariantsValues = [
  { value: 20, start: 1, arr: new Array(1) },
  { value: 40, start: 2, arr: new Array(2) },
  { value: 60, start: 3, arr: new Array(3) },
  { value: 80, start: 4, arr: new Array(4) },
  { value: 100, start: 5, arr: new Array(5) },
];

const PaginatorPageSizes = [10, 20, 50, 100];

export {
  PLACE_DATA,
  KpiTypeYesNoValues,
  KpiTypeThreeVariantsValues,
  KpiTypeFiveVariantsValues,
  PaginatorPageSizes,
};
