import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { DictionaryService } from "src/app/services/dictionary.service";
import { ReportService } from "src/app/services/report.service";

@Component({
  selector: "app-generate-kpi-report-wnd",
  templateUrl: "./generate-kpi-report-wnd.component.html",
  styleUrl: "./generate-kpi-report-wnd.component.scss",
})
export class GenerateKpiReportWndComponent {
  public isLoading: boolean = false;
  public mainForm = new FormGroup({
    periodId: new FormControl(null as number | null, Validators.required),
  });

  constructor(
    public dictionaries: DictionaryService,
    public dialogRef: MatDialogRef<GenerateKpiReportWndComponent>,
    private reportService: ReportService
  ) {}

  onSelect() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      this.isLoading = true;
      this.reportService
        .GetPositionsKpiReport(this.mainForm.controls["periodId"].value || 0)
        .subscribe((res) => {          
          let data = res.body as Blob;
          let a = document.createElement("a");
          a.download = "Отчет.xlsx";
          a.href = window.URL.createObjectURL(data);
          a.click();
          this.isLoading = false;
          setTimeout(() => {
            a.remove();
          }, 30000);
        });
    }
  }
}
