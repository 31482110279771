<div class="edit-card">
  <!-- <div> -->
  <mat-card class="mat-edit-card">
    <!-- <mat-card-header>
        <mat-card-title> Цель или Показатель</mat-card-title>
      </mat-card-header> -->
    <mat-card-content>
      <loader [isLoading]="isLoading"></loader>
      <div class="edit-wrapper">
        <div class="edit-wrapper-content">
          <form [formGroup]="mainForm">
            <mat-form-field>
              <mat-label i18n>e-mail</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Имя</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Фамилия</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Отчество</mat-label>
              <input matInput formControlName="secondName" />
            </mat-form-field>
            <div>
              <mat-slide-toggle formControlName="fired" i18n
                >Уволен</mat-slide-toggle
              >
            </div>
            <div style="margin-top: 1rem;">
              <mat-slide-toggle formControlName="disabledAccess" i18n
                >Отключен от системы</mat-slide-toggle
              >
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="action-buttons">
        <button mat-raised-button (click)="onBtnSave()" color="primary" i18n>Сохранить</button>
      </div>
    </mat-card-actions>
  </mat-card>
  <!-- </div> -->
</div>
