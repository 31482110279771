<div class="wrapper" [class.disabled]="isDisabled">
  <div class="content">
    <mat-chip-set>
      @if (single) { @if (!!value){
      <mat-chip (removed)="onRemoveClick(value)">
        <div class="name-wrapper">
          <div class="photo">
            <avatar [url]="value?.photo"> </avatar>
          </div>
          <div>
            {{value.name}}
          </div>
        </div>

        <button matChipRemove [attr.aria-label]="'remove ' + value.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      } } @else { @for (item of value; track item) {
      <mat-chip (removed)="onRemoveClick(item)">
        <div class="name-wrapper">
          <div class="photo">
            <avatar [url]="item.photo"> </avatar>
          </div>
          <div>
            {{item.name}}
          </div>
        </div>

        <button matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      } }
    </mat-chip-set>

    <!-- @for (item of value; track item) {
      <div class="selected-item">
        {{item.name}}
        
        <div class="close-btn" (click)="onRemoveClick(item)">
            <mat-icon>clear</mat-icon>
        </div>
        
      </div>
      } -->
  </div>
  <div class="caret">
    <button
      mat-icon-button
      [disableRipple]="true"
      (click)="onSelectClick()"
      [disabled]="isDisabled"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</div>
