<loader [isLoading]="isLoading"></loader>
<div mat-dialog-title>
  <form [formGroup]="mainForm">
    <mat-form-field>
      <mat-label i18n>Период</mat-label>
      <mat-select matInput formControlName="periodId" [multiple]="true">
        <mat-option
          *ngFor="let item of dictionaries.PeriodsWithOutHidden | async"
          [value]="item.id"
        >
          <div [style.padding-left.px]="24*item.level">
            {{ item.name }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>

      <mat-checkbox class="check-box"
        [checked]="isChecked(node)"
        (change)="toogleItem(node)"
        ><div class="name-wrapper">

          <mat-icon color="primary">
            @if (node.isGroup) { ads_click } @else { checklist }</mat-icon
          >
          <div>
            <div >{{node.name}}</div>
          </div>
        </div>
      </mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>

      <mat-checkbox class="check-box"
        [checked]="isChecked(node)"
        (change)="toogleItem(node)"
        ><div class="name-wrapper">
          <mat-icon color="primary">
            @if (node.isGroup) { ads_click } @else { checklist }</mat-icon
          >

          <div>
            <div>{{node.name}}</div>
          </div>
        </div>
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Отмена</button>
  <button mat-button (click)="onSelect()" cdkFocusInitial>
    Добавить
  </button></mat-dialog-actions
>
