import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserInfoModel } from '../models';

export interface IApiKeyModel {
    id: number;
    creationDate: string;
    creator: IUserInfoModel | null;
}

@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

  constructor(private http: HttpClient) { 

  }

  public GenerateKey(): Observable<string> {
    return this.http.get(`/api/settings/createapikey`, {
      responseType:'text'
    });
  }
  public GetKeys(): Observable<IApiKeyModel[]> {
    return this.http.get<IApiKeyModel[]>(`/api/settings/getapikeys`);
  }

  public RemoveKey(id: number): Observable<any> {
    return this.http.post(`/api/settings/RemoveApiKey`,id);
  }

}
