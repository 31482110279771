<div class="edit-card">
  <!-- <div> -->
  <mat-card class="mat-edit-card">
    <!-- <mat-card-header>
    <mat-card-title> Цель или Показатель</mat-card-title>
  </mat-card-header> -->
    <mat-card-content>
      <loader [isLoading]="isLoading"></loader>

      <mat-tab-group class="tab-panel" [mat-stretch-tabs]="true">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">edit</mat-icon>
            <span i18n>Редактрование</span>
            <help-btn
              class="help-btn-custom"
              [page]="isGroup? 'prakticheskaya_rabota___sozdanie_tseli_' : 'prakticheskaya_rabota___sozdanie_pokazatelya_' "
            ></help-btn>
          </ng-template>
          <div class="edit-wrapper">
            <div class="edit-wrapper-content">
              <form [formGroup]="kpiForm">
                <mat-form-field>
                  <mat-label i18n
                    >Наименование {{isGroup ? 'цели' : 'показателя'}}</mat-label
                  >
                  <input matInput formControlName="name" maxlength="256" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label i18n
                    >Код {{isGroup ? 'цели' : 'показателя'}}</mat-label
                  >
                  <input matInput formControlName="code" maxlength="256" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label i18n>Период</mat-label>
                  <mat-select matInput formControlName="periodId">
                    <mat-option
                      *ngFor="let item of dictionaries.PeriodsWithOutHidden | async"
                      [value]="item.id"
                    >
                      <div [style.padding-left.px]="24*item.level">
                        {{ item.name }}
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                @if (!isGroup) {
                <mat-form-field>
                  <mat-label i18n>Тип</mat-label>
                  <mat-select matInput formControlName="typeId">
                    <mat-option
                      *ngFor="let item of dictionaries.KpiType | async"
                      [value]="item.id"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                } @if(kpiForm.controls["typeId"].value == 2){
                <mat-form-field>
                  <mat-label i18n>Единица измерения</mat-label>
                  <mat-select matInput formControlName="unitId">
                    <mat-option
                      *ngFor="let item of dictionaries.Units | async"
                      [value]="item.id"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                }
                <!-- <mat-form-field>
                  <mat-label>Категория</mat-label>
                  <mat-select matInput formControlName="categoryId">
                    <mat-option
                      *ngFor="let item of dictionaries.Category| async"
                      [value]="item.id"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field> -->
                <mat-form-field>
                  <mat-label i18n>Вес</mat-label>
                  <input
                    matInput
                    type="number"
                    [min]="0"
                    step="1"
                    pattern="\d*"
                    formControlName="weight"
                  />
                </mat-form-field>
                <mat-form-field>
                  <mat-label i18n>Тренд</mat-label>
                  <mat-select matInput formControlName="trendId">
                    <mat-option
                      *ngFor="let item of dictionaries.Thrends | async"
                      [value]="item.id"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <!-- Доп поля -->
                <div class="function-fields-conteiner">
                  @for (formInfo of functionFieldsForms; track $index) {
                  <form [formGroup]="formInfo.form">
                    <mat-form-field>
                      <mat-label>{{formInfo.info.displayName}}</mat-label>
                      @switch (formInfo.info.valueType) { @case ("number") {
                      <input matInput formControlName="value" type="number" />
                      } @case ("text") {
                      <input matInput formControlName="value" />
                      } }
                    </mat-form-field>
                  </form>
                  }
                </div>
                <!-- end Доп поля -->

                @switch (kpiForm.controls["typeId"].value) { @case (1) {
                <mat-form-field>
                  <mat-label i18n>План</mat-label>
                  <mat-select matInput formControlName="plan">
                    <mat-option
                      *ngFor="let item of KpiTypeYesNoValues"
                      [value]="item.value"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label i18n>Факт</mat-label>
                  <mat-select matInput formControlName="fact">
                    <mat-option
                      *ngFor="let item of KpiTypeYesNoValues"
                      [value]="item.value"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                } @case (2) {
                <mat-form-field>
                  <mat-label i18n>План</mat-label>
                  <input matInput type="number" formControlName="plan" />
                </mat-form-field>
                <mat-form-field>
                  <mat-label i18n>Факт</mat-label>
                  <input matInput type="number" formControlName="fact" />
                </mat-form-field>
                } @case (3) {
                <!-- <mat-form-field>
                    <mat-label i18n>План</mat-label>
                    <mat-select matInput formControlName="plan">
                      <mat-option [value]="60" i18n>Нет</mat-option>
                      <mat-option [value]="80" i18n>Да</mat-option>
                      <mat-option [value]="100" i18n>Да</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                <mat-form-field>
                  <mat-label i18n>Факт</mat-label>
                  <mat-select matInput formControlName="fact">
                    <mat-option
                      *ngFor="let item of KpiTypeThreeVariantsValues"
                      [value]="item.value"
                      >{{ item.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                } @case (4) {
                <!-- <mat-form-field>
                      <mat-label i18n>План</mat-label>
                      <mat-select matInput formControlName="plan">
                        <mat-option [value]="60" i18n>Нет</mat-option>
                        <mat-option [value]="80" i18n>Да</mat-option>
                        <mat-option [value]="100" i18n>Да</mat-option>
                      </mat-select>
                    </mat-form-field> -->
                <mat-form-field>
                  <mat-label i18n>Факт</mat-label>
                  <mat-select matInput formControlName="fact">
                    <mat-select-trigger>
                      <div class="starts">
                        @for (item of getStartArray(); track $index) {
                        <mat-icon>star</mat-icon>
                        }
                      </div>
                    </mat-select-trigger>
                    <mat-option
                      *ngFor="let item of KpiTypeFiveVariantsValues"
                      [value]="item.value"
                    >
                      <div class="starts">
                        <mat-icon *ngFor="let s of item.arr">star</mat-icon>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                } @default { } }

                <!-- @if(!isGroup){ -->
                <!-- <mat-form-field>
                  <mat-label i18n>Факт</mat-label>
                  <input matInput type="number" formControlName="fact" />
                </mat-form-field> -->
                <!-- } -->
                <mat-form-field>
                  <mat-label i18n>Коментарий</mat-label>
                  <textarea
                    matInput
                    rows="3"
                    formControlName="comment"
                  ></textarea>
                </mat-form-field>
                <div class="" *ngIf="!!model">
                  <span i18n>Статус:</span> {{model.status?.name}}
                </div>
              </form>
            </div>
            @if (!isViewer) { @if(isController){
            <div class="action-buttons">
              @if (model == null || model.canEdit || model.canEditFact){
              <button
                mat-raised-button
                (click)="onBtnSave()"
                color="primary"
                i18n
                >Сохранить</button
              >
              }
              <button
                *ngIf="(model?.statusId || 0) > 1"
                mat-raised-button
                (click)="onSetStatus(1)"
                color="primary"
                i18n
                >Черновик</button
              >
              <button
                *ngIf="(model?.statusId || 0) > 3"
                mat-raised-button
                (click)="onSetStatus(3)"
                color="primary"
                i18n
                >Доработка плана</button
              >
              @if ((model?.statusId || 0) > 102) {
              <button
                mat-raised-button
                (click)="onSetStatus(102)"
                color="primary"
                i18n
                >Доработка факта</button
              >
              }
              <button
                mat-raised-button
                (click)="onSetStatus(1000)"
                color="primary"
                i18n
                >В архив</button
              >
            </div>
            } @else { @if (model == null || model.canEdit || model.canEditFact)
            {
            <div class="action-buttons">
              <button
                mat-raised-button
                (click)="onBtnSave()"
                color="primary"
                i18n
                >Сохранить</button
              >
              <button
                *ngIf="model?.canProcess"
                mat-raised-button
                (click)="onBtnSave(true)"
                i18n
                >@if(model?.statusId == 1 || model?.statusId == 3) { Согласовать
                План } @else { Согласовать Факт }</button
              >
              @if(model != null && (model.canEdit && !model.canEditFact)){
              <button
                *ngIf="(model?.id || 0) > 0"
                mat-raised-button
                (click)="onSetStatus(1000)"
                i18n
                >В архив</button
              >
              }
            </div>
            } @else {
            <div class="action-buttons">
              <button
                mat-raised-button
                (click)="onBtnSave()"
                color="primary"
                i18n
                >Сохранить</button
              >
              @for (item of model.status?.nextStatuses; track $index) {
              <button
                mat-raised-button
                (click)="onSetStatus(item.id)"
                color="primary"
              >
                {{item.name}}
              </button>
              }
            </div>
            } } }
          </div>
        </mat-tab>
        @if( !!model && !!model.status && model.status.id>1) {
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">task_alt</mat-icon>
            <span i18n>Статус</span>
          </ng-template>
          <div class="edit-wrapper">
            <div class="edit-wrapper-content">
              <div style="padding-top: 2rem;">
                <mat-panel-title i18n>Согласующие:</mat-panel-title>
                <mat-list role="list">
                  <mat-list-item
                    role="listitem"
                    *ngFor="let row of model?.approvers"
                  >
                    <div class="user">
                      <user-widget
                        [user]="row.position?.user"
                        [position]="row.position.name"
                      >
                      </user-widget>
                      <!-- <avatar [url]="row.position?.user?.photo"></avatar> -->
                      <!-- {{row.position?.user?.name}}: -->
                      <label class="status">{{row.status?.name}}</label>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
              @if ( model != null && model.inspectors != null &&
              model.inspectors.length > 0) {
              <mat-divider></mat-divider>
              <div>
                <mat-panel-title i18n>Контролирующие:</mat-panel-title>
                <mat-list role="list">
                  <mat-list-item
                    role="listitem"
                    *ngFor="let row of model?.inspectors"
                  >
                    <user-widget [user]="row.user" [position]="row.name">
                    </user-widget>
                    <!-- <div class="user">
                    <avatar [url]="row.user?.photo"></avatar>{{row.user?.name}}
                  </div> -->
                  </mat-list-item>
                </mat-list>
              </div>
              } @if ( model != null && model.viewers != null &&
              model.viewers.length > 0) {
              <mat-divider></mat-divider>
              <div>
                <mat-panel-title i18n>Наблюдатели:</mat-panel-title>
                <mat-list role="list">
                  <mat-list-item
                    role="listitem"
                    *ngFor="let row of model?.viewers"
                  >
                    <user-widget [user]="row.user" [position]="row.name">
                    </user-widget>
                    <!-- <div class="user">
                    <avatar [url]="row.user?.photo"></avatar>{{row.user?.name}}
                  </div> -->
                  </mat-list-item>
                </mat-list>
              </div>
              }
              <mat-divider></mat-divider>
            </div>

            <div class="action-buttons">
              @for (item of model.status.nextStatuses; track $index) {
              <button
                mat-raised-button
                (click)="onSetStatus(item.id)"
                color="primary"
              >
                {{item.name}}
              </button>
              }
            </div>
          </div>
        </mat-tab>
        }
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">history</mat-icon>
            <span i18n>История</span>
          </ng-template>

          <mat-list class="history-list">
            <mat-list-item role="listitem" *ngFor="let row of model?.history">
              <div class="history-item">
                <div class="user">
                  <avatar [url]="row.user.photo"></avatar>
                  <div class="text">
                    <div>
                      {{row.user.name}}: {{row.date | date: 'd.M.yyyy h:mm:ss'}}
                    </div>
                    <span>{{row.text}}</span>
                  </div>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
  <!-- </div> -->
</div>
<!-- <div class="status-buttons">
  <mat-card>
    <mat-card-actions>
      <div class="action-buttons">
        <button mat-stroked-button (click)="onBtnCancel()">Согласовать</button>
        <button mat-stroked-button (click)="onBtnCancel()">Доработать</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div> -->
