import { Component } from '@angular/core';

@Component({
  selector: 'app-not-permited-page',
  templateUrl: './not-permited-page.component.html',
  styleUrl: './not-permited-page.component.scss'
})
export class NotPermitedPageComponent {

}
