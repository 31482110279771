<mat-card class="mat-edit-card">
  <mat-card-header>
    <mat-card-title i18n>Департамент</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <loader [isLoading]="isLoading"></loader>

    <form [formGroup]="mainForm">
      <mat-form-field>
        <mat-label i18n>Наименование департамента</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label i18n>Департамент</mat-label>
        <mat-select formControlName="parentId">
          <mat-option
            *ngFor="let item of departments | async"
            [value]="item.id"
          >
            <div [style.padding-left.px]="24*item.level">
              {{ item.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field> -->
      <div>
        <mat-slide-toggle formControlName="archived" i18n
          >В архиве</mat-slide-toggle
        >
      </div>
      <!-- </mat-form-field>       -->
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="action-buttons">
      <button mat-raised-button (click)="onBtnSave()" color="primary" i18n
        >Сохранить</button
      >
    </div>
  </mat-card-actions>
</mat-card>
