import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-view-key-wnd",
  templateUrl: "./view-key-wnd.component.html",
  styleUrl: "./view-key-wnd.component.scss",
})
export class ViewKeyWndComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewKeyWndComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
}
