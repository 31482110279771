import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient) {}

  public GetPositionsKpiReport(periodId: number): Observable<any> {
    return this.http.get(`/api/report/positions-kpi/${periodId}`, {
      observe: "response",
      responseType: "blob",
    });
  }
}
