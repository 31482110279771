<h2 mat-dialog-title i18n>Параметр формулы</h2>
<mat-dialog-content>
  <form [formGroup]="mainForm">
    <mat-form-field>
      <mat-label i18n>Название</mat-label>
      <input matInput formControlName="displayName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Системное имя</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Тип</mat-label>
      <mat-select matInput formControlName="valueType">
        <mat-option value="number" i18n>Число</mat-option>
        <mat-option value="text" i18n>Строка</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
        <mat-slide-toggle formControlName="required" i18n
          >Обязательное</mat-slide-toggle
        >
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">    
    <button mat-button mat-dialog-close i18n>Отмена</button>
    <button mat-button (click)="onSave()" cdkFocusInitial>
      Ок
    </button>
  </mat-dialog-actions>
