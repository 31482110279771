import Params from "./entities/params";
import parseWindowName from "./utils/parse-window-name";
import uniqueKey from "./utils/unique-key";

type FunctionReturnString = () => string;

class MessageSender {
  private _currentWindow: Window;
  private _parentWindow: Window;
  private _params: Params;
  private _resolvers: any;
  private _uniqueKey: FunctionReturnString;

  constructor(currentWindow: Window, parentWindow: Window, params: Params) {
    this._params = params;
    this._currentWindow = currentWindow;
    this._parentWindow = parentWindow;
    this._resolvers = {};
    this._uniqueKey = uniqueKey;

    parseWindowName(currentWindow.name, this._params);
    this._setListener();
  }

  /**
   *
   * @param cmd
   * @param callback
   * @param params
   */
  public send(cmd: string, params?: object): Promise<any> {
    let resolverKey;
    const promise = new Promise<any>((resolve) => {
      resolverKey = this._addResolver(resolve);
    });

    // cmd += [!!params ? JSON.stringify(params) : "", resolverKey].join(":");
    // if (!!this._params.APP_SID) {
    //   cmd = [cmd, this._params.APP_SID].join(":");
    // }
    const url =
      "http" + (this._params.PROTOCOL ? "s" : "") + "://" + this._params.DOMAIN;

    const sendParams = {
      method: cmd,
      appSid: this._params.APP_SID,
      callback: resolverKey,
      params: !!params ? params : [],
    };
    // console.log(cmd, sendParams);

    this._parentWindow.postMessage(sendParams, url);
    return promise;
  }

  /**
   *
   * @param resolver
   * @param callback
   */
  private _addResolver(resolver: Function): string {
    const key = this._uniqueKey();
    this._resolvers[key] = resolver;
    return key;
  }

  private _setListener(): void {
    this._currentWindow.addEventListener(
      "message",
      this._eventHandler.bind(this)
    );
  }

  private _eventHandler(e: MessageEvent): void {   
    
    const arr = (`${e.data}`).match(/(^.*?):(.*$)/);
    if ( arr?.length === 3) {
      // let resData =
      this._resolvers[arr[1]]?.call(null, !!arr[2] ? JSON.parse(arr[2]) : null);
    }
  }
}

export default MessageSender;
