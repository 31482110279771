import { Component } from "@angular/core";

@Component({
  selector: "div[user-settings]",
  templateUrl: "./user-settings-page.component.html",
  styleUrl: "./user-settings-page.component.scss",
})
export class UserSettingsPageComponent {
  public coordinating: any[] = [
    { name: "user 1" },
    { name: "user 2" },
    { name: "user 3" },
    { name: "user 4" },
  ];
  public controllers: any[] = [
    { name: "user 1" },
  ];
}
