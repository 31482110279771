import { Component, OnInit } from "@angular/core";
import { OrgStructureService } from "../../services/org-structure.service";
import { Bitrix24Service } from "../../services/bitrix24.service";
import { FormControl, FormGroup } from "@angular/forms";
import { DictionaryService } from "src/app/services/dictionary.service";
import {
  ExportService,
  IColumnInfo,
  IFilterInfo,
} from "src/app/services/export.service";
import { debounceTime, map } from "rxjs/operators";
import { MessagesService } from "src/app/services/messages.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { AddKpiFromLibWndComponent } from "src/app/components/add-kpi-from-lib-wnd/add-kpi-from-lib-wnd.component";

@Component({
  selector: "app-viewinglist-page",
  templateUrl: "./viewinglist-page.component.html",
  styleUrl: "./viewinglist-page.component.scss",
})
export class ViewinglistPageComponent implements OnInit {
  public isLoading: boolean = true;
  public searchForm: FormGroup;
  public data: any[] = [];
  public selectedRows: Record<number, boolean> = {};
  public allComplete: boolean | null = false;
  public isCheckerIndeterminate: boolean = false;
  public hasSelected: boolean = false;
  constructor(
    private orgStructureService: OrgStructureService,
    public bx: Bitrix24Service,
    public dictionaries: DictionaryService,
    private _exportService: ExportService,
    private _messagesService: MessagesService,
    public dialog: MatDialog
  ) {
    this.searchForm = new FormGroup({
      period: new FormControl(null as number | null),
      status: new FormControl(null as number | null),
      searchString: new FormControl(null),
      departments: new FormControl([]),
    });
  }

  checkerIndeterminate() {
    let hasChecked = false;
    let hasUnchecked = false;
    this.data.forEach((e) => {
      if (this.selectedRows[e.id]) {
        hasChecked = true;
      } else {
        hasUnchecked = true;
      }
    });
    this.hasSelected = hasChecked;
    this.isCheckerIndeterminate = hasChecked == hasUnchecked;
    if (this.isCheckerIndeterminate) {
      // this.allComplete = null;
    } else {
      this.allComplete = hasChecked;
    }
  }

  onChangeAll(event: MatCheckboxChange) {
    this.data.forEach((e) => {
      this.selectedRows[e.id] = event.checked == true;
    });
    this.checkerIndeterminate();
  }
  onCheck(positionId: number) {
    this.selectedRows[positionId] = !this.selectedRows[positionId];
    this.checkerIndeterminate();
  }
  public onAddFromLib() {
    const dialogRef = this.dialog.open(AddKpiFromLibWndComponent, {
      width:"800px",
      minHeight: "640px",
      data: {
        positionIds: this.data
          .filter((f) => this.selectedRows[f.id])
          .map((m) => m.id),
        periodId: this.searchForm.controls["period"].value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }
  private loadData() {
    this.isLoading = true;
    this.orgStructureService
      .GetMembersToView(this.searchForm.getRawValue())
      .subscribe((res) => {
        // console.log(res);
        this.data = res;
        this.isLoading = false;
      });
  }

  public onEditRow(element: any) {
    this.bx.viewUserKpi(
      element.id,
      this.searchForm.controls["period"].value,
      "Показатели",
      "viewer"
    );
  }

  ngOnInit(): void {
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(res =>{
      this.loadData();
    });
    this._messagesService.onUpdateKpi.subscribe((res) => {
      this.loadData();
    });
    this.dictionaries.GetDefaultPeriodId().subscribe((res) => {
      this.searchForm.controls["period"].setValue(res);
      // this.loadData();
    });
  }

  onSearchFormReset() {
    this.searchForm.reset({
      period: null,
      status: null,
    });
    this.loadData();
  }
  onSearchFormSubmit() {
    setTimeout(() => {
      this.loadData();
    });
  }

  async onDownloadClick() {
    var filters: IFilterInfo[] = [];

    var form: any = this.searchForm.getRawValue();
    if (form.period) {
      let fValue: number = form.period;
      var dicValue = await this.dictionaries.PeriodsWithOutHidden.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: "Период",
        value: dicValue || "",
      });
    }
    if (!!form.status) {
      let fValue: number = form.status;
      var dicValue = await this.dictionaries.Statuses.pipe(
        map((m) => {
          return m.find((f) => f.id == fValue)?.name;
        })
      ).toPromise();
      filters.push({
        title: "Статус",
        value: dicValue || "",
      });
    }

    const exportColumns: IColumnInfo[] = [
      {
        title: $localize`Сотрудник`,
        field: (row) => row.user?.name,
      },
      { title: $localize`Департамент`, field: (row) => row.departmentName },
      { title: $localize`Должность`, field: (row) => row.name },
      {
        title: $localize`Исполнение`,
        field: (row) => {
          return row.execution.execution / 100;
        },
        color: (row) => row.execution.color,
        fieldMask: "0%",
      },
    ];

    this._exportService.ExportKpis(
      `Оцениваемые`,
      "Цели и KPI",
      exportColumns,
      this.data,
      filters
    );
  }
}
