import moment from "moment";

export function PrepareModelData(model: any): any {
  let result: any = {};
  if (model) {
    for (const key in model) {
      if (Object.prototype.hasOwnProperty.call(model, key)) {
        const element = model[key];
        if (element === null) continue;

        if (moment.isMoment(element)) {
          result[key] = element.format("YYYY-MM-DD");
        } else if (Array.isArray(element) && element[0]?.id !== undefined) {
          result[key] = element.map(m => m.id);
        } else if ( element.id !== undefined) {
          result[key] = element.id;
        } else result[key] = element;
      }
    }
  }

  return result;
}
