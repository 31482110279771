<h2 mat-dialog-title i18n>Должности</h2>
<mat-dialog-content>
  @if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>

  } @else {
    <div>
      <mat-form-field>
        <mat-label>Фильтр</mat-label>
        <input matInput [(ngModel)]="filterText" (input)="onChangeSearchText()" />
      </mat-form-field>
    </div>
    <div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>

      @if (node.position?.departmentId > 0) {
      <mat-checkbox
        [checked]="isChecked(node.position)"
        (change)="toogleItem(node.position)"
        ><div class="name-wrapper">
          @if(!!node.position?.user?.photo){
          <avatar [url]="node.position?.user?.photo"> </avatar>
          } @else {
          <mat-icon>person</mat-icon>
          }
          <div>
            <div>{{node.name}}</div>
            <div>{{node.position?.user?.name}}</div>
          </div>
        </div>
      </mat-checkbox>
      } @else { <mat-icon>home</mat-icon> {{node.name}} }
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      @if (node.position?.departmentId > 0) {
        <mat-checkbox
          [checked]="isChecked(node.position)"
          (change)="toogleItem(node.position)"
          ><div class="name-wrapper">
            @if(!!node.position?.user?.photo){
            <avatar [url]="node.position?.user?.photo"> </avatar>
            } @else {
            <mat-icon>person</mat-icon>
            }
            <div>
              <div>{{node.name}}</div>
              <div>{{node.position?.user?.name}}</div>
            </div>
          </div>
        </mat-checkbox>
        } @else { <mat-icon>home</mat-icon> {{node.name}} }
    </mat-tree-node>
  </mat-tree>
</div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Отмена</button>
  <button mat-button [mat-dialog-close]="selectedItems" cdkFocusInitial>
    Ок
  </button>
</mat-dialog-actions>
