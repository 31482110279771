import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
export interface IUnitMeasureDicModel {
  id: number;
  name: string;
  orderId: number;
  canEdit?:boolean;
  isHidden:boolean;
}

export interface IPeriodDicModel {
  id: number;
  name: string;
  code: string | null;
  parentId: number | null;
  from: string;
  to: string;
  isHidden: boolean;
  isDefault: boolean;
  orderId: number;
}
@Injectable({
  providedIn: 'root'
})
export class SettingsDictionaryService {

  constructor(private http: HttpClient) { }

  public GetPeriods(): Observable<IPeriodDicModel[]>{
    return this.http.get<IPeriodDicModel[]>(`/api/settings/dictionary/getperiods`)
  }
  public SavePeriods(models :IPeriodDicModel[]): Observable<any>{
    return this.http.post<any>(`/api/settings/dictionary/saveperiods`,models);
  }
  public GetUnitMeasuresFull(): Observable<IUnitMeasureDicModel[]>{
    return this.http.get<IUnitMeasureDicModel[]>(`/api/settings/dictionary/getunitmeasures`)
  }
  public SaveUnitMeasure(models :IUnitMeasureDicModel[]): Observable<any>{
    return this.http.post<any>(`/api/settings/dictionary/saveunitmeasures`,models);
  }
}
