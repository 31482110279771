import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-crud-positions-wnd",
  templateUrl: "./crud-positions-wnd.component.html",
  styleUrl: "./crud-positions-wnd.component.scss",
})
export class CrudPositionsWndComponent {
  public mainForm = new FormGroup({
    positions: new FormControl([]),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CrudPositionsWndComponent>
  ) {}

  public onSave(action: string) {
    if (action == "clear"){
      if (!confirm($localize`Сбросить всех?`)) return;      
    }
    let result = {
      action: action,
      ids: (this.mainForm.controls["positions"].value || []).map(
        (m: any) => m.id
      ),
    };
    this.dialogRef.close(result);
  }
}
