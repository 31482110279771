import {
  Component,
  HostListener,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Bitrix24Service } from "../../../services/bitrix24.service";
import { OrgStructureService } from "../../../services/org-structure.service";
import { FlatTreeControl, NestedTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNestedDataSource,
} from "@angular/material/tree";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragMove,
  CdkDragRelease,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject, catchError, merge, mergeAll, of, takeUntil } from "rxjs";
import { MessagesService } from "src/app/services/messages.service";

interface IOrgItem {
  id: number;
  name: string;
  children: IOrgItem[];
  positions: IOrgItem[] | null;
  parentId?: number;
  archived?: boolean;
}
interface IDropInfo {
  targetId: number;
  action?: string;
  sourceDataType: string;
  targetDataType: string[];
  targetRect: DOMRect;
}

@Component({
  selector: "app-org-structure",
  templateUrl: "./org-structure.component.html",
  styleUrl: "./org-structure.component.scss",
})
export class OrgStructureComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  public isLoading: boolean = true;
  public data: IOrgItem[] = [];
  public expandedRows: Record<number, boolean> = {};
  public expandedPositions: Record<number, boolean> = {};
  public selectedDepartment: IOrgItem | null = null;

  public dropList: string[] = [];

  dropTargetIds: number[] = [];
  nodeDepLookup: Record<number, IOrgItem> = {};
  nodePosLookup: Record<number, IOrgItem> = {};
  public dropActionTodo: IDropInfo | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private orgStructureService: OrgStructureService,
    private bitrix24Service: Bitrix24Service,
    private _snackBar: MatSnackBar,
    private _messagesService: MessagesService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    let me = this;

    merge(
      this._messagesService.onUpdatePosition,
      this._messagesService.onUpdateDepartment
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        //let item: any = me.nodePosLookup[res.id];
        let selectedDepartmentId: number | null =
          me.selectedDepartment?.id || null;
        me._changeDetectorRef.markForCheck();
        me.loadData(() => {
          me.selectedDepartment = null;
          if (selectedDepartmentId !== null) {
            me.onSelectDepartment(
              me.nodeDepLookup[selectedDepartmentId] || null
            );
          }
          me._changeDetectorRef.markForCheck();
          setTimeout(() => {
            me._changeDetectorRef.detectChanges();
          });
        });
        // }
      });

    this.loadData(null);
  }

  private loadData(callBack: (() => void) | null) {
    this.isLoading = true;
    this.orgStructureService.GetList().subscribe((res) => {
      // this.data = [...res,...res,...res,...res,...res];
      this.data = res;
      this.nodeDepLookup = {};
      this.prepareDragDrop(res, this.nodeDepLookup);
      this.data.forEach((row) => {
        this.expandedRows[row.id] = true;
      });
      if (callBack != null) {
        callBack();
      }
      this.isLoading = false;
    });
  }

  prepareDragDrop(nodes: IOrgItem[], collection: Record<number, IOrgItem>) {
    nodes.forEach((node) => {
      // console.log(node);
      if (node.archived !== true) {
        this.dropTargetIds.push(node.id);
        collection[node.id] = node;
        this.prepareDragDrop(node.children, collection);
      }
    });
  }
  dragRelease(event: CdkDragRelease) {
    if (this.dropActionTodo == null) return;

    let dragElem = this.document.getElementsByClassName("cdk-drag-preview");
    if (dragElem.length > 0) {
      let e: any = dragElem.item(0);
      const targetRect = this.dropActionTodo.targetRect;
      //transform: "translate3d(420px, 244px, 0px)",
      setTimeout(() => {
        e.style.transform = `translate3d(${targetRect.x}px, ${targetRect.y}px, 0px)`;
      });
    }
  }

  dragMoved(event: CdkDragMove<any>) {
    let elem = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );
    while (elem != null && !elem.classList.contains("table-row")) {
      elem = elem.parentElement;
    }

    if (elem == null) {
      this.clearDragInfo();
      return;
    }

    let wrapper = elem.parentElement;
    while (wrapper != null && !wrapper.classList.contains("table-level")) {
      wrapper = wrapper.parentElement;
    }
    if (wrapper == null) {
      this.clearDragInfo();
      return;
    }

    let targetDataType = (wrapper.getAttribute("data-type") || "").split(",");
    let sourceDataType =
      event.source.element.nativeElement.getAttribute("data-type") || "";
    // console.log(targetDataType, sourceDataType,);
    if (targetDataType.indexOf(sourceDataType) == -1) {
      this.clearDragInfo();
      return;
    }
    // console.log(this.dropActionTodo);
    const targetRect = elem.getBoundingClientRect();

    this.dropActionTodo = {
      targetId: +(elem.getAttribute("data-id") || 0),
      sourceDataType: sourceDataType,
      targetDataType: targetDataType,
      targetRect: targetRect,
    };
    if (sourceDataType == "pos" && targetDataType.length > 1) {
      this.dropActionTodo["action"] = "inside";
    } else {
      // const part = targetRect.height / 4;
      const oneThird = targetRect.height / 3;
      if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropActionTodo["action"] = "before";
      } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropActionTodo["action"] = "after";
      } else {
        // inside
        this.dropActionTodo["action"] = "inside";
      }
    }
    //cdk-drag table-row-wrapper cdk-drag-preview cdk-drag-animating
    let dragElem = this.document.getElementsByClassName("cdk-drag-preview");
    if (dragElem.length > 0) {
      let e: any = dragElem.item(0);
      //transform: "translate3d(420px, 244px, 0px)",
      // e.style.transform = `translate3d(${targetRect.top}px, ${targetRect.left}px, 0px)`;
    }

    this.showDragInfo();
  }
  checkDropListDisabled(drag: CdkDrag, drop: CdkDropList): boolean {
    return true;
  }
  getParentNodeId(
    id: number,
    nodesToSearch: IOrgItem[],
    parentId: number
  ): number | null {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.children, node.id);
      if (ret) return ret;
    }
    return null;
  }
  drop(event: CdkDragDrop<any[]>, rows: any[]) {
    if (!this.dropActionTodo) return;
    const draggedItemId = event.item.data.id || 0;

    let targetDropTypes = this.dropActionTodo.targetDataType;
    let sourceDataType = this.dropActionTodo.sourceDataType;

    if (targetDropTypes.indexOf(sourceDataType) == -1) {
      this.clearDragInfo(true);
      return;
    }
    let parentItemId = parseInt(
      event.previousContainer.element.nativeElement.getAttribute("data-id") ||
        "0"
    );
    if (Number.isNaN(parentItemId)) parentItemId = 0;

    let dropActionTodo = this.dropActionTodo;
    if (sourceDataType == "dep") {
      const targetListId =
        this.getParentNodeId(this.dropActionTodo.targetId, this.data, 0) || 0;
      const draggedItem = this.nodeDepLookup[draggedItemId];
      const oldItemContainer =
        parentItemId != 0
          ? this.nodeDepLookup[parentItemId].children
          : this.data;
      const newContainer =
        targetListId != 0
          ? this.nodeDepLookup[targetListId].children
          : this.data;

      let model: any = {
        id: draggedItem.id,
        name: draggedItem.name,
        parentId: targetListId == 0 ? null : targetListId,
      };
      if (dropActionTodo.action == "inside") {
        model.parentId = dropActionTodo.targetId;
      }
      this.isLoading = true;
      this.orgStructureService
        .SaveDepartment(model)
        .pipe(
          catchError((err) => {
            this._snackBar.open("Ошибка при сохранении", "OK", {
              duration: 2000,
            });
            return of(false);
          })
        )
        .subscribe((res) => {
          this.isLoading = false;
          if (res === false) return;
          let i = oldItemContainer.findIndex((c) => c.id === draggedItemId);
          oldItemContainer.splice(i, 1);
          switch (dropActionTodo.action) {
            case "before":
            case "after":
              const targetIndex = newContainer.findIndex(
                (c) => c.id === this.dropActionTodo?.targetId
              );
              if (dropActionTodo.action == "before") {
                newContainer.splice(targetIndex, 0, draggedItem);
              } else {
                newContainer.splice(targetIndex + 1, 0, draggedItem);
              }
              break;

            case "inside":
              this.expandedRows[dropActionTodo.targetId] = true;
              this.nodeDepLookup[dropActionTodo.targetId].children.push(
                draggedItem
              );

              break;
          }
          this._messagesService.UpdateDepartment(draggedItem);
          this._snackBar.open("Департамент перемещён", "OK", {
            duration: 2000,
          });
        });
    } else if (sourceDataType == "pos") {
      const targetListId =
        this.getParentNodeId(
          this.dropActionTodo.targetId,
          this.selectedDepartment?.positions || [],
          0
        ) || 0;
      const draggedItem = this.nodePosLookup[draggedItemId];
      const oldItemContainer =
        (parentItemId != 0
          ? this.nodePosLookup[parentItemId].children
          : this.selectedDepartment?.positions) || [];
      const newContainer =
        (targetListId != 0
          ? targetDropTypes.indexOf("dep") == -1
            ? this.nodePosLookup[targetListId].children
            : this.nodeDepLookup[targetListId].positions
          : this.selectedDepartment?.positions) || [];

      let model: any = {
        id: draggedItem.id,
        name: draggedItem.name,
        parentId: null,
        departmentId: null,
      };

      if (targetDropTypes.indexOf("dep") == -1) {
        model.departmentId = this.selectedDepartment?.id;
        model.parentId = targetListId == 0 ? null : targetListId;

        if (dropActionTodo.action == "inside") {
          model.parentId = dropActionTodo.targetId;
        }
      } else {
        model.departmentId = dropActionTodo.targetId;
      }
      this.isLoading = true;
      this.orgStructureService
        .SavePosition(model, false)
        .pipe(
          catchError((err) => {
            this._snackBar.open("Ошибка при сохранении", "OK", {
              duration: 2000,
            });
            return of(false);
          })
        )
        .subscribe((res) => {
          this.isLoading = false;
          if (res === false) return;
          let i = oldItemContainer.findIndex((c) => c.id === draggedItemId);
          oldItemContainer.splice(i, 1);
          switch (dropActionTodo.action) {
            case "before":
            case "after":
              const targetIndex = newContainer.findIndex(
                (c) => c.id === this.dropActionTodo?.targetId
              );
              if (dropActionTodo.action == "before") {
                newContainer.splice(targetIndex, 0, draggedItem);
              } else {
                newContainer.splice(targetIndex + 1, 0, draggedItem);
              }
              break;

            case "inside":
              if (targetDropTypes.indexOf("dep") == -1) {
                this.expandedPositions[dropActionTodo.targetId] = true;
                this.nodePosLookup[dropActionTodo.targetId].children.push(
                  draggedItem
                );
              } else {
                this.nodeDepLookup[dropActionTodo.targetId]?.positions?.push(
                  draggedItem
                );
              }

              break;
          }
          this._messagesService.UpdatePosition(draggedItem);
          this._snackBar.open("Должность перемещена", "OK", {
            duration: 2000,
          });
        });
    }

    this.clearDragInfo(true);
  }

  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document
        .getElementById(`node-${this.dropActionTodo?.targetId}`)
        ?.classList.add("drop-" + this.dropActionTodo?.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  toogleRow(row: IOrgItem) {
    this.expandedRows[row.id] = !this.expandedRows[row.id];
  }
  tooglePositionRow(row: IOrgItem) {
    this.expandedPositions[row.id] = !this.expandedPositions[row.id];
  }

  onSelectDepartment(row: IOrgItem) {
    this.nodePosLookup = {};
    if (row == this.selectedDepartment) {
      this.selectedDepartment = null;
      return;
    }
    this.selectedDepartment = row;
    // this.nodePosLookup = {};
    if (row != null) {
      this.prepareDragDrop(row.positions || [], this.nodePosLookup);
      row.positions?.forEach((r) => {
        this.expandedPositions[r.id] = true;
      });
    }
  }

  onApproversChanged(row: IOrgItem, rows: any[]) {
    this.orgStructureService
      .SaveApprovers(
        row.id,
        rows.map((m) => m.id)
      )
      .subscribe((res) => {
        this._snackBar.open("Согласующие изменены", "OK", {
          duration: 2000,
        });
      });
  }
  onAddDepartment() {
    this.bitrix24Service
      .editDepartment(null, this.selectedDepartment?.id)
      .then((res) => {});
  }
  onEditDepartment(row: IOrgItem) {
    this.bitrix24Service.editDepartment(row.id, row.parentId).then((res) => {});
  }

  onAddPosition() {
    this.bitrix24Service
      .editPosition(null, this.selectedDepartment?.id)
      .then((res) => {});
  }

  onPositionClick(row: IOrgItem) {
    this.bitrix24Service
      .editPosition(row.id, this.selectedDepartment?.id)
      .then((res) => {});
  }
}
