import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { map, Observable } from "rxjs";
import { PLACE_DATA } from "src/app/consts";
import { Bitrix24Service } from "src/app/services/bitrix24.service";
import IPlaceParamsData from "src/app/services/bx24/entities/placeParamsData";
import { MessagesService } from "src/app/services/messages.service";
import { OrgStructureService } from "src/app/services/org-structure.service";

@Component({
  selector: "edit-department-page",
  templateUrl: "./edit-department-page.component.html",
  styleUrl: "./edit-department-page.component.scss",
})
export class EditDepartmentPageComponent {
  public isLoading: boolean = false;

  public mainForm = new FormGroup({
    name: new FormControl("", Validators.required),
    parentId: new FormControl(null),
    archived: new FormControl(false),
  });
  private model!: any;
  public departments: Observable<any[]> | null = null;
  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private bx: Bitrix24Service,
    private _messagesService: MessagesService,
    private orgStructureService: OrgStructureService
  ) {
    // console.log(data);
    this.model = {
      id: 0,
      parentId: data.data?.parentId,
    };
  }

  private LoadData(id: number, sendObject: boolean) {
    if (id == 0) return;
    this.isLoading = true;
    this.orgStructureService.GetDepartment(id).subscribe((res) => {
      this.model = res;
      for (const field in this.mainForm.controls) {

        const control = this.mainForm.get(field); // 'control' is a FormControl

        control?.setValue(res[field] || null);
      }
      if (sendObject) {
        this._messagesService.UpdateDepartment(res);
      }
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    
    
    this.departments = this.orgStructureService.GetDepartmentList().pipe(
      map((res: any[]) => {
        let depId = this.data?.data?.departmentId || 0;
        if (this.model.id > 0) {
          depId = this.model.id ;
        }
        let result: any[] = [];

        var processTree = (row: any, level: number) => {
          if (row.id == depId) return;
          result.push({
            ...row,
            level: level,
          });
          var children = res.filter((f) => f.parentId == row.id);
          for (let item of children) {
            processTree(item, level + 1);
          }
        };

        for (let item of res) {
          if (item.id == depId) continue;
          if (item.parentId == null) {
            processTree(item, 0);
          }
        }

        return result;
      })
    );
    // console.log(this.data);
    if (!!this.data?.data?.departmentId) {
      this.LoadData(this.data.data.departmentId, false);
    }
  }

  onBtnSave() {
    this.mainForm.markAllAsTouched();
    if (this.mainForm.valid) {
      const value = this.mainForm.getRawValue();
      let model = {
        ...this.model,
        name: value.name,
        archived: value.archived,
        parentId: value.parentId,
      };
      this.isLoading = true;
      this.orgStructureService.SaveDepartment(model).subscribe((res) => {
        this.isLoading = false;
        this.LoadData(res, true);
      });
    }

    // filter(): void {
    //   const filterValue = this.input.nativeElement.value.toLowerCase();
    //   this.filteredOptions = this.options.filter(o => o.toLowerCase().includes(filterValue));
    // }
  }
}
