import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { KpiTypeYesNoValues, PLACE_DATA } from 'src/app/consts';
import { IKpi } from 'src/app/models';
import { Bitrix24Service } from 'src/app/services/bitrix24.service';
import IPlaceParamsData from 'src/app/services/bx24/entities/placeParamsData';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { IFunctionParametrModel } from 'src/app/services/functions.service';
import { IKpiTemplateEditModel, KpiTemplateService } from 'src/app/services/kpi-template.service';
import { MessagesService } from 'src/app/services/messages.service';
interface IFormInfo {
  info: IFunctionParametrModel;
  form: FormGroup;
}
@Component({
  selector: 'app-edit-kpi-template-page',
  templateUrl: './edit-kpi-template-page.component.html',
  styleUrl: './edit-kpi-template-page.component.scss'
})
export class EditKpiTemplatePageComponent {

  public KpiTypeYesNoValues = KpiTypeYesNoValues;

  public kpiForm = new FormGroup({
    name: new FormControl("", Validators.required),
    code: new FormControl(null as string | null, Validators.required),    
    unitId: new FormControl(null as number | null, Validators.required),
    trendId: new FormControl(null as number | null, Validators.required),
    typeId: new FormControl(null as number | null, Validators.required),
    plan: new FormControl(null as number | null, Validators.required),    
    weight: new FormControl(1, Validators.required),
    description: new FormControl(""),
  });

  public model?: IKpi | null = null;

  public isLoading: boolean = false;

  public isGroup:boolean = false;

  public functionFieldsForms: IFormInfo[] = [];

  constructor(
    @Inject(PLACE_DATA) private data: IPlaceParamsData,
    private bx: Bitrix24Service,  
      private kpiTemplateService:KpiTemplateService,
    public dictionaries: DictionaryService,
    private _messagesService: MessagesService,
  ) {
    this.isGroup = data.data?.isGroup == "true";
   
    if ( this.isGroup){
      
      this.kpiForm.controls["plan"].setValue(100);      
      this.kpiForm.controls["unitId"].setValue(1);      
      this.kpiForm.controls["typeId"].setValue(2);    
    } 
  
  }

  private LoadData(id: number) {
    this.isLoading = true;
    this.kpiTemplateService.Get(id).subscribe((res: any) => {
      this.isGroup = res.isGroup;
      this.model = res;
      let isGroup = res.isGroup == true || res.isGroup == "true";
      
      for (const field in this.kpiForm.controls) {
        const control = this.kpiForm.get(field); // 'control' is a FormControl
        control?.setValue(res[field] || null);        
      }     
      this.isLoading = false;
    });
  }
  private async onTrendChanged() {
    let trendId = this.kpiForm.controls["trendId"].value || 0;
    this.functionFieldsForms = [];
    if (trendId < 0) {
      let dic: any[] = (await this.dictionaries.Thrends.toPromise()) || [];
      let currentValue = dic?.find((f) => f.id == trendId);
      var fields: IFunctionParametrModel[] = currentValue.parametrs;
      for (const field of fields) {
        var fControl: FormControl;
        let value = null;
        if (this.model?.functionParametrs != null && this.model?.functionParametrs[field.name] !== undefined){
          value = this.model?.functionParametrs[field.name];
        }
        if (field.required) {
          fControl = new FormControl(value, Validators.required);
        } else {
          fControl = new FormControl(value);
        }
        let formInfo = {
          info: field,
          form: new FormGroup({
            value: fControl,
          })
        }        
        this.functionFieldsForms.push(formInfo);
        
      }
    }
  }
  ngOnInit(): void {
    this.dictionaries.Units.subscribe(res => {});
    if (this.data.id != null) {
      this.LoadData(this.data.id);
    }
    this.kpiForm.controls["trendId"].valueChanges
    .pipe(debounceTime(100))
    .subscribe((res) => {
      this.onTrendChanged();
    });
  }

  public onBtnCancel() {
    this.bx.closeCurrentWnd();
  }


  public onBtnSave(process: boolean = false) {
    let value = this.kpiForm.getRawValue();
    let typeId = value.typeId || 0;
    let plan = value.plan;
    if (typeId == 1){
      this.kpiForm.controls["unitId"].setValue(-1);
    } else if (typeId > 2) {
      this.kpiForm.controls["plan"].setValue(100);
      this.kpiForm.controls["unitId"].setValue(-1);
    }

    this.kpiForm.markAllAsTouched();
    let isValid = this.kpiForm.valid;
    let params: Record<string, any> = {};
    for (const item of this.functionFieldsForms) {
      item.form.markAllAsTouched();
      isValid = isValid && item.form.valid;
      params[item.info.name] = item.form.controls["value"].value;
    }

    if (isValid) {
      const value = this.kpiForm.getRawValue();
      let model: IKpiTemplateEditModel = {
        id: this.model?.id || 0,
        name: value.name || "",
        code:value.code,      
        trendId: value.trendId || 0,
        typeId: value.typeId || 0,
        unitId: value.unitId || 0,
        plan: value.plan,                
        isGroup:
          this.model == null
            ? this.data.data?.isGroup == "true"
            : this.model.isGroup,
        parentId:
          this.model == null ? this.data.data.parentId : this.model.parentId,
        weight: value.weight || 0,
        description: value.description ,
        functionParametrs: params,
      };
      this.isLoading = true;
      this.kpiTemplateService.SaveKpi(model).subscribe((res) => {
        this.isLoading = false;
        this.LoadData(res);
        this._messagesService.UpdateKpi(model);
      });
    }
    // console.log(this.kpiForm.getRawValue());
  }
}
